import React from 'react'
import { ConfigProvider } from 'antd'
import { motion, AnimatePresence } from 'framer-motion'

import { PerformanceMetrics } from '../../../../types/video'

import VideoImpression from './video-impression'
import SuccessfulViews from './successful-views'
import VideoClicks from './video-clicks'
import VideoCard from '../video-card'

import { CARD_CONFIG } from '../../../theme/card'

const MetricsContent: React.FC<{ data: PerformanceMetrics }> = ({ data }) => {
  const config = data.thumbnail ? CARD_CONFIG.standard : CARD_CONFIG.performance

  const containerStyles = {
    backgroundColor: data.thumbnail ? '#F6F7F9' : '#ffffff',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: data.thumbnail ? '10px' : 0,
    paddingRight: data.thumbnail ? '10px' : 0,
    marginBottom: data.thumbnail ? '13px' : 0,
    borderRadius: '16px',
    gap: '15px',
    height: data.thumbnail ? 'auto' : '145px',
    display: 'flex',
    justifyContent: data.thumbnail ? 'space-between' : 'space-between',
    flexGrow: 1,
  }

  return (
    <ConfigProvider>
      <AnimatePresence mode='sync'>
        <motion.div
          layout
          key={`metric-${data.video_source}`}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <div style={containerStyles}>
            {data.thumbnail && (
              <VideoCard
                videoData={{
                  video: data.video,
                  preview: data.preview,
                  thumbnail: data.thumbnail,
                  caption: data.caption,
                  startDate: data.startDate,
                }}
                size={{ width: 108, height: 108 }}
                hasTitle={true}
                withTooltip={true}
              />
            )}
            <VideoImpression impressions={data.impressions} config={config} />
            <SuccessfulViews
              impressions={data.impressions}
              video_views={data.video_views}
              config={config}
            />
            <VideoClicks
              video_views={data.video_views}
              cta_click={data.cta_click}
              config={config}
            />
          </div>
        </motion.div>
      </AnimatePresence>
    </ConfigProvider>
  )
}

export default MetricsContent
