import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Card, Image, Tooltip, Typography } from 'antd'

interface VideoCardProps {
  videoData: {
    video?: string
    preview?: string
    thumbnail?: string
    caption?: string
    startDate: string
  }
  size: {
    width: number
    height: number
  }
  hoverable?: boolean
  onClick?: () => void
  boxShadow?: boolean
  isSelected?: boolean
  hasTitle?: boolean
  withTooltip?: boolean
}

const DateDisplay = (dateString: string): JSX.Element => {
  const date = dayjs(dateString, 'YYYYMMDD').locale('es')
  const day = date.date()
  const month = date.format('MMM').charAt(0).toUpperCase() + date.format('MMM').slice(1)

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 2,
        width: '24px',
        height: '24px',
        borderRadius: '8px',
        padding: '1px',
        backgroundColor: 'rgba(217, 217, 217, 0.7)',
        color: 'rgba(51, 51, 51, 1)',
        fontSize: '8px',
        lineHeight: '9px',
        textAlign: 'center',
        fontWeight: 500,
        top: '4px',
        right: '4px',
        pointerEvents: 'none',
      }}
    >
      <div>{day}</div>
      <div>{month}</div>
    </div>
  )
}

const VideoPreview = React.memo(({ videoUrl }: { videoUrl: string }) => (
  <div style={{ width: '120px', height: 'auto' }}>
    <video
      autoPlay
      muted
      loop
      style={{
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        display: 'block',
      }}
    >
      <source src={videoUrl} type='video/mp4' />
    </video>
  </div>
))

const VideoCard: React.FC<VideoCardProps> = ({
  videoData,
  size,
  hoverable = false,
  onClick,
  boxShadow = false,
  isSelected = false,
  hasTitle = false,
  withTooltip = false,
}) => {
  const { Paragraph } = Typography

  const CardContent = (
    <Card
      hoverable={hoverable}
      onClick={onClick}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        borderRadius: '12px',
        boxSizing: 'border-box',
        boxShadow: boxShadow ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
        border: 'none',
        margin: 0,
        padding: 0,
      }}
      styles={{
        body: {
          padding: 0,
          height: '100%',
          margin: 0,
          position: 'relative',
        },
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Image
          src={videoData.thumbnail}
          width='100%'
          height='100%'
          preview={false}
          style={{
            objectFit: 'cover',
            borderRadius: '12px',
            display: 'block',
          }}
        />
      </div>

      {/* Title overlay */}
      {hasTitle && (
        <div
          style={{
            position: 'absolute',
            top: '60%',
            left: 0,
            width: '100%',
            padding: '5px',
            background: 'linear-gradient(180deg, transparent 0%, rgba(0,0,0,0.7) 100%)',
            zIndex: 2,
          }}
        >
          <Paragraph
            ellipsis={{ rows: 4 }}
            style={{
              color: '#ffffff',
              fontSize: '7px',
              lineHeight: '9px',
              margin: 0,
              textAlign: 'left',
            }}
          >
            {videoData.caption || ''}
          </Paragraph>
        </div>
      )}

      {/* Border for selected image */}
      {isSelected && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: '1px solid rgba(75, 85, 99, 1)',
            borderRadius: '12px',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
      )}
    </Card>
  )

  const WrapperContent = (
    <div
      style={{
        width: size.width,
        height: size.height,
        position: 'relative',
        padding: 0,
        margin: 0,
      }}
    >
      {CardContent}
      {DateDisplay(videoData.startDate)}
    </div>
  )

  return withTooltip && videoData.preview ? (
    <Tooltip
      title={<VideoPreview videoUrl={videoData.preview} />}
      mouseEnterDelay={0.5}
      mouseLeaveDelay={0.1}
      destroyTooltipOnHide={true}
      overlayInnerStyle={{
        padding: 0,
        borderRadius: '8px',
        display: 'inline-block',
      }}
    >
      {WrapperContent}
    </Tooltip>
  ) : (
    WrapperContent
  )
}

export default VideoCard
