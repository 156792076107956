export const fullData = [
  {
    date: '20241106',
    video_source: 'DCAWCYUyhDn',
    impressions: 62239,
    video_views: 461,
    cta_click: 50,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241106',
    video_source: 'DCAPkHNStRd',
    impressions: 54689,
    video_views: 312,
    cta_click: 29,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241106',
    video_source: 'DCB0lxSJQZg',
    impressions: 34698,
    video_views: 265,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241106',
    video_source: 'DB_ysAFxW_k',
    impressions: 22698,
    video_views: 213,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241106',
    video_source: 'DB_WxMSIIVc',
    impressions: 58,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241106',
    video_source: 'DB8ojSaxgB7',
    impressions: 55,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241106',
    video_source: 'DB1TDq2iWa2',
    impressions: 32,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB1TDq2iWa2_optimized-4-L1O1R1B76zePYBQPBa6ygdc36djoxM.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB1TDq2iWa2_preview-jJ7Z4dYFFxI1WTad4iEQp8YmX4hN40.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB1TDq2iWa2-CxRt9WlZy1S7Y2NFY2a2OQ49WhiVxi.jpg',
    caption: '¿Cómo ven la economía de Estados Unidos en Nueva York? 🇺🇸',
  },
  {
    date: '20241106',
    video_source: 'DB9ToayxDH6',
    impressions: 21,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB9ToayxDH6-gYnLD31nr9SyKhNZJdYjRlF9vj7f0z.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB9ToayxDH6_preview-SilNxex8sv5bTdn5QxJ1A1j9LMS2qg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB9ToayxDH6-BvPXIUUVTPzBpiR0ol4X8MTMGcKbSm.jpg',
    caption:
      '🎤 Lucas Sugo habló con Alejandro Fantino sobre haber tocado en el Estadio Centenario  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241106',
    video_source: 'DB4K9ObpRHL',
    impressions: 10,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241106',
    video_source: 'DB3nB2TJykC',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241106',
    video_source: 'DBziZpVSBuO',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241106',
    video_source: 'DBgZj5xJdDs',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241106',
    video_source: 'DBd1QNMpg9F',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241106',
    video_source: 'DBcQ2K0suss',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241106',
    video_source: 'DBzV42Aym4L',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241106',
    video_source: 'DBw3S0aRIhB',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241106',
    video_source: 'DBcnkzVujTi',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcnkzVujTi_optimized-bcF1DJGff3olq8c2q0oPBRI7NLykM3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcnkzVujTi_preview-9GSoQpqbxECbHEillurIrqu6i33KD1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcnkzVujTi_cover_resized-Iz63cijkg0frOHAIR0HzRraleqG3i5.jpg',
    caption:
      '🇺🇾 ¿Por qué las elecciones de este domingo son históricas?\n\n🗳️ Además de elegir representantes, también se votará el plebiscito de allanamientos nocturnos.\n\n🎙️ @joacopisa\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241106',
    video_source: 'DBuWJVIRWYD',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBuWJVIRWYD-99XOOTwYatjxXALAhkGhmu3BGpKgtC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBuWJVIRWYD_preview-0V33zJEqqPMc2lWL56ukHCmrMUMPMv.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/2-DBuWJVIRWYD-C1EjBTt3vuYh9HmiMTX5zePpegZF0U.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado fueron los más votados y protagonizarán un balotaje que se definirá voto a voto. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241106',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241106',
    video_source: 'DA0euPSp9lQ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DA0euPSp9lQ_optimized-2-kVfPU1qJUCg6lOBNbZNkGi11EI8EBb.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DA0euPSp9lQ_preview-nkcv6Cddn8e0tFz7uDk9zKaXfu4mtY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DA0euPSp9lQ_cover_resized-PaOO5XsfNBt2qwkjY77ARbr72HU1ol.jpg',
    caption:
      'Con la pasión como motor y la disciplina como guía, la piloto uruguaya Patricia Pita revela las dificultades y satisfacciones de un deporte donde la adrenalina, el miedo y la superación personal se mezclan en cada carrera. Pita estudia periodismo deportivo y le encantaría entrevistar personajes. Pero esta vez fue la entrevistada.\n\nMirá la entrevista completa en 👉 www.elobservador.com.uy\n\n@salusuruguay @toyota_uruguay @tatasupermercados_uy\n\n#diálogosenmontevideo',
  },
  {
    date: '20241106',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241106',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241106',
    video_source: 'DBGg7FmJja-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGg7FmJja-_optimized-6vduZKTNwCQcfZDZpPy2V6W0wh4xSN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGg7FmJja-_preview-hZmPM0Zzx5oFrqTJD56hH81hxnMR2Y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGg7FmJja-_cover_resized-D4zHqXQKbatGCnAOi7Uuj98mqbExuv.jpg',
    caption:
      '⚽ Diego Godín: "El fútbol de hoy es un espectáculo y cada vez el fútbol se juega a más intensidad y te tenés que preparar mejor". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay \\n\\n#diálogosenmontevideo #diegogodín',
  },
  {
    date: '20241107',
    video_source: 'xxxxxxxxxx',
    impressions: 119826,
    video_views: 168,
    cta_click: 92,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241107',
    video_source: 'DCEXXjnx5wE',
    impressions: 64599,
    video_views: 377,
    cta_click: 36,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241107',
    video_source: 'DCAWCYUyhDn',
    impressions: 46850,
    video_views: 230,
    cta_click: 27,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241107',
    video_source: 'DCDKJzAuiEe',
    impressions: 43615,
    video_views: 224,
    cta_click: 6,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241107',
    video_source: 'DCAPkHNStRd',
    impressions: 32626,
    video_views: 178,
    cta_click: 21,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241107',
    video_source: 'DCCcn2NpQKV',
    impressions: 29269,
    video_views: 347,
    cta_click: 23,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241107',
    video_source: 'DCB0lxSJQZg',
    impressions: 22655,
    video_views: 149,
    cta_click: 5,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241107',
    video_source: 'DB_ysAFxW_k',
    impressions: 14699,
    video_views: 128,
    cta_click: 12,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241107',
    video_source: 'DB_WxMSIIVc',
    impressions: 40,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241107',
    video_source: 'DB8ojSaxgB7',
    impressions: 37,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241107',
    video_source: 'DB1TDq2iWa2',
    impressions: 23,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB1TDq2iWa2_optimized-4-L1O1R1B76zePYBQPBa6ygdc36djoxM.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB1TDq2iWa2_preview-jJ7Z4dYFFxI1WTad4iEQp8YmX4hN40.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB1TDq2iWa2-CxRt9WlZy1S7Y2NFY2a2OQ49WhiVxi.jpg',
    caption: '¿Cómo ven la economía de Estados Unidos en Nueva York? 🇺🇸',
  },
  {
    date: '20241107',
    video_source: 'DB9ToayxDH6',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB9ToayxDH6-gYnLD31nr9SyKhNZJdYjRlF9vj7f0z.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB9ToayxDH6_preview-SilNxex8sv5bTdn5QxJ1A1j9LMS2qg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB9ToayxDH6-BvPXIUUVTPzBpiR0ol4X8MTMGcKbSm.jpg',
    caption:
      '🎤 Lucas Sugo habló con Alejandro Fantino sobre haber tocado en el Estadio Centenario  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241107',
    video_source: 'DBzjaqzuSbA',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241107',
    video_source: 'DBziZpVSBuO',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241107',
    video_source: 'DB4K9ObpRHL',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241107',
    video_source: 'DB3nB2TJykC',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241107',
    video_source: 'DBgZj5xJdDs',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241107',
    video_source: 'DBd1QNMpg9F',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241107',
    video_source: 'DBw3S0aRIhB',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241107',
    video_source: 'DBzV42Aym4L',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241107',
    video_source: 'DBl-HYxJqXv',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241107',
    video_source: 'DBcQ2K0suss',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241107',
    video_source: 'DBuWJVIRWYD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBuWJVIRWYD-99XOOTwYatjxXALAhkGhmu3BGpKgtC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBuWJVIRWYD_preview-0V33zJEqqPMc2lWL56ukHCmrMUMPMv.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/2-DBuWJVIRWYD-C1EjBTt3vuYh9HmiMTX5zePpegZF0U.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado fueron los más votados y protagonizarán un balotaje que se definirá voto a voto. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241107',
    video_source: 'DBGg7FmJja-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGg7FmJja-_optimized-6vduZKTNwCQcfZDZpPy2V6W0wh4xSN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGg7FmJja-_preview-hZmPM0Zzx5oFrqTJD56hH81hxnMR2Y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGg7FmJja-_cover_resized-D4zHqXQKbatGCnAOi7Uuj98mqbExuv.jpg',
    caption:
      '⚽ Diego Godín: "El fútbol de hoy es un espectáculo y cada vez el fútbol se juega a más intensidad y te tenés que preparar mejor". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay \\n\\n#diálogosenmontevideo #diegogodín',
  },
  {
    date: '20241107',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241108',
    video_source: 'DCHKCTTJuEZ',
    impressions: 66117,
    video_views: 246,
    cta_click: 19,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241108',
    video_source: 'DCEXXjnx5wE',
    impressions: 65651,
    video_views: 377,
    cta_click: 39,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241108',
    video_source: 'DCB0lxSJQZg',
    impressions: 58480,
    video_views: 226,
    cta_click: 19,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241108',
    video_source: 'xxxxxxxxxx',
    impressions: 28362,
    video_views: 49,
    cta_click: 27,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241108',
    video_source: 'DB_ysAFxW_k',
    impressions: 26744,
    video_views: 158,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241108',
    video_source: 'DCDKJzAuiEe',
    impressions: 19932,
    video_views: 116,
    cta_click: 6,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241108',
    video_source: 'DCCcn2NpQKV',
    impressions: 14338,
    video_views: 150,
    cta_click: 4,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241108',
    video_source: 'DCAWCYUyhDn',
    impressions: 26,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241108',
    video_source: 'DCAPkHNStRd',
    impressions: 22,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241108',
    video_source: 'DB_WxMSIIVc',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241108',
    video_source: 'DB8ojSaxgB7',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241108',
    video_source: 'DBzV42Aym4L',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241108',
    video_source: 'DB1TDq2iWa2',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB1TDq2iWa2_optimized-4-L1O1R1B76zePYBQPBa6ygdc36djoxM.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB1TDq2iWa2_preview-jJ7Z4dYFFxI1WTad4iEQp8YmX4hN40.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB1TDq2iWa2-CxRt9WlZy1S7Y2NFY2a2OQ49WhiVxi.jpg',
    caption: '¿Cómo ven la economía de Estados Unidos en Nueva York? 🇺🇸',
  },
  {
    date: '20241108',
    video_source: 'DBw3S0aRIhB',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241108',
    video_source: 'DBziZpVSBuO',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241108',
    video_source: 'DBzjaqzuSbA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241108',
    video_source: 'DB4K9ObpRHL',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241108',
    video_source: 'DB3nB2TJykC',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241108',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241108',
    video_source: 'DBuWJVIRWYD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBuWJVIRWYD-99XOOTwYatjxXALAhkGhmu3BGpKgtC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBuWJVIRWYD_preview-0V33zJEqqPMc2lWL56ukHCmrMUMPMv.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/2-DBuWJVIRWYD-C1EjBTt3vuYh9HmiMTX5zePpegZF0U.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado fueron los más votados y protagonizarán un balotaje que se definirá voto a voto. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241108',
    video_source: 'DB9ToayxDH6',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB9ToayxDH6-gYnLD31nr9SyKhNZJdYjRlF9vj7f0z.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB9ToayxDH6_preview-SilNxex8sv5bTdn5QxJ1A1j9LMS2qg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB9ToayxDH6-BvPXIUUVTPzBpiR0ol4X8MTMGcKbSm.jpg',
    caption:
      '🎤 Lucas Sugo habló con Alejandro Fantino sobre haber tocado en el Estadio Centenario  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241108',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241108',
    video_source: 'DBcQ2K0suss',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241109',
    video_source: 'DCHKCTTJuEZ',
    impressions: 84864,
    video_views: 423,
    cta_click: 32,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241109',
    video_source: 'DCB0lxSJQZg',
    impressions: 76860,
    video_views: 443,
    cta_click: 39,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241109',
    video_source: 'DCEXXjnx5wE',
    impressions: 51554,
    video_views: 382,
    cta_click: 41,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241109',
    video_source: 'DB_ysAFxW_k',
    impressions: 35741,
    video_views: 332,
    cta_click: 24,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241109',
    video_source: 'xxxxxxxxxx',
    impressions: 25,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241109',
    video_source: 'DCAWCYUyhDn',
    impressions: 12,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241109',
    video_source: 'DCDKJzAuiEe',
    impressions: 11,
    video_views: 3,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241109',
    video_source: 'DCAPkHNStRd',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241109',
    video_source: 'DCCcn2NpQKV',
    impressions: 6,
    video_views: 2,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241109',
    video_source: 'DB8ojSaxgB7',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241109',
    video_source: 'DB_WxMSIIVc',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241109',
    video_source: 'DBl-HYxJqXv',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241109',
    video_source: 'DBcQ2K0suss',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241109',
    video_source: 'DBziZpVSBuO',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241109',
    video_source: 'DBzV42Aym4L',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241109',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241109',
    video_source: 'DBGg7FmJja-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGg7FmJja-_optimized-6vduZKTNwCQcfZDZpPy2V6W0wh4xSN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGg7FmJja-_preview-hZmPM0Zzx5oFrqTJD56hH81hxnMR2Y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGg7FmJja-_cover_resized-D4zHqXQKbatGCnAOi7Uuj98mqbExuv.jpg',
    caption:
      '⚽ Diego Godín: "El fútbol de hoy es un espectáculo y cada vez el fútbol se juega a más intensidad y te tenés que preparar mejor". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay \\n\\n#diálogosenmontevideo #diegogodín',
  },
  {
    date: '20241109',
    video_source: 'DBPLbr4y9JZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20241109',
    video_source: 'DB1TDq2iWa2',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB1TDq2iWa2_optimized-4-L1O1R1B76zePYBQPBa6ygdc36djoxM.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB1TDq2iWa2_preview-jJ7Z4dYFFxI1WTad4iEQp8YmX4hN40.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB1TDq2iWa2-CxRt9WlZy1S7Y2NFY2a2OQ49WhiVxi.jpg',
    caption: '¿Cómo ven la economía de Estados Unidos en Nueva York? 🇺🇸',
  },
  {
    date: '20241109',
    video_source: 'DBPW4r8yFfq',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPW4r8yFfq_optimized-8QMu6LXv993FqM8ZgkMFxL9MKlCny2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPW4r8yFfq_preview-QkeCfYxfbhqNiZEHY81BEDKq7VyfhG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPW4r8yFfq_cover_resized-UFMXCefXM8LMO3edrhPHtUDOmBKZ4q.jpg',
    caption:
      '🕹️ 🇺🇾 Sebastián Gastelumendi, el gamer profesional de Age of Empires que es top 10 en el mundo y compite por miles de dólares. Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @juanpablo.dm 📹 @agusabelenda #ageofempire #gamer',
  },
  {
    date: '20241109',
    video_source: 'DBBnU2vuRjw',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBBnU2vuRjw_optimized-3-kfOBJgnI8MOVnKjxKBHB1vFiZBcLGW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBBnU2vuRjw_preview-nLm6lIhAaop4oIRGYY1gfab9vZbfQ9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBBnU2vuRjw_cover_resized-Gp1GhVI1tX6DGQAf6HA8gK6x49ho2p.jpg',
    caption:
      '🗳️🇺🇾 ¿Cómo funciona el sistema de jubilaciones en Uruguay? ¿Cuáles son las diferencias entre régimen mixto, sistema de reparto y sistema de ahorro individual? \\n\\n🎙️ @piamestell te lo explica. \\n\\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241109',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241109',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241110',
    video_source: 'DCHKCTTJuEZ',
    impressions: 111790,
    video_views: 568,
    cta_click: 61,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241110',
    video_source: 'DCB0lxSJQZg',
    impressions: 109129,
    video_views: 641,
    cta_click: 58,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241110',
    video_source: 'DCEXXjnx5wE',
    impressions: 80464,
    video_views: 581,
    cta_click: 59,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241110',
    video_source: 'DB_ysAFxW_k',
    impressions: 56317,
    video_views: 457,
    cta_click: 38,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241110',
    video_source: 'xxxxxxxxxx',
    impressions: 18,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241110',
    video_source: 'DCAPkHNStRd',
    impressions: 12,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241110',
    video_source: 'DCAWCYUyhDn',
    impressions: 11,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241110',
    video_source: 'DCDKJzAuiEe',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241110',
    video_source: 'DCCcn2NpQKV',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241110',
    video_source: 'DB3nB2TJykC',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241110',
    video_source: 'DB8ojSaxgB7',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241110',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241110',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241110',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241110',
    video_source: 'DB1TDq2iWa2',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB1TDq2iWa2_optimized-4-L1O1R1B76zePYBQPBa6ygdc36djoxM.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB1TDq2iWa2_preview-jJ7Z4dYFFxI1WTad4iEQp8YmX4hN40.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB1TDq2iWa2-CxRt9WlZy1S7Y2NFY2a2OQ49WhiVxi.jpg',
    caption: '¿Cómo ven la economía de Estados Unidos en Nueva York? 🇺🇸',
  },
  {
    date: '20241110',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241110',
    video_source: 'DBGg7FmJja-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGg7FmJja-_optimized-6vduZKTNwCQcfZDZpPy2V6W0wh4xSN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGg7FmJja-_preview-hZmPM0Zzx5oFrqTJD56hH81hxnMR2Y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGg7FmJja-_cover_resized-D4zHqXQKbatGCnAOi7Uuj98mqbExuv.jpg',
    caption:
      '⚽ Diego Godín: "El fútbol de hoy es un espectáculo y cada vez el fútbol se juega a más intensidad y te tenés que preparar mejor". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay \\n\\n#diálogosenmontevideo #diegogodín',
  },
  {
    date: '20241110',
    video_source: 'DB_WxMSIIVc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241110',
    video_source: 'DBPLbr4y9JZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20241110',
    video_source: 'DBcnkzVujTi',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcnkzVujTi_optimized-bcF1DJGff3olq8c2q0oPBRI7NLykM3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcnkzVujTi_preview-9GSoQpqbxECbHEillurIrqu6i33KD1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcnkzVujTi_cover_resized-Iz63cijkg0frOHAIR0HzRraleqG3i5.jpg',
    caption:
      '🇺🇾 ¿Por qué las elecciones de este domingo son históricas?\n\n🗳️ Además de elegir representantes, también se votará el plebiscito de allanamientos nocturnos.\n\n🎙️ @joacopisa\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241110',
    video_source: 'DB4K9ObpRHL',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241110',
    video_source: 'DAoN4ofplgh',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DAoN4ofplgh_optimized-2-Ng1ZTfWpuSjHsf9Dhvmk26QnpDDXZ4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DAoN4ofplgh_preview-XeFoO4KO2h1LUOYmCauJYbdIRu11ZO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DAoN4ofplgh_cover_resized-e3XsDCaVtdOELcmHVGyx9AA6MJKVq8.jpg',
    caption:
      '🎸 Paul McCartney se presentó por tercera vez en Montevideo y así se vivió en el Estadio Centenario.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @nicotabarez93\n📹 @agusabelenda\n\n#paulmccartney',
  },
  {
    date: '20241110',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241110',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241110',
    video_source: 'DBPW4r8yFfq',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPW4r8yFfq_optimized-8QMu6LXv993FqM8ZgkMFxL9MKlCny2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPW4r8yFfq_preview-QkeCfYxfbhqNiZEHY81BEDKq7VyfhG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPW4r8yFfq_cover_resized-UFMXCefXM8LMO3edrhPHtUDOmBKZ4q.jpg',
    caption:
      '🕹️ 🇺🇾 Sebastián Gastelumendi, el gamer profesional de Age of Empires que es top 10 en el mundo y compite por miles de dólares. Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @juanpablo.dm 📹 @agusabelenda #ageofempire #gamer',
  },
  {
    date: '20241110',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241110',
    video_source: 'DBl-HYxJqXv',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241111',
    video_source: 'DCKN-7qS7am',
    impressions: 110190,
    video_views: 958,
    cta_click: 86,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241111',
    video_source: 'DCKa-cZyWSD',
    impressions: 97431,
    video_views: 1312,
    cta_click: 76,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241111',
    video_source: 'DCMPXy5xkXW',
    impressions: 67503,
    video_views: 1004,
    cta_click: 70,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241111',
    video_source: 'DCHKCTTJuEZ',
    impressions: 47969,
    video_views: 214,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241111',
    video_source: 'DCB0lxSJQZg',
    impressions: 47221,
    video_views: 216,
    cta_click: 18,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241111',
    video_source: 'DCKCgX0JK8_',
    impressions: 46725,
    video_views: 1163,
    cta_click: 78,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241111',
    video_source: 'DCEXXjnx5wE',
    impressions: 37965,
    video_views: 212,
    cta_click: 16,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241111',
    video_source: 'DB_ysAFxW_k',
    impressions: 27389,
    video_views: 182,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241111',
    video_source: 'xxxxxxxxxx',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241111',
    video_source: 'DCAWCYUyhDn',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241111',
    video_source: 'DCDKJzAuiEe',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241111',
    video_source: 'DCCcn2NpQKV',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241111',
    video_source: 'DCAPkHNStRd',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241111',
    video_source: 'DBzV42Aym4L',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241111',
    video_source: 'DBziZpVSBuO',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241111',
    video_source: 'DB8ojSaxgB7',
    impressions: 3,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241111',
    video_source: 'DB_WxMSIIVc',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241111',
    video_source: 'DB3nB2TJykC',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241111',
    video_source: 'DBzjaqzuSbA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241111',
    video_source: 'DB4K9ObpRHL',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241111',
    video_source: 'DB1TDq2iWa2',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB1TDq2iWa2_optimized-4-L1O1R1B76zePYBQPBa6ygdc36djoxM.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB1TDq2iWa2_preview-jJ7Z4dYFFxI1WTad4iEQp8YmX4hN40.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB1TDq2iWa2-CxRt9WlZy1S7Y2NFY2a2OQ49WhiVxi.jpg',
    caption: '¿Cómo ven la economía de Estados Unidos en Nueva York? 🇺🇸',
  },
  {
    date: '20241111',
    video_source: 'DBw3S0aRIhB',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241111',
    video_source: 'DBcQ2K0suss',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241111',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241112',
    video_source: 'DCRWT0euV3C',
    impressions: 116117,
    video_views: 565,
    cta_click: 43,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241112',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 102095,
    video_views: 575,
    cta_click: 43,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241112',
    video_source: 'DCPH3DtOrCc',
    impressions: 69735,
    video_views: 494,
    cta_click: 39,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241112',
    video_source: 'DCHKCTTJuEZ',
    impressions: 46093,
    video_views: 417,
    cta_click: 25,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241112',
    video_source: 'DCKN-7qS7am',
    impressions: 35717,
    video_views: 239,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241112',
    video_source: 'DCKa-cZyWSD',
    impressions: 32491,
    video_views: 329,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241112',
    video_source: 'DCMPXy5xkXW',
    impressions: 24416,
    video_views: 247,
    cta_click: 14,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241112',
    video_source: 'DCKCgX0JK8_',
    impressions: 17377,
    video_views: 317,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241112',
    video_source: 'DCB0lxSJQZg',
    impressions: 34,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241112',
    video_source: 'DCEXXjnx5wE',
    impressions: 26,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241112',
    video_source: 'DB_ysAFxW_k',
    impressions: 21,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241112',
    video_source: 'xxxxxxxxxx',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241112',
    video_source: 'DCAWCYUyhDn',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241112',
    video_source: 'DCAPkHNStRd',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241112',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241112',
    video_source: 'DCDKJzAuiEe',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241112',
    video_source: 'DBuWJVIRWYD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBuWJVIRWYD-99XOOTwYatjxXALAhkGhmu3BGpKgtC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBuWJVIRWYD_preview-0V33zJEqqPMc2lWL56ukHCmrMUMPMv.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/2-DBuWJVIRWYD-C1EjBTt3vuYh9HmiMTX5zePpegZF0U.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado fueron los más votados y protagonizarán un balotaje que se definirá voto a voto. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241112',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241112',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241112',
    video_source: 'DAwJ5uFSMB6',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DAwJ5uFSMB6_optimized-2-0weHkvhnLc2KTZpt0RB5QkxKepOa8Z.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DAwJ5uFSMB6_preview-Zk7EZgvKle75SXnPEr06ukhKasHukT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DAwJ5uFSMB6_cover_resized-nxEEECXnCUl6E9anpcKQSGXQpywI7E.jpg',
    caption:
      '🗣️ La piloto uruguaya Patricia Pita participó de Diálogos en Montevideo con Alejandro Fantino y comparte su camino en el automovilismo, desde sus primeros pasos en el atletismo hasta enfrentarse a las dunas del Dakar.\n\nPodrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy\n\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241112',
    video_source: 'DBPW4r8yFfq',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPW4r8yFfq_optimized-8QMu6LXv993FqM8ZgkMFxL9MKlCny2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPW4r8yFfq_preview-QkeCfYxfbhqNiZEHY81BEDKq7VyfhG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPW4r8yFfq_cover_resized-UFMXCefXM8LMO3edrhPHtUDOmBKZ4q.jpg',
    caption:
      '🕹️ 🇺🇾 Sebastián Gastelumendi, el gamer profesional de Age of Empires que es top 10 en el mundo y compite por miles de dólares. Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @juanpablo.dm 📹 @agusabelenda #ageofempire #gamer',
  },
  {
    date: '20241112',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241112',
    video_source: 'DBcQ2K0suss',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241112',
    video_source: 'DBBnU2vuRjw',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBBnU2vuRjw_optimized-3-kfOBJgnI8MOVnKjxKBHB1vFiZBcLGW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBBnU2vuRjw_preview-nLm6lIhAaop4oIRGYY1gfab9vZbfQ9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBBnU2vuRjw_cover_resized-Gp1GhVI1tX6DGQAf6HA8gK6x49ho2p.jpg',
    caption:
      '🗳️🇺🇾 ¿Cómo funciona el sistema de jubilaciones en Uruguay? ¿Cuáles son las diferencias entre régimen mixto, sistema de reparto y sistema de ahorro individual? \\n\\n🎙️ @piamestell te lo explica. \\n\\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241112',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241112',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241112',
    video_source: 'DBPLbr4y9JZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20241112',
    video_source: 'DAwZyRHyZS1',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DAwZyRHyZS1_optimized-8vvA07LCj47139qPiZ5Z2VWMQadoEu.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DAwZyRHyZS1_preview-uYOst9PQEySx9w8W64VWaKHSpg5qkV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DAwZyRHyZS1_cover_resized-ygQiPihXfnLOADU9jsQf2fxeE3VHRk.jpg',
    caption:
      '🗣️ Gustaf: “Tiene un gran talento el actor uruguayo, pero no tiene el entrenamiento que tiene el actor de una industria como Argentina”.\n\n📺 El actor asumió la tarea de conducir la versión uruguaya de ¡Ahora caigo! y habló con El Observador sobre ese desafío, la faceta actoral de su trabajo en televisión y cómo se lleva con la popularidad.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @nicotabarez93\n📹 @agusabelenda @_sil_fernandez',
  },
  {
    date: '20241112',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241113',
    video_source: 'DCRWT0euV3C',
    impressions: 82731,
    video_views: 618,
    cta_click: 48,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241113',
    video_source: 'DCT3TRWJh8y',
    impressions: 75299,
    video_views: 413,
    cta_click: 52,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241113',
    video_source: 'DCSRBHrys16',
    impressions: 56636,
    video_views: 434,
    cta_click: 22,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241113',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 41580,
    video_views: 240,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241113',
    video_source: 'DCPH3DtOrCc',
    impressions: 31200,
    video_views: 201,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241113',
    video_source: 'DCKa-cZyWSD',
    impressions: 25688,
    video_views: 490,
    cta_click: 33,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241113',
    video_source: 'DCHKCTTJuEZ',
    impressions: 21723,
    video_views: 172,
    cta_click: 15,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241113',
    video_source: 'DCKN-7qS7am',
    impressions: 27,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241113',
    video_source: 'DCMPXy5xkXW',
    impressions: 25,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241113',
    video_source: 'DCB0lxSJQZg',
    impressions: 20,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241113',
    video_source: 'DCKCgX0JK8_',
    impressions: 16,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241113',
    video_source: 'DCEXXjnx5wE',
    impressions: 12,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241113',
    video_source: 'DB_ysAFxW_k',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241113',
    video_source: 'xxxxxxxxxx',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241113',
    video_source: 'DCAPkHNStRd',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241113',
    video_source: 'DCAWCYUyhDn',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241113',
    video_source: 'DBl-HYxJqXv',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241113',
    video_source: 'DBcQ2K0suss',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241113',
    video_source: 'DB4K9ObpRHL',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241113',
    video_source: 'DCCcn2NpQKV',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241113',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241113',
    video_source: 'DBGg7FmJja-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGg7FmJja-_optimized-6vduZKTNwCQcfZDZpPy2V6W0wh4xSN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGg7FmJja-_preview-hZmPM0Zzx5oFrqTJD56hH81hxnMR2Y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGg7FmJja-_cover_resized-D4zHqXQKbatGCnAOi7Uuj98mqbExuv.jpg',
    caption:
      '⚽ Diego Godín: "El fútbol de hoy es un espectáculo y cada vez el fútbol se juega a más intensidad y te tenés que preparar mejor". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay \\n\\n#diálogosenmontevideo #diegogodín',
  },
  {
    date: '20241113',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241113',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241113',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241113',
    video_source: 'DCDKJzAuiEe',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241113',
    video_source: 'DBcnkzVujTi',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcnkzVujTi_optimized-bcF1DJGff3olq8c2q0oPBRI7NLykM3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcnkzVujTi_preview-9GSoQpqbxECbHEillurIrqu6i33KD1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcnkzVujTi_cover_resized-Iz63cijkg0frOHAIR0HzRraleqG3i5.jpg',
    caption:
      '🇺🇾 ¿Por qué las elecciones de este domingo son históricas?\n\n🗳️ Además de elegir representantes, también se votará el plebiscito de allanamientos nocturnos.\n\n🎙️ @joacopisa\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241114',
    video_source: 'DCT3TRWJh8y',
    impressions: 48925,
    video_views: 455,
    cta_click: 31,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241114',
    video_source: 'DCU0SJpssPM',
    impressions: 40300,
    video_views: 319,
    cta_click: 20,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241114',
    video_source: 'DCSRBHrys16',
    impressions: 37133,
    video_views: 391,
    cta_click: 20,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241114',
    video_source: 'DCWcslLxjUN',
    impressions: 29603,
    video_views: 679,
    cta_click: 80,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241114',
    video_source: 'DCRWT0euV3C',
    impressions: 16891,
    video_views: 140,
    cta_click: 9,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241114',
    video_source: 'DCKa-cZyWSD',
    impressions: 11755,
    video_views: 194,
    cta_click: 12,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241114',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 25,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241114',
    video_source: 'DCPH3DtOrCc',
    impressions: 16,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241114',
    video_source: 'DCHKCTTJuEZ',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241114',
    video_source: 'DCKN-7qS7am',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241114',
    video_source: 'DCMPXy5xkXW',
    impressions: 5,
    video_views: 4,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241114',
    video_source: 'DCEXXjnx5wE',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241114',
    video_source: 'DB_ysAFxW_k',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241114',
    video_source: 'DCKCgX0JK8_',
    impressions: 3,
    video_views: 2,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241114',
    video_source: 'DCB0lxSJQZg',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241114',
    video_source: 'DCAWCYUyhDn',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241114',
    video_source: 'xxxxxxxxxx',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241114',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241114',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241114',
    video_source: 'DBPW4r8yFfq',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPW4r8yFfq_optimized-8QMu6LXv993FqM8ZgkMFxL9MKlCny2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPW4r8yFfq_preview-QkeCfYxfbhqNiZEHY81BEDKq7VyfhG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPW4r8yFfq_cover_resized-UFMXCefXM8LMO3edrhPHtUDOmBKZ4q.jpg',
    caption:
      '🕹️ 🇺🇾 Sebastián Gastelumendi, el gamer profesional de Age of Empires que es top 10 en el mundo y compite por miles de dólares. Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @juanpablo.dm 📹 @agusabelenda #ageofempire #gamer',
  },
  {
    date: '20241114',
    video_source: 'DBPLbr4y9JZ',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 59694,
    video_views: 217,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241115',
    video_source: 'DCU0SJpssPM',
    impressions: 47399,
    video_views: 286,
    cta_click: 14,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241115',
    video_source: 'DCXdLdpyCqo',
    impressions: 38767,
    video_views: 169,
    cta_click: 11,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241115',
    video_source: 'DCWcslLxjUN',
    impressions: 37370,
    video_views: 646,
    cta_click: 84,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DCT3TRWJh8y',
    impressions: 27317,
    video_views: 278,
    cta_click: 16,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241115',
    video_source: 'DCZF0CsxkPY',
    impressions: 24216,
    video_views: 370,
    cta_click: 28,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DCSRBHrys16',
    impressions: 18836,
    video_views: 231,
    cta_click: 8,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DCXRuTJxx9-',
    impressions: 16105,
    video_views: 291,
    cta_click: 23,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DCRWT0euV3C',
    impressions: 19,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241115',
    video_source: 'DCHKCTTJuEZ',
    impressions: 12,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241115',
    video_source: 'DCKa-cZyWSD',
    impressions: 11,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241115',
    video_source: 'DCB0lxSJQZg',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241115',
    video_source: 'DCEXXjnx5wE',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241115',
    video_source: 'DCKN-7qS7am',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241115',
    video_source: 'DCPH3DtOrCc',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241115',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241115',
    video_source: 'DB_ysAFxW_k',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241115',
    video_source: 'DCKCgX0JK8_',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DBw3S0aRIhB',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241115',
    video_source: 'DCMPXy5xkXW',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241115',
    video_source: 'DB8ojSaxgB7',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241115',
    video_source: 'DCAPkHNStRd',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DCAWCYUyhDn',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241115',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241115',
    video_source: 'DBuWJVIRWYD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBuWJVIRWYD-99XOOTwYatjxXALAhkGhmu3BGpKgtC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBuWJVIRWYD_preview-0V33zJEqqPMc2lWL56ukHCmrMUMPMv.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/2-DBuWJVIRWYD-C1EjBTt3vuYh9HmiMTX5zePpegZF0U.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado fueron los más votados y protagonizarán un balotaje que se definirá voto a voto. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241115',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241115',
    video_source: 'DB3nB2TJykC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 138320,
    video_views: 574,
    cta_click: 60,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241116',
    video_source: 'DCXdLdpyCqo',
    impressions: 99110,
    video_views: 408,
    cta_click: 32,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241116',
    video_source: 'DCZF0CsxkPY',
    impressions: 66224,
    video_views: 758,
    cta_click: 56,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241116',
    video_source: 'DCXRuTJxx9-',
    impressions: 43896,
    video_views: 657,
    cta_click: 50,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241116',
    video_source: 'DCU0SJpssPM',
    impressions: 27,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241116',
    video_source: 'DCT3TRWJh8y',
    impressions: 24,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241116',
    video_source: 'DCWcslLxjUN',
    impressions: 22,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241116',
    video_source: 'DCSRBHrys16',
    impressions: 15,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241116',
    video_source: 'DCKa-cZyWSD',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241116',
    video_source: 'DCRWT0euV3C',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241116',
    video_source: 'DCMPXy5xkXW',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241116',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241116',
    video_source: 'DCKCgX0JK8_',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241116',
    video_source: 'DCKN-7qS7am',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241116',
    video_source: 'DCHKCTTJuEZ',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241116',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241116',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241116',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241116',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241116',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241116',
    video_source: 'DCPH3DtOrCc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241116',
    video_source: 'DCEXXjnx5wE',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 127508,
    video_views: 563,
    cta_click: 60,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241117',
    video_source: 'DCXdLdpyCqo',
    impressions: 100481,
    video_views: 434,
    cta_click: 24,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241117',
    video_source: 'DCZF0CsxkPY',
    impressions: 74530,
    video_views: 800,
    cta_click: 56,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241117',
    video_source: 'DCXRuTJxx9-',
    impressions: 50470,
    video_views: 698,
    cta_click: 55,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241117',
    video_source: 'DCU0SJpssPM',
    impressions: 10,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241117',
    video_source: 'DCRWT0euV3C',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241117',
    video_source: 'DCT3TRWJh8y',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241117',
    video_source: 'DCHKCTTJuEZ',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241117',
    video_source: 'DCWcslLxjUN',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241117',
    video_source: 'DCSRBHrys16',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241117',
    video_source: 'DCB0lxSJQZg',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241117',
    video_source: 'DCEXXjnx5wE',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241117',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241117',
    video_source: 'DCKa-cZyWSD',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241117',
    video_source: 'DCPH3DtOrCc',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241117',
    video_source: 'DCCcn2NpQKV',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241117',
    video_source: 'DCDKJzAuiEe',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241117',
    video_source: 'DCKN-7qS7am',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241117',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241117',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241117',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241117',
    video_source: 'DB_ysAFxW_k',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241117',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241117',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241117',
    video_source: 'DB8ojSaxgB7',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241117',
    video_source: 'DCKCgX0JK8_',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241117',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241117',
    video_source: 'xxxxxxxxxx',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241117',
    video_source: 'DBGg7FmJja-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGg7FmJja-_optimized-6vduZKTNwCQcfZDZpPy2V6W0wh4xSN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGg7FmJja-_preview-hZmPM0Zzx5oFrqTJD56hH81hxnMR2Y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGg7FmJja-_cover_resized-D4zHqXQKbatGCnAOi7Uuj98mqbExuv.jpg',
    caption:
      '⚽ Diego Godín: "El fútbol de hoy es un espectáculo y cada vez el fútbol se juega a más intensidad y te tenés que preparar mejor". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay \\n\\n#diálogosenmontevideo #diegogodín',
  },
  {
    date: '20241117',
    video_source: 'DBcQ2K0suss',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241118',
    video_source: 'DCfIugYs9iC',
    impressions: 116876,
    video_views: 2757,
    cta_click: 225,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241118',
    video_source: 'DCZk64_J7RI',
    impressions: 90529,
    video_views: 1752,
    cta_click: 93,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241118',
    video_source: 'DCZF0CsxkPY',
    impressions: 81047,
    video_views: 2131,
    cta_click: 76,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCcOrjmSBsb',
    impressions: 63502,
    video_views: 2242,
    cta_click: 173,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 56630,
    video_views: 181,
    cta_click: 14,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241118',
    video_source: 'DCXdLdpyCqo',
    impressions: 46616,
    video_views: 125,
    cta_click: 11,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241118',
    video_source: 'DCXRuTJxx9-',
    impressions: 25225,
    video_views: 208,
    cta_click: 18,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCU0SJpssPM',
    impressions: 17,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241118',
    video_source: 'DCWcslLxjUN',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCT3TRWJh8y',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241118',
    video_source: 'DB4K9ObpRHL',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241118',
    video_source: 'DCHKCTTJuEZ',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241118',
    video_source: 'DBziZpVSBuO',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241118',
    video_source: 'DB3nB2TJykC',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241118',
    video_source: 'DCEXXjnx5wE',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241118',
    video_source: 'DCSRBHrys16',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCKN-7qS7am',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241118',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241118',
    video_source: 'DBzjaqzuSbA',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCRWT0euV3C',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241118',
    video_source: 'DBgZj5xJdDs',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241118',
    video_source: 'DBw3S0aRIhB',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241118',
    video_source: 'xxxxxxxxxx',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241118',
    video_source: 'DB8ojSaxgB7',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241118',
    video_source: 'DCB0lxSJQZg',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241118',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241118',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241118',
    video_source: 'DBLtZ3_p7iW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBLtZ3_p7iW_optimized-TO6uS0moZlDvKGR2ieYgE14de8t9Qz.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBLtZ3_p7iW_preview-08Obq5tDchLtq0gqcDPRoAjmuOsNqB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBLtZ3_p7iW_cover_resized-xJncc4FBDeo3o55J8tSpNzmM6JWdXq.jpg',
    caption:
      '"🗣️ Diego Godín habló sobre Luis Suárez.\\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay"',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241118',
    video_source: 'DCKa-cZyWSD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241118',
    video_source: 'DCAPkHNStRd',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCPH3DtOrCc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241118',
    video_source: 'DB_ysAFxW_k',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241118',
    video_source: 'DBcnkzVujTi',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcnkzVujTi_optimized-bcF1DJGff3olq8c2q0oPBRI7NLykM3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcnkzVujTi_preview-9GSoQpqbxECbHEillurIrqu6i33KD1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcnkzVujTi_cover_resized-Iz63cijkg0frOHAIR0HzRraleqG3i5.jpg',
    caption:
      '🇺🇾 ¿Por qué las elecciones de este domingo son históricas?\n\n🗳️ Además de elegir representantes, también se votará el plebiscito de allanamientos nocturnos.\n\n🎙️ @joacopisa\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCAWCYUyhDn',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241118',
    video_source: 'DBPLbr4y9JZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20241118',
    video_source: 'DBuWJVIRWYD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBuWJVIRWYD-99XOOTwYatjxXALAhkGhmu3BGpKgtC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBuWJVIRWYD_preview-0V33zJEqqPMc2lWL56ukHCmrMUMPMv.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/2-DBuWJVIRWYD-C1EjBTt3vuYh9HmiMTX5zePpegZF0U.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado fueron los más votados y protagonizarán un balotaje que se definirá voto a voto. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCCcn2NpQKV',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241118',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241118',
    video_source: 'DB_WxMSIIVc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241118',
    video_source: 'DCDKJzAuiEe',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241119',
    video_source: 'DChC98KuTc0',
    impressions: 109141,
    video_views: 1891,
    cta_click: 147,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241119',
    video_source: 'DCfIugYs9iC',
    impressions: 80733,
    video_views: 1833,
    cta_click: 127,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241119',
    video_source: 'DCXRuTJxx9-',
    impressions: 79683,
    video_views: 1178,
    cta_click: 57,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241119',
    video_source: 'DCZF0CsxkPY',
    impressions: 48509,
    video_views: 1437,
    cta_click: 74,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241119',
    video_source: 'DCZk64_J7RI',
    impressions: 21414,
    video_views: 408,
    cta_click: 23,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241119',
    video_source: 'DCcOrjmSBsb',
    impressions: 15733,
    video_views: 497,
    cta_click: 40,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241119',
    video_source: 'DCXdLdpyCqo',
    impressions: 35,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241119',
    video_source: 'DCT3TRWJh8y',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241119',
    video_source: 'DCSRBHrys16',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241119',
    video_source: 'DCAWCYUyhDn',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241119',
    video_source: 'DCB0lxSJQZg',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241119',
    video_source: 'DCRWT0euV3C',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241119',
    video_source: 'DCHKCTTJuEZ',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241119',
    video_source: 'DCAPkHNStRd',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241119',
    video_source: 'DCKa-cZyWSD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241119',
    video_source: 'DCWcslLxjUN',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241119',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241119',
    video_source: 'DCU0SJpssPM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241119',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241119',
    video_source: 'DCEXXjnx5wE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241120',
    video_source: 'DChC98KuTc0',
    impressions: 159241,
    video_views: 2181,
    cta_click: 202,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DCXRuTJxx9-',
    impressions: 126785,
    video_views: 1700,
    cta_click: 103,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241120',
    video_source: 'DCfIugYs9iC',
    impressions: 92958,
    video_views: 1785,
    cta_click: 100,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DCZF0CsxkPY',
    impressions: 66364,
    video_views: 1408,
    cta_click: 64,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241120',
    video_source: 'DCXdLdpyCqo',
    impressions: 18,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241120',
    video_source: 'DCZk64_J7RI',
    impressions: 18,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241120',
    video_source: 'DCcOrjmSBsb',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DCU0SJpssPM',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241120',
    video_source: 'DCT3TRWJh8y',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DCKa-cZyWSD',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241120',
    video_source: 'DB4K9ObpRHL',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DCRWT0euV3C',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DCSRBHrys16',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241120',
    video_source: 'DBzV42Aym4L',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241120',
    video_source: 'DCEXXjnx5wE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241120',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241120',
    video_source: 'DCKCgX0JK8_',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241120',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241120',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241120',
    video_source: 'DCB0lxSJQZg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241120',
    video_source: 'DCKN-7qS7am',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241120',
    video_source: 'DB_ysAFxW_k',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241120',
    video_source: 'DCMPXy5xkXW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241120',
    video_source: 'DB3nB2TJykC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241120',
    video_source: 'DB8ojSaxgB7',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20241121',
    video_source: 'DChC98KuTc0',
    impressions: 93265,
    video_views: 1330,
    cta_click: 98,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241121',
    video_source: 'DCXRuTJxx9-',
    impressions: 78257,
    video_views: 910,
    cta_click: 54,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241121',
    video_source: 'DCfIugYs9iC',
    impressions: 62045,
    video_views: 1036,
    cta_click: 67,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241121',
    video_source: 'DCZF0CsxkPY',
    impressions: 45628,
    video_views: 835,
    cta_click: 32,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241121',
    video_source: 'DCZk64_J7RI',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241121',
    video_source: 'DCXdLdpyCqo',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241121',
    video_source: 'DCcOrjmSBsb',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241121',
    video_source: 'DBzjaqzuSbA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241121',
    video_source: 'DCU0SJpssPM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241121',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241121',
    video_source: 'DCDKJzAuiEe',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCDKJzAuiEe_optimized-3-4Gkh72i5Efr3vWxAjbtwiRmH0ga8cW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCDKJzAuiEe_preview-orr4TO10Qgu9ZyrN8wHplwt5QXcTnr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495682389483069726p-SIn8N00Wrv0gFZhpKxpnxvniioJkS2.jpg',
    caption:
      '🇺🇸🗳️ Elecciones 2024: Donald Trump vuelve a la Casa Blanca.\n\n➡️ Luego de superar los 270 votos electorales y de obtener importantes victorias en los estados clave, el candidato republicano se consagró como el 47º presidente de los Estados Unidos.\n\n🔎 El ex-presidente triunfó con más poder que nunca: ganó el voto popular, logró la mayoría en el Senado y está en camino a llevarse la Cámara de Representantes.\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241121',
    video_source: 'DCWcslLxjUN',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241121',
    video_source: 'DCT3TRWJh8y',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241121',
    video_source: 'DCSRBHrys16',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCSRBHrys16_optimized-LEl6fmvuDatGx980dzAnG7VRE9LKWA.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCSRBHrys16_preview-A1Q77vCwPtKMNFdc7FvbDHNlpXG2Nq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499934704181366138p-BFQfn5ywMhpQSxzeCtSvk9rjpkScS2.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado pusieron toda la maquinaria a funcionar para ganar el balotaje. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241121',
    video_source: 'DCKN-7qS7am',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241121',
    video_source: 'DCPH3DtOrCc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241121',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241121',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241121',
    video_source: 'DCHKCTTJuEZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241121',
    video_source: 'DCCcn2NpQKV',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCCcn2NpQKV_optimized-3-afniNKwFnTWb2XOLQgOjA633dP8nkC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCCcn2NpQKV_preview-1okKmvii4geNdLVmtekSIiwEwEk5Ti.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495482144365806229p-ouzwvGMGAP0DAsaOvHWg47mK74FG9J.jpg',
    caption:
      '🎾 Diego Forlán habló con El Observador y respondió un Ping Pong sobre su nuevo desafío como tenista profesional.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @sebaamaya77\n\n#diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241121',
    video_source: 'DCEXXjnx5wE',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241122',
    video_source: 'DChC98KuTc0',
    impressions: 70759,
    video_views: 1151,
    cta_click: 94,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241122',
    video_source: 'DCXRuTJxx9-',
    impressions: 57103,
    video_views: 795,
    cta_click: 50,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241122',
    video_source: 'DCfIugYs9iC',
    impressions: 46049,
    video_views: 959,
    cta_click: 74,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241122',
    video_source: 'DCZF0CsxkPY',
    impressions: 34313,
    video_views: 759,
    cta_click: 40,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241122',
    video_source: 'DCHKCTTJuEZ',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241122',
    video_source: 'DCB0lxSJQZg',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241122',
    video_source: 'DB_WxMSIIVc',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241122',
    video_source: 'DCXdLdpyCqo',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241122',
    video_source: 'DCcOrjmSBsb',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241122',
    video_source: 'DB_ysAFxW_k',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241122',
    video_source: 'DB8ojSaxgB7',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB8ojSaxgB7_optimized-GLbnuFXYdyoMsnh32dRa2vHNh2yYSK.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB8ojSaxgB7_preview-bYWzV40LYE5GHihBAaFNwzQKNvIuJN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB8ojSaxgB7-CiXROaS8f0SCfE0c7BOtevyPfXANc6.jpg',
    caption:
      '🗣️ Lucas Sugo : "Yo lanzo una canción hoy al igual que 350.000 personas que lanzaron hoy. Hay que remar mucho más, porque es mucho más fácil grabar para mí y para todos. Entonces el todo hace a que sea más difícil".  💬 Hablando con Alejandro Fantino en Diálogos en Montevideo, el cantante Lucas Sugo reflexiona sobre sus raíces en Rivera, su amor por la música y los lazos afectivos que ha cultivado desde el inicio de su carrera. Mirá la entrevista completa en 👉 www.elobservador.com.uy  #lucassugo',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241122',
    video_source: 'DCZk64_J7RI',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241122',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241122',
    video_source: 'xxxxxxxxxx',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241122',
    video_source: 'DBGg7FmJja-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGg7FmJja-_optimized-6vduZKTNwCQcfZDZpPy2V6W0wh4xSN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGg7FmJja-_preview-hZmPM0Zzx5oFrqTJD56hH81hxnMR2Y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGg7FmJja-_cover_resized-D4zHqXQKbatGCnAOi7Uuj98mqbExuv.jpg',
    caption:
      '⚽ Diego Godín: "El fútbol de hoy es un espectáculo y cada vez el fútbol se juega a más intensidad y te tenés que preparar mejor". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay \\n\\n#diálogosenmontevideo #diegogodín',
  },
  {
    date: '20241122',
    video_source: 'DCPH3DtOrCc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241122',
    video_source: 'DBcnkzVujTi',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcnkzVujTi_optimized-bcF1DJGff3olq8c2q0oPBRI7NLykM3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcnkzVujTi_preview-9GSoQpqbxECbHEillurIrqu6i33KD1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcnkzVujTi_cover_resized-Iz63cijkg0frOHAIR0HzRraleqG3i5.jpg',
    caption:
      '🇺🇾 ¿Por qué las elecciones de este domingo son históricas?\n\n🗳️ Además de elegir representantes, también se votará el plebiscito de allanamientos nocturnos.\n\n🎙️ @joacopisa\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241122',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241122',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241122',
    video_source: 'DBuWJVIRWYD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBuWJVIRWYD-99XOOTwYatjxXALAhkGhmu3BGpKgtC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBuWJVIRWYD_preview-0V33zJEqqPMc2lWL56ukHCmrMUMPMv.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/2-DBuWJVIRWYD-C1EjBTt3vuYh9HmiMTX5zePpegZF0U.jpg',
    caption:
      '🗳️ Yamandú Orsi y Álvaro Delgado fueron los más votados y protagonizarán un balotaje que se definirá voto a voto. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @santisoravilla  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241122',
    video_source: 'DCAPkHNStRd',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAPkHNStRd_optimized-bDsm3Lh3e6LjkUTQkGGiTioFUPF3Yr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAPkHNStRd_preview-fZrpIX5hrUFxojz8uMFiAZetthMySl.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494861763177534557p-RU9iyB0d4Kl80enTgHxY0mgS1HFfgf.jpg',
    caption:
      '🗳️ Terminó el escrutinio departamental y hay más novedades.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 🎙️ @diegocayota  Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241122',
    video_source: 'DCAWCYUyhDn',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20241122',
    video_source: 'DCEXXjnx5wE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241122',
    video_source: 'DBLtZ3_p7iW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBLtZ3_p7iW_optimized-TO6uS0moZlDvKGR2ieYgE14de8t9Qz.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBLtZ3_p7iW_preview-08Obq5tDchLtq0gqcDPRoAjmuOsNqB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBLtZ3_p7iW_cover_resized-xJncc4FBDeo3o55J8tSpNzmM6JWdXq.jpg',
    caption:
      '"🗣️ Diego Godín habló sobre Luis Suárez.\\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay"',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241122',
    video_source: 'DCU0SJpssPM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241122',
    video_source: 'DB1TDq2iWa2',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB1TDq2iWa2_optimized-4-L1O1R1B76zePYBQPBa6ygdc36djoxM.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB1TDq2iWa2_preview-jJ7Z4dYFFxI1WTad4iEQp8YmX4hN40.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB1TDq2iWa2-CxRt9WlZy1S7Y2NFY2a2OQ49WhiVxi.jpg',
    caption: '¿Cómo ven la economía de Estados Unidos en Nueva York? 🇺🇸',
  },
  {
    date: '20241122',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241122',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241123',
    video_source: 'DChC98KuTc0',
    impressions: 63084,
    video_views: 1099,
    cta_click: 84,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241123',
    video_source: 'DCXRuTJxx9-',
    impressions: 47431,
    video_views: 771,
    cta_click: 62,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241123',
    video_source: 'DCfIugYs9iC',
    impressions: 37461,
    video_views: 866,
    cta_click: 59,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241123',
    video_source: 'DCZF0CsxkPY',
    impressions: 28179,
    video_views: 700,
    cta_click: 38,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241123',
    video_source: 'DCRWT0euV3C',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCRWT0euV3C_optimized-I5lxwjUy6jt8yC6sDOb5amFKTNwB92.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCRWT0euV3C_preview-jKsOtt9tX8cAU1oImjaMqfdRjeIUR3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499676504486927810p-96dGzkWsCvGHnqyTdeg6SBzJBoveMG.jpg',
    caption:
      '💬 Carlos Lecueder: "Hoy viene gente a invertir en Uruguay con la convicción de que invierten acá y duermen tranquilos".  ➡️ El empresario Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241123',
    video_source: 'DCXdLdpyCqo',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241123',
    video_source: 'DCWcslLxjUN',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241123',
    video_source: 'DBw3S0aRIhB',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241123',
    video_source: 'DCZk64_J7RI',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241123',
    video_source: 'DCcOrjmSBsb',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241123',
    video_source: 'DCPH3DtOrCc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCPH3DtOrCc-xucIgSgqaWnNb4silcnAMgz14cvMB9.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCPH3DtOrCc_preview-MMw8KxTTWG7rDD83TNIXFVG6afVq52.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3499050005367009436p-9ZqXk0JdNddxs1CiAYZGBVoeSCfg6x.jpg',
    caption:
      '💬 Carlos Lecueder: "La guita es importante cuando uno la usa como un medio para un fin. El fin es generar el proyecto, el fin es hacer cosas, el fin es generar puestos de trabajo".  En una charla con Alejandro Fantino, Lecueder hizo un repaso de su trayectoria, habló de la importancia de su familia, sobre sus éxitos pero también de sus fracasos.  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #diálogosenmontevideo #carloslecueder',
  },
  {
    date: '20241123',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241123',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241123',
    video_source: 'DCHKCTTJuEZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241123',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241123',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241123',
    video_source: 'DCT3TRWJh8y',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241123',
    video_source: 'DCJ2uAPJ_Ql',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCJ2uAPJ_Ql_optimized-A8FdL7kCwtUraQtsVaNVbenYHwzlW8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCJ2uAPJ_Ql_preview-Q5Loq3m6y56WRn84x7EllQhxYf8g8J.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497567241491706917p-DnIRtiKSQjzeTDVJgDcTthHUBqxKFA.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy #inteligenciaartificial',
  },
  {
    date: '20241124',
    video_source: 'DChC98KuTc0',
    impressions: 72648,
    video_views: 958,
    cta_click: 79,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241124',
    video_source: 'DCXRuTJxx9-',
    impressions: 53273,
    video_views: 694,
    cta_click: 46,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241124',
    video_source: 'DCfIugYs9iC',
    impressions: 41203,
    video_views: 716,
    cta_click: 50,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241124',
    video_source: 'DCZF0CsxkPY',
    impressions: 30752,
    video_views: 591,
    cta_click: 37,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241124',
    video_source: 'DCHKCTTJuEZ',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241124',
    video_source: 'DCXdLdpyCqo',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241124',
    video_source: 'DCB0lxSJQZg',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241124',
    video_source: 'DBzjaqzuSbA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241124',
    video_source: 'DB_ysAFxW_k',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241124',
    video_source: 'DCEXXjnx5wE',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241124',
    video_source: 'DCcOrjmSBsb',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241124',
    video_source: 'DCZk64_J7RI',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241124',
    video_source: 'DBgZj5xJdDs',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241124',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241124',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241124',
    video_source: 'DCT3TRWJh8y',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241124',
    video_source: 'DB_WxMSIIVc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241124',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241124',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241124',
    video_source: 'DBEGDrcxmL6',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBEGDrcxmL6_optimized-fQywJZn67Ou07hkgHWFgMQw7PhUXeV.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBEGDrcxmL6_preview-OYDyzBCnuJwY62RUGRno4kR9kkGrzH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBEGDrcxmL6_cover_resized-yOITnha49tpbxQBMPRIuFOPq6nbGlZ.jpg',
    caption:
      '⚽️ Diego Godín: "El Maestro para nosotros fue mucho más que un director técnico". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro. \\n\\nPodrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241124',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241125',
    video_source: 'DChC98KuTc0',
    impressions: 72005,
    video_views: 820,
    cta_click: 63,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241125',
    video_source: 'DCXRuTJxx9-',
    impressions: 59112,
    video_views: 619,
    cta_click: 38,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241125',
    video_source: 'DCfIugYs9iC',
    impressions: 49245,
    video_views: 647,
    cta_click: 39,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241125',
    video_source: 'DCtqssfJecY',
    impressions: 46983,
    video_views: 173,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241125',
    video_source: 'DCZF0CsxkPY',
    impressions: 37034,
    video_views: 574,
    cta_click: 29,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241125',
    video_source: 'DCr_otlR9IE',
    impressions: 25690,
    video_views: 96,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241125',
    video_source: 'DCpaQyRyPEU',
    impressions: 17886,
    video_views: 157,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241125',
    video_source: 'DCm-bnsxQbh',
    impressions: 11828,
    video_views: 211,
    cta_click: 19,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241125',
    video_source: 'DCKN-7qS7am',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKN-7qS7am_optimized-SXMlZrit1KfOUATB6IUS0vYBAShiBJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKN-7qS7am_preview-C6EPeGhHawx1nI5ShbyiSmm1sp9BGs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497669559879186086p-j1d3WxATE9Jz0SObCb1u8XQQBjhJxd.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241125',
    video_source: 'DCKa-cZyWSD',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKa-cZyWSD_optimized-Al2mUR3TudCoynXpIufdKKtdjPQGYQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKa-cZyWSD_preview-MLGibWSElSVvAYilkAKIN59hFzpZEn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497726700920857731p-cQEJrXDhW5FxWtXAYaqxEIyHwXmA6h.jpg',
    caption:
      '🗣️ El senador electo por el Partido Nacional, Sebastián Da Silva, estuvo en El Observador y habló sobre el caso eventual de que Álvaro Delgado gane las elecciones y le toque gobernar con una mayoría parlamentaria del Frente Amplio. Conocé más detalles en 👉 www.elobservador.com.uy #sebastiándasilva',
  },
  {
    date: '20241125',
    video_source: 'DCMPXy5xkXW',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCMPXy5xkXW_optimized-g6o6SvsolwL0RTWd7pvp9k6BiAdMvI.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCMPXy5xkXW_preview-xjx6OLAmoQMUPLlcormUOp0N0llioF.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3498238616462050774p-DoKk04QjLejmdt55LtkYFnSInhHPG3.jpg',
    caption:
      '🗣️ Carlos Lecueder recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241125',
    video_source: 'DCKCgX0JK8_',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCKCgX0JK8__optimized-hu1qk68WDOdLvW7GDcLjSbhfPEdssy.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCKCgX0JK8__preview-vpCCV7GoXZEUIfInehT351yWFg6cTD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3497619081293770559p-E8Y0qp1hfGQkfSxKMMHnpaBqdOUT8G.jpg',
    caption:
      '🗣️ El senador nacionalista, Sebastián da Silva, estuvo en El Observador y habló sobre la vez que publicó una placa en la red social X que decía \\"Mi patria sin izquierda\\". Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241125',
    video_source: 'DCU0SJpssPM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241125',
    video_source: 'DCZk64_J7RI',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241125',
    video_source: 'DCcOrjmSBsb',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241125',
    video_source: 'DCHKCTTJuEZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241125',
    video_source: 'DB3nB2TJykC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241125',
    video_source: 'DCXdLdpyCqo',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241126',
    video_source: 'DCzvO-EokGM',
    impressions: 93716,
    video_views: 318,
    cta_click: 30,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241126',
    video_source: 'DCtqssfJecY',
    impressions: 89821,
    video_views: 380,
    cta_click: 18,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241126',
    video_source: 'DCpaQyRyPEU',
    impressions: 79937,
    video_views: 545,
    cta_click: 35,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241126',
    video_source: 'DCr_otlR9IE',
    impressions: 56953,
    video_views: 301,
    cta_click: 18,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241126',
    video_source: 'DCm-bnsxQbh',
    impressions: 13791,
    video_views: 226,
    cta_click: 14,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241126',
    video_source: 'DChC98KuTc0',
    impressions: 73,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241126',
    video_source: 'DCXRuTJxx9-',
    impressions: 42,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241126',
    video_source: 'DCfIugYs9iC',
    impressions: 38,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241126',
    video_source: 'DCZF0CsxkPY',
    impressions: 24,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241126',
    video_source: 'DBcQ2K0suss',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241126',
    video_source: 'DCT3TRWJh8y',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241126',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241126',
    video_source: 'DCXdLdpyCqo',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241126',
    video_source: 'DCHKCTTJuEZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241126',
    video_source: 'DB_WxMSIIVc',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_WxMSIIVc_optimized-3-vuQuEbo01rduM9l8qoKYCIqDuSoDNs.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_WxMSIIVc_preview-K0OKPQgZ7DLGduukx7nYPiTXlcm83d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB_WxMSIIVc-qlUAquTA99Q1OYWOfCmpaQpdxvdkIH.jpg',
    caption:
      '🇺🇸 Elecciones de Estados Unidos y apuestas. La carrera electoral entre Donald Trump y Kamala Harris es una de las más peleadas de la historia y eso se refleja en la cantidad de gente apostando sobre el resultado.  Conocé más detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241126',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241126',
    video_source: 'DCU0SJpssPM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241126',
    video_source: 'DCWcslLxjUN',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241126',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241127',
    video_source: 'DC2QiC2PA5d',
    impressions: 87082,
    video_views: 469,
    cta_click: 41,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241127',
    video_source: 'DCzvO-EokGM',
    impressions: 69809,
    video_views: 306,
    cta_click: 26,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241127',
    video_source: 'DC1mHB-OfUA',
    impressions: 56877,
    video_views: 281,
    cta_click: 23,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241127',
    video_source: 'DCtqssfJecY',
    impressions: 36973,
    video_views: 318,
    cta_click: 34,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241127',
    video_source: 'DCpaQyRyPEU',
    impressions: 24016,
    video_views: 154,
    cta_click: 5,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241127',
    video_source: 'DCr_otlR9IE',
    impressions: 12031,
    video_views: 62,
    cta_click: 4,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241127',
    video_source: 'DChC98KuTc0',
    impressions: 23,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241127',
    video_source: 'DCXRuTJxx9-',
    impressions: 19,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241127',
    video_source: 'DCfIugYs9iC',
    impressions: 17,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241127',
    video_source: 'DCm-bnsxQbh',
    impressions: 17,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241127',
    video_source: 'DCZF0CsxkPY',
    impressions: 13,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241127',
    video_source: 'DCXdLdpyCqo',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241127',
    video_source: 'DCcOrjmSBsb',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241127',
    video_source: 'DCZk64_J7RI',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241128',
    video_source: 'DC2QiC2PA5d',
    impressions: 102871,
    video_views: 572,
    cta_click: 40,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241128',
    video_source: 'DC4fJSkJRV-',
    impressions: 88965,
    video_views: 415,
    cta_click: 42,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241128',
    video_source: 'DC1mHB-OfUA',
    impressions: 71385,
    video_views: 354,
    cta_click: 32,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241128',
    video_source: 'DCzvO-EokGM',
    impressions: 43247,
    video_views: 425,
    cta_click: 53,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241128',
    video_source: 'DCtqssfJecY',
    impressions: 13516,
    video_views: 149,
    cta_click: 19,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241128',
    video_source: 'DCpaQyRyPEU',
    impressions: 18,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241128',
    video_source: 'DChC98KuTc0',
    impressions: 10,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241128',
    video_source: 'DCr_otlR9IE',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241128',
    video_source: 'DCXRuTJxx9-',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241128',
    video_source: 'DCfIugYs9iC',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241128',
    video_source: 'DCZF0CsxkPY',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241128',
    video_source: 'DCU0SJpssPM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCU0SJpssPM_optimized-Y6kWGQUtmR909QPHIa8wpPZoaZkk3g.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCU0SJpssPM_preview-F5kwgyXB3BFNaLzTziQatV2mGCjz0E.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500652756106134476p-RktJGaCrwkJmcb6Qjxf19RxZ8jjZXX.jpg',
    caption:
      '🗳️ Voto Buquebus, movidas en Montevideo y preocupación por los caídos\n\n💬 Los días están contados. Faltan 11 para que sepamos quién es el nuevo presidente y las fotos de las encuestas muestran a Yamandú Orsi con una leve ventaja sobre Álvaro Delgado.\n\n➡️ Leé la nueva edición de #GalletaDeCampaña\n\n✍️ @diegocayota @santisoravilla\n\n#elecciones2024 #yamandúorsi #álvarodelgado',
  },
  {
    date: '20241128',
    video_source: 'DCm-bnsxQbh',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241128',
    video_source: 'DCWcslLxjUN',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241129',
    video_source: 'DC4fJSkJRV-',
    impressions: 80796,
    video_views: 473,
    cta_click: 30,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241129',
    video_source: 'DC2QiC2PA5d',
    impressions: 52860,
    video_views: 384,
    cta_click: 26,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241129',
    video_source: 'DC1mHB-OfUA',
    impressions: 39668,
    video_views: 238,
    cta_click: 26,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241129',
    video_source: 'DCzvO-EokGM',
    impressions: 23026,
    video_views: 351,
    cta_click: 67,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241129',
    video_source: 'DCtqssfJecY',
    impressions: 13,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241129',
    video_source: 'DCr_otlR9IE',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241129',
    video_source: 'DCpaQyRyPEU',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241129',
    video_source: 'DChC98KuTc0',
    impressions: 4,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241129',
    video_source: 'DCXRuTJxx9-',
    impressions: 2,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241129',
    video_source: 'DCfIugYs9iC',
    impressions: 2,
    video_views: 2,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241129',
    video_source: 'DCXdLdpyCqo',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241129',
    video_source: 'DCZF0CsxkPY',
    impressions: 2,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241129',
    video_source: 'DB_ysAFxW_k',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB_ysAFxW_k_optimized-a7Zb2LdaxRZRiMkzMnFsV4ICoDnWuN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB_ysAFxW_k_preview-wQkLvR9ezu1RJ0N7OxrUtA2ImyS0l2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494734761942151140p-VXCavsiKI29GHrHyW8pvMJ7UKUm2dq.jpg',
    caption:
      '🎤 Lucas Sugo: "Cinco minutos fue un caso excepcional de que vi un informativo y también hubo un sacudón emocional. La canción Cinco Minutos yo la escribí pensando en otra cosa, no en una historia de amor clásica, por eso quiero contextualizar mejor. Estaba mirando un informativo y me sentí sacudido".  Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241129',
    video_source: 'DCB0lxSJQZg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241129',
    video_source: 'DCZk64_J7RI',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241129',
    video_source: 'DCHKCTTJuEZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241129',
    video_source: 'DCcOrjmSBsb',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241129',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241129',
    video_source: 'DBPW4r8yFfq',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPW4r8yFfq_optimized-8QMu6LXv993FqM8ZgkMFxL9MKlCny2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPW4r8yFfq_preview-QkeCfYxfbhqNiZEHY81BEDKq7VyfhG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPW4r8yFfq_cover_resized-UFMXCefXM8LMO3edrhPHtUDOmBKZ4q.jpg',
    caption:
      '🕹️ 🇺🇾 Sebastián Gastelumendi, el gamer profesional de Age of Empires que es top 10 en el mundo y compite por miles de dólares. Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @juanpablo.dm 📹 @agusabelenda #ageofempire #gamer',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241129',
    video_source: 'DBBnU2vuRjw',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBBnU2vuRjw_optimized-3-kfOBJgnI8MOVnKjxKBHB1vFiZBcLGW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBBnU2vuRjw_preview-nLm6lIhAaop4oIRGYY1gfab9vZbfQ9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBBnU2vuRjw_cover_resized-Gp1GhVI1tX6DGQAf6HA8gK6x49ho2p.jpg',
    caption:
      '🗳️🇺🇾 ¿Cómo funciona el sistema de jubilaciones en Uruguay? ¿Cuáles son las diferencias entre régimen mixto, sistema de reparto y sistema de ahorro individual? \\n\\n🎙️ @piamestell te lo explica. \\n\\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241129',
    video_source: 'DBPLbr4y9JZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20241130',
    video_source: 'DC4fJSkJRV-',
    impressions: 64075,
    video_views: 453,
    cta_click: 57,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241130',
    video_source: 'DC2QiC2PA5d',
    impressions: 42828,
    video_views: 357,
    cta_click: 35,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241130',
    video_source: 'DC1mHB-OfUA',
    impressions: 32206,
    video_views: 241,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241130',
    video_source: 'DCzvO-EokGM',
    impressions: 19847,
    video_views: 332,
    cta_click: 55,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241130',
    video_source: 'DChC98KuTc0',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241130',
    video_source: 'DCtqssfJecY',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241130',
    video_source: 'DCXRuTJxx9-',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241130',
    video_source: 'DCr_otlR9IE',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241130',
    video_source: 'DCm-bnsxQbh',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241130',
    video_source: 'DCfIugYs9iC',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241130',
    video_source: 'DCZF0CsxkPY',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241130',
    video_source: 'DCpaQyRyPEU',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241130',
    video_source: 'DBzV42Aym4L',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzV42Aym4L_optimized-hUh9heSHmYiJgo0FxDj9UalVAHVOV2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzV42Aym4L_preview-hMLphU4IirsLtqSb8Jc0sZtCqNjVFo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491230401405021707p-uAtIKdPM8mLLxrq96r2cj6updI7zzm.jpg',
    caption:
      '🤔🎮 ¿Qué tan gamers somos los uruguayos? 🇺🇾 Estimaciones hechas por una tienda con más de 15 años en el sector indican que el 30% de los hogares en Uruguay tiene una consola. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #gamers #uruguay #videojuegos',
  },
  {
    date: '20241130',
    video_source: 'DCEXXjnx5wE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241130',
    video_source: 'xxxxxxxxxx',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241130',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241130',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241130',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241201',
    video_source: 'DC4fJSkJRV-',
    impressions: 79798,
    video_views: 429,
    cta_click: 170,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241201',
    video_source: 'DC2QiC2PA5d',
    impressions: 51716,
    video_views: 332,
    cta_click: 42,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241201',
    video_source: 'DC1mHB-OfUA',
    impressions: 38254,
    video_views: 250,
    cta_click: 26,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241201',
    video_source: 'DCzvO-EokGM',
    impressions: 24219,
    video_views: 341,
    cta_click: 70,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241201',
    video_source: 'DCXRuTJxx9-',
    impressions: 10,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241201',
    video_source: 'DChC98KuTc0',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241201',
    video_source: 'DCtqssfJecY',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241201',
    video_source: 'DCZF0CsxkPY',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241201',
    video_source: 'DCfIugYs9iC',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241201',
    video_source: 'DCpaQyRyPEU',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241201',
    video_source: 'DCr_otlR9IE',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241201',
    video_source: 'DCXdLdpyCqo',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241201',
    video_source: 'DBcnkzVujTi',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcnkzVujTi_optimized-bcF1DJGff3olq8c2q0oPBRI7NLykM3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcnkzVujTi_preview-9GSoQpqbxECbHEillurIrqu6i33KD1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcnkzVujTi_cover_resized-Iz63cijkg0frOHAIR0HzRraleqG3i5.jpg',
    caption:
      '🇺🇾 ¿Por qué las elecciones de este domingo son históricas?\n\n🗳️ Además de elegir representantes, también se votará el plebiscito de allanamientos nocturnos.\n\n🎙️ @joacopisa\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241201',
    video_source: 'DCm-bnsxQbh',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241201',
    video_source: 'DBPLbr4y9JZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20241201',
    video_source: 'DBPW4r8yFfq',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPW4r8yFfq_optimized-8QMu6LXv993FqM8ZgkMFxL9MKlCny2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPW4r8yFfq_preview-QkeCfYxfbhqNiZEHY81BEDKq7VyfhG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPW4r8yFfq_cover_resized-UFMXCefXM8LMO3edrhPHtUDOmBKZ4q.jpg',
    caption:
      '🕹️ 🇺🇾 Sebastián Gastelumendi, el gamer profesional de Age of Empires que es top 10 en el mundo y compite por miles de dólares. Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @juanpablo.dm 📹 @agusabelenda #ageofempire #gamer',
  },
  {
    date: '20241201',
    video_source: 'DCHKCTTJuEZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241201',
    video_source: 'DCB0lxSJQZg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241201',
    video_source: 'DBBnU2vuRjw',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBBnU2vuRjw_optimized-3-kfOBJgnI8MOVnKjxKBHB1vFiZBcLGW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBBnU2vuRjw_preview-nLm6lIhAaop4oIRGYY1gfab9vZbfQ9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBBnU2vuRjw_cover_resized-Gp1GhVI1tX6DGQAf6HA8gK6x49ho2p.jpg',
    caption:
      '🗳️🇺🇾 ¿Cómo funciona el sistema de jubilaciones en Uruguay? ¿Cuáles son las diferencias entre régimen mixto, sistema de reparto y sistema de ahorro individual? \\n\\n🎙️ @piamestell te lo explica. \\n\\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241201',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241201',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241201',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241202',
    video_source: 'DC4fJSkJRV-',
    impressions: 56547,
    video_views: 242,
    cta_click: 39,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241202',
    video_source: 'DC2QiC2PA5d',
    impressions: 43438,
    video_views: 165,
    cta_click: 16,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241202',
    video_source: 'DC-R16POTVW',
    impressions: 38189,
    video_views: 128,
    cta_click: 22,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241202',
    video_source: 'DC1mHB-OfUA',
    impressions: 35188,
    video_views: 134,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241202',
    video_source: 'DDAZB8_yODE',
    impressions: 21956,
    video_views: 90,
    cta_click: 15,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241202',
    video_source: 'DCzvO-EokGM',
    impressions: 21679,
    video_views: 175,
    cta_click: 31,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241202',
    video_source: 'DC_uqr3JAGg',
    impressions: 14601,
    video_views: 59,
    cta_click: 3,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241202',
    video_source: 'DDCb4b7xsFM',
    impressions: 7357,
    video_views: 76,
    cta_click: 16,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241202',
    video_source: 'DCtqssfJecY',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241202',
    video_source: 'DCpaQyRyPEU',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241203',
    video_source: 'DCXRuTJxx9-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241202',
    video_source: 'DCr_otlR9IE',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241202',
    video_source: 'DCZF0CsxkPY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241202',
    video_source: 'DB3nB2TJykC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB3nB2TJykC_optimized-6FujdZU7eSqELDe17wW24SLEH7rE14.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB3nB2TJykC_preview-HJ6tQ4JdZmuuBRNsvLTPThkmBcAQe1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB3nB2TJykC-J9602eW89CF0sS7vqVbOwxZrvrLsyk.jpg',
    caption:
      '🗳️ Estrategias electorales para llegar al Senado: cómo listas con pocos votos logran bancas en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  📹 @agusabelenda  #elecciones',
  },
  {
    date: '20241202',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241202',
    video_source: 'DB4K9ObpRHL',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DB4K9ObpRHL-wylg22IIAaJRMkM44r8YrC6veK2pAB.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DB4K9ObpRHL_preview-zb5lA7v2lpd9rR97SXN5HJ04zv8h1G.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DB4K9ObpRHL-0HitKLh89gh9oAxOE89GkPNZ0uKR2f.jpg',
    caption:
      '🗣️ El cantante uruguayo Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino.  Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241202',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241202',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241202',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241202',
    video_source: 'DBcnkzVujTi',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcnkzVujTi_optimized-bcF1DJGff3olq8c2q0oPBRI7NLykM3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcnkzVujTi_preview-9GSoQpqbxECbHEillurIrqu6i33KD1.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcnkzVujTi_cover_resized-Iz63cijkg0frOHAIR0HzRraleqG3i5.jpg',
    caption:
      '🇺🇾 ¿Por qué las elecciones de este domingo son históricas?\n\n🗳️ Además de elegir representantes, también se votará el plebiscito de allanamientos nocturnos.\n\n🎙️ @joacopisa\n\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241202',
    video_source: 'DCXdLdpyCqo',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241203',
    video_source: 'DDHTuNZx0ZD',
    impressions: 69412,
    video_views: 214,
    cta_click: 27,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241203',
    video_source: 'DC-R16POTVW',
    impressions: 68618,
    video_views: 233,
    cta_click: 21,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241203',
    video_source: 'DDCb4b7xsFM',
    impressions: 35124,
    video_views: 162,
    cta_click: 18,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241203',
    video_source: 'DDAZB8_yODE',
    impressions: 17346,
    video_views: 68,
    cta_click: 7,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241203',
    video_source: 'DC9vkoKSB9T',
    impressions: 15570,
    video_views: 172,
    cta_click: 26,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC9vkoKSB9T_optimized-TM8yXxEKXoY62vVilVU6GsyiCxfnD4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC9vkoKSB9T_preview-fp68aeslQMyrmj8w5dEtLcB5bwPuLj.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512172509628211027p-RmkUYhcoJSzQy87avMCNurs18iUTdq.jpg',
    caption:
      '💻El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241203',
    video_source: 'DC_uqr3JAGg',
    impressions: 12099,
    video_views: 57,
    cta_click: 4,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241203',
    video_source: 'DC4fJSkJRV-',
    impressions: 47,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241203',
    video_source: 'DC2QiC2PA5d',
    impressions: 33,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241203',
    video_source: 'DC1mHB-OfUA',
    impressions: 25,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241203',
    video_source: 'DCzvO-EokGM',
    impressions: 19,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241203',
    video_source: 'DCtqssfJecY',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241203',
    video_source: 'DCpaQyRyPEU',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241203',
    video_source: 'DCXRuTJxx9-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241203',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241203',
    video_source: 'DCr_otlR9IE',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241204',
    video_source: 'DDHTuNZx0ZD',
    impressions: 50598,
    video_views: 320,
    cta_click: 59,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241204',
    video_source: 'DDIRcgASVUR',
    impressions: 49956,
    video_views: 286,
    cta_click: 29,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241204',
    video_source: 'DDJ_W_rJlEA',
    impressions: 28771,
    video_views: 205,
    cta_click: 11,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241204',
    video_source: 'DC-R16POTVW',
    impressions: 26700,
    video_views: 74,
    cta_click: 5,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241204',
    video_source: 'DDCb4b7xsFM',
    impressions: 20208,
    video_views: 55,
    cta_click: 8,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241204',
    video_source: 'DDIhgwzyKyY',
    impressions: 18727,
    video_views: 243,
    cta_click: 29,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241204',
    video_source: 'DC9vkoKSB9T',
    impressions: 11521,
    video_views: 94,
    cta_click: 11,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC9vkoKSB9T_optimized-TM8yXxEKXoY62vVilVU6GsyiCxfnD4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC9vkoKSB9T_preview-fp68aeslQMyrmj8w5dEtLcB5bwPuLj.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512172509628211027p-RmkUYhcoJSzQy87avMCNurs18iUTdq.jpg',
    caption:
      '💻El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241204',
    video_source: 'DC4fJSkJRV-',
    impressions: 16,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241204',
    video_source: 'DDAZB8_yODE',
    impressions: 15,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241204',
    video_source: 'DC2QiC2PA5d',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241204',
    video_source: 'DC_uqr3JAGg',
    impressions: 11,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241204',
    video_source: 'DCzvO-EokGM',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241204',
    video_source: 'DC1mHB-OfUA',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241204',
    video_source: 'DCXRuTJxx9-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241204',
    video_source: 'DChC98KuTc0',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241204',
    video_source: 'DCtqssfJecY',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241204',
    video_source: 'DCpaQyRyPEU',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241204',
    video_source: 'DCm-bnsxQbh',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241204',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241204',
    video_source: 'DCr_otlR9IE',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241205',
    video_source: 'DDKgsyFxv3q',
    impressions: 57262,
    video_views: 117,
    cta_click: 12,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241205',
    video_source: 'DDIRcgASVUR',
    impressions: 43547,
    video_views: 328,
    cta_click: 43,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241205',
    video_source: 'DDJ_W_rJlEA',
    impressions: 40810,
    video_views: 215,
    cta_click: 15,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241205',
    video_source: 'DDLLXLtsoZ4',
    impressions: 31870,
    video_views: 181,
    cta_click: 23,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241205',
    video_source: 'DDIhgwzyKyY',
    impressions: 13663,
    video_views: 173,
    cta_click: 21,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241205',
    video_source: 'DDHTuNZx0ZD',
    impressions: 3051,
    video_views: 67,
    cta_click: 9,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241205',
    video_source: 'DC-R16POTVW',
    impressions: 15,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241205',
    video_source: 'DC4fJSkJRV-',
    impressions: 12,
    video_views: 2,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241205',
    video_source: 'DDCb4b7xsFM',
    impressions: 8,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241205',
    video_source: 'DCzvO-EokGM',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241205',
    video_source: 'DC2QiC2PA5d',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241205',
    video_source: 'DDAZB8_yODE',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241205',
    video_source: 'DC1mHB-OfUA',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241205',
    video_source: 'DCr_otlR9IE',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241205',
    video_source: 'DCpaQyRyPEU',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241205',
    video_source: 'DC9vkoKSB9T',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC9vkoKSB9T_optimized-TM8yXxEKXoY62vVilVU6GsyiCxfnD4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC9vkoKSB9T_preview-fp68aeslQMyrmj8w5dEtLcB5bwPuLj.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512172509628211027p-RmkUYhcoJSzQy87avMCNurs18iUTdq.jpg',
    caption:
      '💻El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241205',
    video_source: 'DCtqssfJecY',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241205',
    video_source: 'DC_uqr3JAGg',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241205',
    video_source: 'DCm-bnsxQbh',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCm-bnsxQbh_optimized-LNGaQSFHs4dp1CzYctjs6VyWxlB4G5.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCm-bnsxQbh_preview-UB7cH09cTcxyb26bZhJ1dhxvmyr7CB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3505763936891111137p-qlP5w9URz58Oq2PKmyPqmhluIRWXSu.jpg',
    caption:
      '🗣️ Claudia Fernández: "Soy una agradecida de la vida"  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241205',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20250116',
    video_source: 'DBgyQXyJ6wz',
    impressions: 100,
    video_views: 20,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgyQXyJ6wz_optimized-f4dTXSM7dyUWhabU5P26s1REqR5xeP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgyQXyJ6wz_preview-frFeIEZeIKQpILh8cxJy4tKutrbLHB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgyQXyJ6wz_cover_resized-RNWC0P8NBhPVkjWLq1kePp0O1EvvzO.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Hay varios casos judiciales que tienen los políticos como protagonistas y otros buscan sacar rédito electoral.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @robanatalia\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241205',
    video_source: 'DCXdLdpyCqo',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241205',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241205',
    video_source: 'DBd1QNMpg9F',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBd1QNMpg9F_optimized-nu13X40ZXpPTNLrzmsbKPMzANcpbXf.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBd1QNMpg9F_preview-gjphR1vCQFgiJBNTbq6qkHFpmNm7Fa.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBd1QNMpg9F_cover_resized-kgP9ocDuD3px9iaurYBZbBvmQOhxQr.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ En estas elecciones el Parlamento podría alcanzar una mayor diversidad de colectividades políticas.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @tomer.urwicz\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241206',
    video_source: 'DDKgsyFxv3q',
    impressions: 133428,
    video_views: 340,
    cta_click: 23,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241206',
    video_source: 'DDNxdVMSDUP',
    impressions: 70086,
    video_views: 219,
    cta_click: 19,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241206',
    video_source: 'DDLLXLtsoZ4',
    impressions: 65985,
    video_views: 302,
    cta_click: 25,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241206',
    video_source: 'DDNUz89yHLW',
    impressions: 26688,
    video_views: 232,
    cta_click: 28,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241206',
    video_source: 'DDJ_W_rJlEA',
    impressions: 14125,
    video_views: 73,
    cta_click: 7,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241206',
    video_source: 'DDIRcgASVUR',
    impressions: 7620,
    video_views: 102,
    cta_click: 6,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241206',
    video_source: 'DDHTuNZx0ZD',
    impressions: 20,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241206',
    video_source: 'DDIhgwzyKyY',
    impressions: 12,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241206',
    video_source: 'DC-R16POTVW',
    impressions: 10,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241206',
    video_source: 'DC4fJSkJRV-',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241206',
    video_source: 'DC2QiC2PA5d',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241206',
    video_source: 'DCfIugYs9iC',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241206',
    video_source: 'DC1mHB-OfUA',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241206',
    video_source: 'DDCb4b7xsFM',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241206',
    video_source: 'DChC98KuTc0',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241206',
    video_source: 'DCcOrjmSBsb',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCcOrjmSBsb_optimized-YBMwVOD1ZzOcGafILC2ejRP6gI4BFR.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCcOrjmSBsb_preview-E243OeatmlHlf24Ri7fTJqBwU1zvYC.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3502739175999281947p-z2p5yihvCgqcoaap0zgRPwOSnyfb8g.jpg',
    caption:
      '🗣️ Claudia Fernández: "Yo he sido una gran atrevida durante toda mi carrera, porque yo ya irrumpí en los medios en un momento que era otra época\\" 💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241206',
    video_source: 'DCZk64_J7RI',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241206',
    video_source: 'DCzvO-EokGM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241206',
    video_source: 'DC9vkoKSB9T',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC9vkoKSB9T_optimized-TM8yXxEKXoY62vVilVU6GsyiCxfnD4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC9vkoKSB9T_preview-fp68aeslQMyrmj8w5dEtLcB5bwPuLj.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512172509628211027p-RmkUYhcoJSzQy87avMCNurs18iUTdq.jpg',
    caption:
      '💻El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241206',
    video_source: 'DCXRuTJxx9-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241206',
    video_source: 'DCZF0CsxkPY',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241206',
    video_source: 'DCB0lxSJQZg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCB0lxSJQZg-OETkFDiOZDXvXaBrq4b5lnN2zf19su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCB0lxSJQZg_preview-RfORDwJap5gUNc7vubDB8qNzJppPF5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3495306079773197920p-lOsMwfoXCWAHVN2WiEvTVw5psCjLo0.jpg',
    caption:
      '🗣️ Lucas Sugo estuvo en Diálogos en Montevideo con Alejandro Fantino y contó sobre la vez que Luis Suárez y Lionel Messi fueron a un concierto de él. Leé la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay  #lucassugo',
  },
  {
    date: '20241206',
    video_source: 'DCEXXjnx5wE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCEXXjnx5wE_optimized-q4GCLUM2IjWG1JX8IBOYdkr04a9Cyc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCEXXjnx5wE_preview-w0OziKlXp1TOiEuCAgMawrAwQtFNLJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496021984612424708p-e9EC2CFWJ4cmMWbO63RF1PCQeLMeaX.jpg',
    caption:
      '👩‍💻 La inteligencia artificial al servicio nuestro tiene muchas cosas positivas. Estamos haciendo un montón de cosas mismo desde UKG que por ejemplo te ayudan a algo que es intrínsecamente humano y es el tener sesgos en contratar gente, por ejemplo un líder dentro de la empresa.\n\n💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241206',
    video_source: 'DDAZB8_yODE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241206',
    video_source: 'DCHKCTTJuEZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/3496807774302953753-3-ChM7LED5WUi6FzbX2zqawi3D5kmzwX.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/3496807774302953753-prev-K3Xo0pnV7fKVFmzAMT4FVHHtKl1SvP.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3496807774302953753p-Obqou6LxuMEC2p9MOduf1dwBBLCbWH.jpg',
    caption:
      '💬 Chiara Toscani es country manager de UKG (Ultimate Kronos Group) una empresa de tecnología global que ofrece soluciones de gestión del capital humano. Sobre su experiencia habló con Alejandro Fantino\n\nMirá la nota completa en 👉 www.elobservador.com.uy\n\n#inteligenciaartificial',
  },
  {
    date: '20241207',
    video_source: 'DDKgsyFxv3q',
    impressions: 95811,
    video_views: 342,
    cta_click: 35,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241207',
    video_source: 'DDNxdVMSDUP',
    impressions: 61868,
    video_views: 262,
    cta_click: 29,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241207',
    video_source: 'DDLLXLtsoZ4',
    impressions: 39375,
    video_views: 268,
    cta_click: 24,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241207',
    video_source: 'DDNUz89yHLW',
    impressions: 24912,
    video_views: 333,
    cta_click: 53,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241207',
    video_source: 'DDIRcgASVUR',
    impressions: 13,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241207',
    video_source: 'DDJ_W_rJlEA',
    impressions: 12,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241207',
    video_source: 'DC4fJSkJRV-',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241207',
    video_source: 'DC1mHB-OfUA',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241207',
    video_source: 'DDIhgwzyKyY',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241207',
    video_source: 'DDHTuNZx0ZD',
    impressions: 4,
    video_views: 3,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241207',
    video_source: 'DC2QiC2PA5d',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241207',
    video_source: 'DCXRuTJxx9-',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241207',
    video_source: 'DChC98KuTc0',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241207',
    video_source: 'DCZF0CsxkPY',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241207',
    video_source: 'DCfIugYs9iC',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241207',
    video_source: 'DC_uqr3JAGg',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241207',
    video_source: 'DCtqssfJecY',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241207',
    video_source: 'DCzvO-EokGM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241207',
    video_source: 'DC9vkoKSB9T',
    impressions: 1,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC9vkoKSB9T_optimized-TM8yXxEKXoY62vVilVU6GsyiCxfnD4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC9vkoKSB9T_preview-fp68aeslQMyrmj8w5dEtLcB5bwPuLj.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512172509628211027p-RmkUYhcoJSzQy87avMCNurs18iUTdq.jpg',
    caption:
      '💻El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241207',
    video_source: 'DCr_otlR9IE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241207',
    video_source: 'DC-R16POTVW',
    impressions: 1,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241207',
    video_source: 'DCpaQyRyPEU',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCpaQyRyPEU_optimized-ZwEJPNNf0grYBjyUTKyQ4x44tK5RAS.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCpaQyRyPEU_preview-Hcl6WyYGAMDXuY3jXCDAfrOevxmJ3H.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3506449287591031060p-ugpkIJUx1fhZgn0LT8P6z53NHjhCEh.jpg',
    caption:
      '📱 Usuarios de todo el mundo están abandonando X, la red social que antes conocíamos como Twitter. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #redessociales #internet #twitter',
  },
  {
    date: '20241207',
    video_source: 'DDAZB8_yODE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241207',
    video_source: 'DDCb4b7xsFM',
    impressions: 0,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241208',
    video_source: 'DDKgsyFxv3q',
    impressions: 89161,
    video_views: 278,
    cta_click: 30,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241208',
    video_source: 'DDNxdVMSDUP',
    impressions: 60698,
    video_views: 252,
    cta_click: 30,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241208',
    video_source: 'DDLLXLtsoZ4',
    impressions: 41063,
    video_views: 281,
    cta_click: 33,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241208',
    video_source: 'DDNUz89yHLW',
    impressions: 26479,
    video_views: 348,
    cta_click: 49,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241208',
    video_source: 'DDJ_W_rJlEA',
    impressions: 15,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241208',
    video_source: 'DDIRcgASVUR',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241208',
    video_source: 'DC-R16POTVW',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241208',
    video_source: 'DDHTuNZx0ZD',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241208',
    video_source: 'DDIhgwzyKyY',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241208',
    video_source: 'DC4fJSkJRV-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241208',
    video_source: 'DDCb4b7xsFM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241208',
    video_source: 'DDAZB8_yODE',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241208',
    video_source: 'DC2QiC2PA5d',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241208',
    video_source: 'DC9vkoKSB9T',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC9vkoKSB9T_optimized-TM8yXxEKXoY62vVilVU6GsyiCxfnD4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC9vkoKSB9T_preview-fp68aeslQMyrmj8w5dEtLcB5bwPuLj.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512172509628211027p-RmkUYhcoJSzQy87avMCNurs18iUTdq.jpg',
    caption:
      '💻El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241208',
    video_source: 'DC1mHB-OfUA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241208',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241208',
    video_source: 'xxxxxxxxxx',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/jumbo-diariolibre-4-l7Rzo1eXGnLqk0mYrEukNas3bGAWVd.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/jumbo-diariolibre-2-preview-ADt12HMuTT6HoxYeMIZR5WzTh1UdVq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/Captura%20de%20pantalla%202024-11-01%20a%20la(s)%2011.31.39%E2%80%AFa.%C2%A0m.-ept4TkpqjQFJtq6jCo3ICqwbC3TNKU.png',
    caption: '',
  },
  {
    date: '20241208',
    video_source: 'DC_uqr3JAGg',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241209',
    video_source: 'DDWsZEaxwRm',
    impressions: 97191,
    video_views: 442,
    cta_click: 76,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'DDQQ8QxuUz3',
    impressions: 56379,
    video_views: 192,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241209',
    video_source: 'DDUo8oNx8yr',
    impressions: 37741,
    video_views: 194,
    cta_click: 32,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'DDKgsyFxv3q',
    impressions: 26202,
    video_views: 65,
    cta_click: 4,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241209',
    video_source: 'DDNxdVMSDUP',
    impressions: 20670,
    video_views: 67,
    cta_click: 3,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241209',
    video_source: 'DDVEbePRZQj',
    impressions: 19882,
    video_views: 288,
    cta_click: 70,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDVEbePRZQj-1-pClRkoFTuToD2BOwklDoB4n8N9xC2U.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDVEbePRZQj-prev-DLDNqLmmKuwSLpOVaovsattFUrjVdR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDVEbePRZQj-oUI1oMHNDjGezCVyruLb9BHkETvabK.jpg',
    caption:
      '🥹 "Si subo a cantar se casa conmigo", decía un cartel. Valentina subió a cantar La Balada y el muchacho se arrodilló ahí mismo para pedirle matrimonio. "Decirte que te amo y preguntarte si te querés casar conmigo", dijo el hombre con un anillo en la mano mientras Emilia Mernes filmaba el momento con un celular. "Estoy un poco en shock con lo que acaba de pasar", diría después. Leé la crónica completa del show en 👉 www.elobservador.com.uy ✍️ @carlacolman  📹 @carlacolman  #emiliamernes #mp3tour #antelarena',
  },
  {
    date: '20241209',
    video_source: 'DDLLXLtsoZ4',
    impressions: 15463,
    video_views: 69,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241209',
    video_source: 'DDNUz89yHLW',
    impressions: 10001,
    video_views: 78,
    cta_click: 15,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241209',
    video_source: 'DDJ_W_rJlEA',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'DC-R16POTVW',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241209',
    video_source: 'DDIRcgASVUR',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241209',
    video_source: 'DC4fJSkJRV-',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241209',
    video_source: 'DC2QiC2PA5d',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241209',
    video_source: 'DC1mHB-OfUA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241209',
    video_source: 'DDHTuNZx0ZD',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'DCzvO-EokGM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241209',
    video_source: 'DChC98KuTc0',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'DCXRuTJxx9-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241209',
    video_source: 'DDIhgwzyKyY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241209',
    video_source: 'DC_uqr3JAGg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241209',
    video_source: 'DCr_otlR9IE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCr_otlR9IE_optimized-5l43QCjfphj3fV55u0wzs4ojbWVKGP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCr_otlR9IE_preview-pwYMU71YGtYLAPYJD3pZtlOtoiuLeU.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507176609491177988p-2Y22Odsv0SaDrA31gjGPIc3vXk1mio.jpg',
    caption:
      'Se viene el verano y contamos cuál es la cartelera de shows musicales para este verano 🌅🎶 🎙️@nicotabarez93  📽️ @_sil_fernandez',
  },
  {
    date: '20241209',
    video_source: 'DDAZB8_yODE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'DCtqssfJecY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241209',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'C_6gzdcy5gi',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/C_6gzdcy5gi_optimized-h1P3Ig2ObcBzUJZUw2wX8S3U9hjYxh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/C_6gzdcy5gi_preview-f7HAxIabEoFnmRZegS6umyX5wwqF9D.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/C_6gzdcy5gi_cover_resized-BLt0mqk1iqxXOAFiaPGsJBLYFLGoT3.jpg',
    caption:
      '📦 Drones delivery empiezan a despegar en Uruguay: proyectan más de 3 mil envíos de paquetes diarios en 2025.\n\nPodés ver la nota completa en 👉 www.elobservador.com.uy\n\n🎙️ @juanpablo.dm\n📹 @agusabelenda\n\n#drones',
  },
  {
    date: '20241209',
    video_source: 'DCZF0CsxkPY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241209',
    video_source: 'DCT3TRWJh8y',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCT3TRWJh8y_optimized-GEnOfFqzhSx8ztuPlLdNfnlgjLGWg8.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCT3TRWJh8y_preview-Eur9f55FlTISLBbEXrvsbRNIe1ZSaX.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3500384552250384178p-Wnl3sN0gXW3UOG21NJ2pALRMZjZtkY.jpg',
    caption:
      '💬 Carlos Lecueder habló sobre las sociedades anónimas en el fútbol. ➡️ El empresario recorrió su extensa trayectoria empresarial en una entrevista con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241209',
    video_source: 'DCWcslLxjUN',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCWcslLxjUN-TfBqPE7SvIOWk7n987hXGaVYsDN41p.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCWcslLxjUN_preview-4zP54no1lB8yD54Cd4G73nrSsJC34V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501111969212413197p-fIBp6aYcyAa5GAvYHhMeBl7doN9KH2.jpg',
    caption:
      '➡️ Se fue de su casa a los 17 años para vivir en una pensión y la relación con su padre la marco duramente. En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241210',
    video_source: 'DDW4ajwPOU3',
    impressions: 113400,
    video_views: 314,
    cta_click: 33,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241210',
    video_source: 'DDWsZEaxwRm',
    impressions: 97923,
    video_views: 394,
    cta_click: 66,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241210',
    video_source: 'DDU6CyINeMB',
    impressions: 43507,
    video_views: 377,
    cta_click: 87,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241210',
    video_source: 'DDUo8oNx8yr',
    impressions: 40337,
    video_views: 314,
    cta_click: 52,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241210',
    video_source: 'DDQQ8QxuUz3',
    impressions: 24862,
    video_views: 48,
    cta_click: 4,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241210',
    video_source: 'DDVEbePRZQj',
    impressions: 9555,
    video_views: 113,
    cta_click: 25,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDVEbePRZQj-1-pClRkoFTuToD2BOwklDoB4n8N9xC2U.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDVEbePRZQj-prev-DLDNqLmmKuwSLpOVaovsattFUrjVdR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDVEbePRZQj-oUI1oMHNDjGezCVyruLb9BHkETvabK.jpg',
    caption:
      '🥹 "Si subo a cantar se casa conmigo", decía un cartel. Valentina subió a cantar La Balada y el muchacho se arrodilló ahí mismo para pedirle matrimonio. "Decirte que te amo y preguntarte si te querés casar conmigo", dijo el hombre con un anillo en la mano mientras Emilia Mernes filmaba el momento con un celular. "Estoy un poco en shock con lo que acaba de pasar", diría después. Leé la crónica completa del show en 👉 www.elobservador.com.uy ✍️ @carlacolman  📹 @carlacolman  #emiliamernes #mp3tour #antelarena',
  },
  {
    date: '20241210',
    video_source: 'DDKgsyFxv3q',
    impressions: 49,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241210',
    video_source: 'DDNxdVMSDUP',
    impressions: 28,
    video_views: 2,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241210',
    video_source: 'DDLLXLtsoZ4',
    impressions: 20,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241210',
    video_source: 'DDNUz89yHLW',
    impressions: 13,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241210',
    video_source: 'DDJ_W_rJlEA',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241210',
    video_source: 'DC2QiC2PA5d',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241210',
    video_source: 'DDIRcgASVUR',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241210',
    video_source: 'DChC98KuTc0',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241210',
    video_source: 'DC-R16POTVW',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241210',
    video_source: 'DBLtZ3_p7iW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBLtZ3_p7iW_optimized-TO6uS0moZlDvKGR2ieYgE14de8t9Qz.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBLtZ3_p7iW_preview-08Obq5tDchLtq0gqcDPRoAjmuOsNqB.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBLtZ3_p7iW_cover_resized-xJncc4FBDeo3o55J8tSpNzmM6JWdXq.jpg',
    caption:
      '"🗣️ Diego Godín habló sobre Luis Suárez.\\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay"',
  },
  {
    date: '20241210',
    video_source: 'DCzvO-EokGM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241210',
    video_source: 'DCZF0CsxkPY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241210',
    video_source: 'DCXRuTJxx9-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241210',
    video_source: 'DDIhgwzyKyY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241210',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241210',
    video_source: 'DC4fJSkJRV-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241210',
    video_source: 'DDHTuNZx0ZD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241210',
    video_source: 'DC1mHB-OfUA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241211',
    video_source: 'DDaT4UnMI3f',
    impressions: 86010,
    video_views: 536,
    cta_click: 62,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241211',
    video_source: 'DDW4ajwPOU3',
    impressions: 60838,
    video_views: 180,
    cta_click: 16,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241211',
    video_source: 'DDcaLFIprsN',
    impressions: 44824,
    video_views: 291,
    cta_click: 25,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DDWsZEaxwRm',
    impressions: 37637,
    video_views: 138,
    cta_click: 12,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DDU6CyINeMB',
    impressions: 29777,
    video_views: 226,
    cta_click: 35,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241211',
    video_source: 'DDZq_pAPIgZ',
    impressions: 29034,
    video_views: 286,
    cta_click: 42,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241211',
    video_source: 'DDUo8oNx8yr',
    impressions: 17263,
    video_views: 121,
    cta_click: 21,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DDZs3iRR0ur',
    impressions: 15543,
    video_views: 277,
    cta_click: 55,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DDKgsyFxv3q',
    impressions: 20,
    video_views: 0,
    cta_click: 1,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241211',
    video_source: 'DDQQ8QxuUz3',
    impressions: 18,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241211',
    video_source: 'DDVEbePRZQj',
    impressions: 12,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDVEbePRZQj-1-pClRkoFTuToD2BOwklDoB4n8N9xC2U.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDVEbePRZQj-prev-DLDNqLmmKuwSLpOVaovsattFUrjVdR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDVEbePRZQj-oUI1oMHNDjGezCVyruLb9BHkETvabK.jpg',
    caption:
      '🥹 "Si subo a cantar se casa conmigo", decía un cartel. Valentina subió a cantar La Balada y el muchacho se arrodilló ahí mismo para pedirle matrimonio. "Decirte que te amo y preguntarte si te querés casar conmigo", dijo el hombre con un anillo en la mano mientras Emilia Mernes filmaba el momento con un celular. "Estoy un poco en shock con lo que acaba de pasar", diría después. Leé la crónica completa del show en 👉 www.elobservador.com.uy ✍️ @carlacolman  📹 @carlacolman  #emiliamernes #mp3tour #antelarena',
  },
  {
    date: '20241211',
    video_source: 'DDLLXLtsoZ4',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241211',
    video_source: 'DDNUz89yHLW',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241211',
    video_source: 'DDJ_W_rJlEA',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DDIRcgASVUR',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241211',
    video_source: 'DC2QiC2PA5d',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241211',
    video_source: 'DDNxdVMSDUP',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241211',
    video_source: 'DC4fJSkJRV-',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241211',
    video_source: 'DC-R16POTVW',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241211',
    video_source: 'DChC98KuTc0',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DCzvO-EokGM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241211',
    video_source: 'DDIhgwzyKyY',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241211',
    video_source: 'DDCb4b7xsFM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DDHTuNZx0ZD',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DCXRuTJxx9-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241211',
    video_source: 'DC1mHB-OfUA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241211',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DCZF0CsxkPY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241211',
    video_source: 'DC_uqr3JAGg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241211',
    video_source: 'DDAZB8_yODE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241211',
    video_source: 'DCXdLdpyCqo',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241212',
    video_source: 'DDcmFxCJey6',
    impressions: 107900,
    video_views: 54,
    cta_click: 61,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241212',
    video_source: 'DDcaLFIprsN',
    impressions: 80465,
    video_views: 136,
    cta_click: 33,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241212',
    video_source: 'DDaT4UnMI3f',
    impressions: 75075,
    video_views: 200,
    cta_click: 34,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241212',
    video_source: 'DDZs3iRR0ur',
    impressions: 36838,
    video_views: 118,
    cta_click: 81,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241212',
    video_source: 'DDZq_pAPIgZ',
    impressions: 14795,
    video_views: 130,
    cta_click: 12,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241212',
    video_source: 'DDW4ajwPOU3',
    impressions: 36,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241212',
    video_source: 'DDWsZEaxwRm',
    impressions: 21,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241212',
    video_source: 'DDU6CyINeMB',
    impressions: 11,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241212',
    video_source: 'DDUo8oNx8yr',
    impressions: 11,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241212',
    video_source: 'DDKgsyFxv3q',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241212',
    video_source: 'DDQQ8QxuUz3',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241212',
    video_source: 'DC2QiC2PA5d',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241212',
    video_source: 'DC4fJSkJRV-',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241212',
    video_source: 'DDVEbePRZQj',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDVEbePRZQj-1-pClRkoFTuToD2BOwklDoB4n8N9xC2U.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDVEbePRZQj-prev-DLDNqLmmKuwSLpOVaovsattFUrjVdR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDVEbePRZQj-oUI1oMHNDjGezCVyruLb9BHkETvabK.jpg',
    caption:
      '🥹 "Si subo a cantar se casa conmigo", decía un cartel. Valentina subió a cantar La Balada y el muchacho se arrodilló ahí mismo para pedirle matrimonio. "Decirte que te amo y preguntarte si te querés casar conmigo", dijo el hombre con un anillo en la mano mientras Emilia Mernes filmaba el momento con un celular. "Estoy un poco en shock con lo que acaba de pasar", diría después. Leé la crónica completa del show en 👉 www.elobservador.com.uy ✍️ @carlacolman  📹 @carlacolman  #emiliamernes #mp3tour #antelarena',
  },
  {
    date: '20241212',
    video_source: 'DDLLXLtsoZ4',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241212',
    video_source: 'DDNxdVMSDUP',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241212',
    video_source: 'DBgZj5xJdDs',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBgZj5xJdDs_optimized-iVU6tuCwi2KaxYqhBajoK6yaKUuXQh.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBgZj5xJdDs_preview-JK0RqIybJXRBNYxPPLqK4PqxcehwfS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBgZj5xJdDs_cover_resized-hW9mlm2fGXN97PC99ehGSaZ2xQ97Wx.jpg',
    caption:
      '🗳️ ¿Por qué las elecciones de este domingo son históricas?\n\n➡️ Actualmente la pobreza es de más de un 9% de la población. El gran desafío del gobierno que viene es revertir ese número.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @dominguezmarianoel\n\n#elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241212',
    video_source: 'DDIRcgASVUR',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 109041,
    video_views: 50,
    cta_click: 47,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241213',
    video_source: 'DDcaLFIprsN',
    impressions: 75538,
    video_views: 1,
    cta_click: 46,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DDZq_pAPIgZ',
    impressions: 43653,
    video_views: 1,
    cta_click: 20,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241213',
    video_source: 'DDZs3iRR0ur',
    impressions: 38360,
    video_views: 1,
    cta_click: 82,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DDcmFxCJey6',
    impressions: 30941,
    video_views: 0,
    cta_click: 19,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241213',
    video_source: 'DDaT4UnMI3f',
    impressions: 12787,
    video_views: 1,
    cta_click: 5,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241213',
    video_source: 'DDW4ajwPOU3',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241213',
    video_source: 'DDKgsyFxv3q',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241213',
    video_source: 'DDWsZEaxwRm',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DDLLXLtsoZ4',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241213',
    video_source: 'DDNxdVMSDUP',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241213',
    video_source: 'DDU6CyINeMB',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241213',
    video_source: 'DBJFbMBxOVg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBJFbMBxOVg_optimized-EoJyFx9Ky2Hw9FbH20qBLMNagf8RMQ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBJFbMBxOVg_preview-JxVfHI43W36ypohWumaGL6ljwVGqLL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBJFbMBxOVg_cover_resized-n6cwCl7tQURN6NfB1ppbcBjP8si4YO.jpg',
    caption:
      '🗣️ Diego Godín: "Sentí miedo, pero no era el miedo que me paralizaba" \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro.\\n\\nMirá la nota completa en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DDHTuNZx0ZD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DC-R16POTVW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241213',
    video_source: 'DDAZB8_yODE',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DDUo8oNx8yr',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DDCb4b7xsFM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DBEGDrcxmL6',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBEGDrcxmL6_optimized-fQywJZn67Ou07hkgHWFgMQw7PhUXeV.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBEGDrcxmL6_preview-OYDyzBCnuJwY62RUGRno4kR9kkGrzH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBEGDrcxmL6_cover_resized-yOITnha49tpbxQBMPRIuFOPq6nbGlZ.jpg',
    caption:
      '⚽️ Diego Godín: "El Maestro para nosotros fue mucho más que un director técnico". \\n\\n💬 En Diálogos en Montevideo con Alejandro Fantino, Diego Godín comparte el recorrido desde sus inicios en Rosario (Colonia), hasta su vida como deportista de élite en Europa. Reflexiona sobre el sacrificio personal, el amor por el país y el futuro. \\n\\nPodrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy\\n\\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DC_uqr3JAGg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241213',
    video_source: 'DBGo5khuuuP',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBGo5khuuuP_optimized-ZJIoxHEvFmrxXFGAL7rdjEQROnDYqj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBGo5khuuuP_preview-0IeFNVdDWf23mrOn4OdwxrOuDFl8hq.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBGo5khuuuP_cover_resized-z1vCD9n9RecqpmQwuZhVatcFXjBIoN.jpg',
    caption:
      '🚀 Space X logró recapturar en vuelo el propulsor de su cohete Starship y se convierte en un hito de la ingeniería espacial. Esto permitiría que a futuro este tipo de lanzadores puedan ser reutilizadas.\\n\\nEl Starship, un cohete espacial de 121 metros de altura, despegó del Golfo de México y, siete minutos después de su despegue, su propulsor de 71 metros fue atrapado por los brazos metálicos de la torre de despegue. Este fue el primer intento por parte de Elon Musk de atrapar un propulsor en vuelo y resultó efectivo. \\n\\nConocé más detalles en 👉 www.elobservador.com.uy\\n\\n📹 @elonmusk',
  },
  {
    date: '20241213',
    video_source: 'DDIhgwzyKyY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241213',
    video_source: 'DBBnU2vuRjw',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBBnU2vuRjw_optimized-3-kfOBJgnI8MOVnKjxKBHB1vFiZBcLGW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBBnU2vuRjw_preview-nLm6lIhAaop4oIRGYY1gfab9vZbfQ9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBBnU2vuRjw_cover_resized-Gp1GhVI1tX6DGQAf6HA8gK6x49ho2p.jpg',
    caption:
      '🗳️🇺🇾 ¿Cómo funciona el sistema de jubilaciones en Uruguay? ¿Cuáles son las diferencias entre régimen mixto, sistema de reparto y sistema de ahorro individual? \\n\\n🎙️ @piamestell te lo explica. \\n\\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241213',
    video_source: 'DDIRcgASVUR',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241213',
    video_source: 'DDJ_W_rJlEA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241213',
    video_source: 'DCzvO-EokGM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 100024,
    video_views: 15,
    cta_click: 47,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241214',
    video_source: 'DDcaLFIprsN',
    impressions: 55272,
    video_views: 0,
    cta_click: 40,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241214',
    video_source: 'DDZq_pAPIgZ',
    impressions: 41043,
    video_views: 0,
    cta_click: 43,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241214',
    video_source: 'DDZs3iRR0ur',
    impressions: 27929,
    video_views: 0,
    cta_click: 79,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241214',
    video_source: 'DDcmFxCJey6',
    impressions: 32,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241214',
    video_source: 'DDaT4UnMI3f',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241214',
    video_source: 'DDW4ajwPOU3',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241214',
    video_source: 'DDWsZEaxwRm',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241214',
    video_source: 'DDKgsyFxv3q',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241214',
    video_source: 'DDNxdVMSDUP',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241214',
    video_source: 'DDLLXLtsoZ4',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241214',
    video_source: 'DDU6CyINeMB',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241214',
    video_source: 'DDUo8oNx8yr',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241214',
    video_source: 'DDNUz89yHLW',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241214',
    video_source: 'DC4fJSkJRV-',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241214',
    video_source: 'DDJ_W_rJlEA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241214',
    video_source: 'DDIRcgASVUR',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241214',
    video_source: 'DC-R16POTVW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241214',
    video_source: 'DDVEbePRZQj',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDVEbePRZQj-1-pClRkoFTuToD2BOwklDoB4n8N9xC2U.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDVEbePRZQj-prev-DLDNqLmmKuwSLpOVaovsattFUrjVdR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDVEbePRZQj-oUI1oMHNDjGezCVyruLb9BHkETvabK.jpg',
    caption:
      '🥹 "Si subo a cantar se casa conmigo", decía un cartel. Valentina subió a cantar La Balada y el muchacho se arrodilló ahí mismo para pedirle matrimonio. "Decirte que te amo y preguntarte si te querés casar conmigo", dijo el hombre con un anillo en la mano mientras Emilia Mernes filmaba el momento con un celular. "Estoy un poco en shock con lo que acaba de pasar", diría después. Leé la crónica completa del show en 👉 www.elobservador.com.uy ✍️ @carlacolman  📹 @carlacolman  #emiliamernes #mp3tour #antelarena',
  },
  {
    date: '20241214',
    video_source: 'C-pu6afOKyB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/C-pu6afOKyB_optimized-3-6EZKlOL5GBYmIdJVAGXeagYxGcBuL2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/C-pu6afOKyB_preview-YsrRyZzD04b9rwnztkDobCI4LIyd91.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/C-pu6afOKyB_cover_resized-24qfhvlGZ0XN1i81u00Y7QuE8ARD8z.jpg',
    caption:
      '➡️ Roberto Balaguer es un pionero en el estudio de la intersección entre psicología y tecnología.\n\n💬 Dice que ya es técnicamente posible crear agentes de inteligencia artificial que simulen personalidades humanas, lo que podría revolucionar campos como la psicología.\n\nLeé la entrevista completa en 👉 www.elobservador.com.uy\n\n✍️ @dominguezmarianoel\n🎙️ @robertobalaguer\n🏠 @lacasavioleta\n\n#tecnología #inteligenciaartificial',
  },
  {
    date: '20241214',
    video_source: 'DCzvO-EokGM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241214',
    video_source: 'DDHTuNZx0ZD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241214',
    video_source: 'DDAZB8_yODE',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDAZB8_yODE_optimized-KbK9xlyE2xP9t3OqAMMeksFTwe2Yue.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDAZB8_yODE_preview-557ScyCCDeWvkECwOXijvpD8J8e2L5.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512917794725880004p-u2cGrefimeEjmyRS45JQVrRYPj88OW.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 131125,
    video_views: 20000,
    cta_click: 69,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241215',
    video_source: 'DDcaLFIprsN',
    impressions: 77079,
    video_views: 0,
    cta_click: 40,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241215',
    video_source: 'DDZq_pAPIgZ',
    impressions: 57701,
    video_views: 0,
    cta_click: 44,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241215',
    video_source: 'DDZs3iRR0ur',
    impressions: 39957,
    video_views: 0,
    cta_click: 101,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241215',
    video_source: 'DDaT4UnMI3f',
    impressions: 12,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241215',
    video_source: 'DDcmFxCJey6',
    impressions: 9,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241215',
    video_source: 'DDWsZEaxwRm',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241215',
    video_source: 'DDW4ajwPOU3',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241215',
    video_source: 'DDCb4b7xsFM',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241215',
    video_source: 'DC-R16POTVW',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241215',
    video_source: 'DDQQ8QxuUz3',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241215',
    video_source: 'DCfIugYs9iC',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241215',
    video_source: 'DDKgsyFxv3q',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241215',
    video_source: 'DC_uqr3JAGg',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC_uqr3JAGg_optimized-Mk5fOVy86hvBKzzAgM7GYorecXa0g0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC_uqr3JAGg_preview-4uyx7em9GDn7M79slMS9N4bOCK3iGT.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512731477825814944p-vol2CB9Z7LAki3l9Cydq9yB9TJ6xrL.jpg',
    caption:
      '💻 El acoso online ahora es más castigado en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @juampa.demarco  #ciberacoso #tecnología',
  },
  {
    date: '20241215',
    video_source: 'DDU6CyINeMB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241215',
    video_source: 'DCZk64_J7RI',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZk64_J7RI-4VtNc3ZqlwDU6XV0TM8h6coeIMAqzg.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZk64_J7RI_preview-qiWG359q9zSwzp7R7xvarIEYVyllUL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501992561850430536p-o4EHPzv3njnMh6u7uqCyaThdEvdI47.jpg',
    caption:
      '🗳️ ¿Quién será el futuro presidente de Uruguay según los uruguayos? Conocé más detalles en 👉 www.elobservador.com.uy ✍️ @tomer.urwicz  #elecciones #uruguay',
  },
  {
    date: '20241215',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241215',
    video_source: 'DCXRuTJxx9-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241215',
    video_source: 'DDUo8oNx8yr',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241215',
    video_source: 'DCZF0CsxkPY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241215',
    video_source: 'DDNxdVMSDUP',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 101434,
    video_views: 40,
    cta_click: 37,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 84479,
    video_views: 10000,
    cta_click: 32,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241216',
    video_source: 'DDiLgAeNyYo',
    impressions: 55595,
    video_views: 0,
    cta_click: 21,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241216',
    video_source: 'DDcaLFIprsN',
    impressions: 31617,
    video_views: 0,
    cta_click: 15,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241216',
    video_source: 'DDjup8-J7VX',
    impressions: 27262,
    video_views: 0,
    cta_click: 37,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241216',
    video_source: 'DDZq_pAPIgZ',
    impressions: 26241,
    video_views: 0,
    cta_click: 18,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241216',
    video_source: 'DDZs3iRR0ur',
    impressions: 18726,
    video_views: 0,
    cta_click: 48,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241216',
    video_source: 'DDaT4UnMI3f',
    impressions: 13,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241216',
    video_source: 'DDcmFxCJey6',
    impressions: 10,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241216',
    video_source: 'DDNxdVMSDUP',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241216',
    video_source: 'DDKgsyFxv3q',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241216',
    video_source: 'DC-R16POTVW',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241216',
    video_source: 'DC4fJSkJRV-',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241216',
    video_source: 'DDW4ajwPOU3',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241216',
    video_source: 'DC2QiC2PA5d',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241216',
    video_source: 'DCXdLdpyCqo',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241216',
    video_source: 'DDWsZEaxwRm',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241216',
    video_source: 'DBcQ2K0suss',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241216',
    video_source: 'DDU6CyINeMB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241216',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241216',
    video_source: 'DDLLXLtsoZ4',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241216',
    video_source: 'DDUo8oNx8yr',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241216',
    video_source: 'DCAWCYUyhDn',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAWCYUyhDn_optimized-v4ehcGAp112hkBgOoyZq2Mrc9XZIYC.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCAWCYUyhDn_preview-3M8pOg7MIOjiniZve70h8dj3uzRL9W.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3494890231420293351p-B658d6aPSMtLakoiLujjiK4J21ZlLd.jpg',
    caption:
      '➡️ A sus 45 años y luego de una destacada trayectoria como futbolista, con un título de campeón de América con la selección uruguaya en Argentina 2011, más el Balón de Oro en el Mundial de Sudáfrica 2010 y distinciones individuales como las dos Botas de Oro que ganó en la elite europea, Diego Forlán vuelve a ser profesional, pero como tenista, el otro deporte de su vida y al que se ha dedicado en los últimos años a nivel amateur. 🎾 El exgoleador será la gran atracción del Uruguay Open 2024, el principal torneo de tenis uruguayo que forma parte del Challenger Tour de la ATP y que se jugará en el Carrasco Lawn Tennis, el club donde creció jugando a ambas disciplinas, desde el 10 al 17 de noviembre. Conocé más detalles en 👉 www.elobservador.com.uy 📹 @sebaamaya77  #diegoforlán #tenis #uruguayopen',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 131729,
    video_views: 41,
    cta_click: 41,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241217',
    video_source: 'DDiLgAeNyYo',
    impressions: 83302,
    video_views: 0,
    cta_click: 24,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 58000,
    video_views: 30000,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241217',
    video_source: 'DDjup8-J7VX',
    impressions: 44257,
    video_views: 0,
    cta_click: 41,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241217',
    video_source: 'DDcaLFIprsN',
    impressions: 40,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241217',
    video_source: 'DDZq_pAPIgZ',
    impressions: 27,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241217',
    video_source: 'DDZs3iRR0ur',
    impressions: 21,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241217',
    video_source: 'DDaT4UnMI3f',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241217',
    video_source: 'DDcmFxCJey6',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241217',
    video_source: 'DDKgsyFxv3q',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241217',
    video_source: 'DDNxdVMSDUP',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241217',
    video_source: 'DDW4ajwPOU3',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241217',
    video_source: 'DDLLXLtsoZ4',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241217',
    video_source: 'DDU6CyINeMB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241217',
    video_source: 'DDIRcgASVUR',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241217',
    video_source: 'DDHTuNZx0ZD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241217',
    video_source: 'DDWsZEaxwRm',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241217',
    video_source: 'DDNUz89yHLW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNUz89yHLW-video-2-rszxlrawe8Mtvd9mLX2DXfFTIBAusw.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNUz89yHLW-video-prev-9e6vsxFoEdpigUFMK2MlisO11BVYH3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNUz89yHLW-poster-zzmq0ZYoTqf2Ie8KdxD6ZsUCanFJiJ.jpg',
    caption:
      '💵 Así marcha la exportación en los tres grandes motores del agro. ➡️ Considerando los bienes de exportación que más ingresos generan en Uruguay, el podio que lidera celulosa y complementan carne vacuna y soja tiene nuevos datos. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #agronegocios #exportación',
  },
  {
    date: '20241217',
    video_source: 'DDJ_W_rJlEA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241217',
    video_source: 'DC4fJSkJRV-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241217',
    video_source: 'DC2QiC2PA5d',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241217',
    video_source: 'DBw3S0aRIhB',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBw3S0aRIhB_optimized-LBG1t88OMjkDOhKrrVvDCIiWkLV1Ja.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBw3S0aRIhB_preview-pe18ZYJfUFEQqpPfzuaDIiI6BX1om9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/posters/1-DBw3S0aRIhB-k5jt1W91vNkjpU9GM3v7nHr1F2MdjK.jpg',
    caption:
      '🗳️ Pasaron las elecciones y nos queda un mes más intenso y competitivo de lo que preveíamos. Las encuestas subestimaron el voto al Partido Nacional, sobrestimaron apenas al Frente Amplio, y el resultado generó un balotaje muy parejo. El FA fue la fuerza más votada pero la coalición sumada votó mejor por lo que Álvaro Delgado tendrá el desafío de retener los votos mientras que Yamandú Orsi saldrá en busca de los votantes de partidos chicos y alguno que pueda arañar en el oficialismo (valen doble). Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 110413,
    video_views: 50,
    cta_click: 29,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241218',
    video_source: 'DDiLgAeNyYo',
    impressions: 77892,
    video_views: 0,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 57202,
    video_views: 40,
    cta_click: 17,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241218',
    video_source: 'DDjup8-J7VX',
    impressions: 45651,
    video_views: 0,
    cta_click: 37,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241218',
    video_source: 'DDcaLFIprsN',
    impressions: 18,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241218',
    video_source: 'DDZq_pAPIgZ',
    impressions: 13,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241218',
    video_source: 'DDZs3iRR0ur',
    impressions: 11,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241218',
    video_source: 'DDKgsyFxv3q',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241218',
    video_source: 'DDaT4UnMI3f',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241218',
    video_source: 'DChC98KuTc0',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241218',
    video_source: 'DDcmFxCJey6',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241218',
    video_source: 'DDNxdVMSDUP',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241218',
    video_source: 'DDWsZEaxwRm',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241218',
    video_source: 'DDUo8oNx8yr',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241218',
    video_source: 'DCXRuTJxx9-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241218',
    video_source: 'DDQQ8QxuUz3',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241218',
    video_source: 'DC4fJSkJRV-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241219',
    video_source: 'DDu2WsmRLo5',
    impressions: 113854,
    video_views: 0,
    cta_click: 77,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241219',
    video_source: 'DDsLvikyHUL',
    impressions: 59683,
    video_views: 0,
    cta_click: 31,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241219',
    video_source: 'DDsbem4S156',
    impressions: 42397,
    video_views: 0,
    cta_click: 28,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 39921,
    video_views: 30,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241219',
    video_source: 'DDr7n8txCsm',
    impressions: 28497,
    video_views: 0,
    cta_click: 50,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20241219',
    video_source: 'DDiLgAeNyYo',
    impressions: 26845,
    video_views: 0,
    cta_click: 7,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 18951,
    video_views: 60,
    cta_click: 7,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241219',
    video_source: 'DDjup8-J7VX',
    impressions: 15617,
    video_views: 0,
    cta_click: 18,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241219',
    video_source: 'DDcaLFIprsN',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241219',
    video_source: 'DDKgsyFxv3q',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241219',
    video_source: 'DDZq_pAPIgZ',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241219',
    video_source: 'DCXRuTJxx9-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241219',
    video_source: 'DDNxdVMSDUP',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241219',
    video_source: 'DDcmFxCJey6',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241219',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241219',
    video_source: 'DDQQ8QxuUz3',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241219',
    video_source: 'DCZF0CsxkPY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241219',
    video_source: 'DDWsZEaxwRm',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241219',
    video_source: 'DDZs3iRR0ur',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241219',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241219',
    video_source: 'DDW4ajwPOU3',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241219',
    video_source: 'DC4fJSkJRV-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241220',
    video_source: 'DDu2WsmRLo5',
    impressions: 118978,
    video_views: 0,
    cta_click: 84,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241220',
    video_source: 'DDsLvikyHUL',
    impressions: 74344,
    video_views: 0,
    cta_click: 44,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241220',
    video_source: 'DDsbem4S156',
    impressions: 55332,
    video_views: 0,
    cta_click: 35,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241220',
    video_source: 'DDr7n8txCsm',
    impressions: 38870,
    video_views: 0,
    cta_click: 62,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 43,
    video_views: 10,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241220',
    video_source: 'DDiLgAeNyYo',
    impressions: 32,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 27,
    video_views: 1,
    cta_click: 1,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241220',
    video_source: 'DDjup8-J7VX',
    impressions: 16,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241220',
    video_source: 'DDcaLFIprsN',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DDZs3iRR0ur',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DDaT4UnMI3f',
    impressions: 4,
    video_views: 0,
    cta_click: 1,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDaT4UnMI3f-video-gFiYG9NIClakzXJzDzekwayozqyRH1.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDaT4UnMI3f-prev-5cXN0Ne7prBqAoVkAScXEePutIDOFs.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDaT4UnMI3f-PQrJTdSIvMw64TDjyUIajtmFnnNPKi.jpg',
    caption:
      '🗳🇺🇾 Yamandú Orsi no asumió, pero ya tiene el primer problema grande para resolver: Arazatí.  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla  y @diegocayota  sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024 Léela todos los miércoles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241220',
    video_source: 'DDcmFxCJey6',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241220',
    video_source: 'DDZq_pAPIgZ',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241220',
    video_source: 'DDIRcgASVUR',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241220',
    video_source: 'DDIhgwzyKyY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIhgwzyKyY_optimized-2-QlIJAu9CVhXBJNvMxKa6hN8ychy8mP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIhgwzyKyY_preview-bVI6oTW2kJS5uEN4eakXcWTgdM6pMO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515206896129191064p-kV7LHYbe2dKusq56vo8SGwOhsgYykm.jpg',
    caption:
      '🎬 El presidente electo, Yamandú Orsi, estuvo en la presentación de lo más destacado de los Festivales de Málaga y San Sebastián en Montevideo y habló sobre la industria audiovisual. Conocé más detalles en 👉 www.elobservador.com.uy #yamandúorsi #festivaldemálaga #festivalsansebastián',
  },
  {
    date: '20241220',
    video_source: 'DDUo8oNx8yr',
    impressions: 1,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDUo8oNx8yr-1-bSvIFm6J1sVcrTj3nXAbBQS3BYa2gq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDUo8oNx8yr-prev-YKwoj0j4jiHxd52u8V0uCii42739Oc.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDUo8oNx8yr-oVpxTklx1Ef6sfOrB0xbWB4fbaL9ZI.jpg',
    caption:
      '🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Matías Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DCtqssfJecY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241220',
    video_source: 'DCfIugYs9iC',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCfIugYs9iC_optimized-12XR0w8XhL2eArk8swqp3IO1Nzxm7S.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCfIugYs9iC_preview-gee8CfpexdWtrIdrBgu738Xrj8tdxH.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3503557415359731842p-pG10XIm56Z3NO31OcZTUWEJsDAbdQj.jpg',
    caption:
      '💬 En el marco del ciclo de Diálogos en Montevideo, Alejandro Fantino entrevistó a Fernández y con ella repasó su carrera, la relación que ha tenido con su cuerpo a lo largo de los años y lo que piensa ahora de algunos hechos polémicos que la tuvieron como centro. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DCXRuTJxx9-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241220',
    video_source: 'DDCb4b7xsFM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DDJ_W_rJlEA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DDW4ajwPOU3',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241220',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DDQQ8QxuUz3',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDQQ8QxuUz3-1-9Ma5uhAVXuTrTyxAsg3bSKLu5bIeAr.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDQQ8QxuUz3-prev-wHHJvux9aXZH6pK8F6vew1A0gVSoA2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDQQ8QxuUz3-49tpQSaT93w4rXiiAjDMoVjrlHREcw.jpg',
    caption:
      '🎶🌎 Spotify lanzó el "Wrapped" de 2024: ¿quiénes fueron los artistas más escuchados en el mundo?  ➡️ Si bien la lista dominada por estadounidenses y canadienses, también hay representación latina con tres artistas que cantan en español en el top 10.  🎙️ @nicotabarez93  📹 @agusabelenda  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241220',
    video_source: 'DDHTuNZx0ZD',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDHTuNZx0ZD_optimized-J37SsJ5ovnM1NYhCVMGiBDmPNHNSCm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDHTuNZx0ZD_preview-FbgLnisj9vzK5O0AoP2B6fOW5h2C3z.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3514864772556736067p-QVPlrzFVwB417MPOTF9NDj4m4iivTp.jpg',
    caption:
      '🗣️ Pamela Martínez: "Lo que nosotros discutimos en OMBIJAM es que una vez que la persona está dentro de la cárcel, reciba herramientas para que pueda transformar su vida y salir mejor de lo que entró".  La madre y la abuela de Pamela Martínez nacieron en un asentamiento y su padre era basurero. Luego de salir del cantegril, la vida llevó a Martínez por derroteros que la condujeron a una playa del Caribe donde vivía en un hotel ecológico. Pero volvió para dedicarse a darle una nueva oportunidad a los presos a través del programa Ombijam. De su peripecia vital y la de los presos a los que ayudó, habló con Alejandro Fantino. Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DDNxdVMSDUP',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241220',
    video_source: 'DDU6CyINeMB',
    impressions: 1,
    video_views: 1,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDU6CyINeMB-1-CYJ2hpShybgOnOrl6nm2ZGQpBiT2bv.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDU6CyINeMB-prev-TnbEYfxva3dutGV7yeZi22nmOOf51y.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDU6CyINeMB-3l39yQyqPoSYpgrsSCtaPo0Vi3PeuH.jpg',
    caption:
      '💒La catedral de Notre Dame reabrió este sábado sus puertas para acoger a decenas de líderes procedentes de todo el mundo en el marco de una acto ceremonial presidido por el arzobispo de París, Laurent Ulrich, cinco años después de que se produjera el trágico incendio.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹: CNN',
  },
  {
    date: '20241220',
    video_source: 'DDWsZEaxwRm',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241220',
    video_source: 'DC-R16POTVW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241220',
    video_source: 'DDKgsyFxv3q',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241220',
    video_source: 'DCZF0CsxkPY',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241221',
    video_source: 'DDu2WsmRLo5',
    impressions: 85147,
    video_views: 0,
    cta_click: 49,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241221',
    video_source: 'DDsLvikyHUL',
    impressions: 52498,
    video_views: 0,
    cta_click: 26,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241221',
    video_source: 'DDsbem4S156',
    impressions: 37463,
    video_views: 0,
    cta_click: 31,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241221',
    video_source: 'DDr7n8txCsm',
    impressions: 26371,
    video_views: 0,
    cta_click: 35,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 16,
    video_views: 10,
    cta_click: 2,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241221',
    video_source: 'DDiLgAeNyYo',
    impressions: 13,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 9,
    video_views: 10,
    cta_click: 2,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241221',
    video_source: 'DDjup8-J7VX',
    impressions: 6,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241221',
    video_source: 'DDcaLFIprsN',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241221',
    video_source: 'DDZq_pAPIgZ',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241221',
    video_source: 'DDCb4b7xsFM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDCb4b7xsFM_optimized-9ma2G4Zxp31kN9oLC94a35XhSS1sib.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDCb4b7xsFM_preview-wyv03ACjIrg4zR0ZpYLQylppsaDIeo.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3513493284842815820p-f2XUKiIwGeD2mBGL9HJfGJxIfgktA5.jpg',
    caption:
      '🗣️ Pamela Martínez, fundadora de OMBIJAM, habló con Alejandro Fantino en el marco del ciclo de Diálogos en Montevideo. Podrás ver la entrevista completa a partir del lunes en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241221',
    video_source: 'DDW4ajwPOU3',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDW4ajwPOU3-1-ZDgZCUl1R7SUAVJmgHLu2aqjwZx8EP.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDW4ajwPOU3-prev-DRvPR65j8ZW3z14MAqHeQBZJDGhQlL.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDW4ajwPOU3-QFNYIkWUwQzKsUPCm36Lo8JWMa4NQi.jpg',
    caption:
      '🏎 \\"Las mejores 9 carreras que he tenido en mi vida\\". Williams Racing compartió un video de Franco Colapinto el día de su última carrera en la F1 con dicha escudería y el piloto argentino reflexionó sobre la experiencia.  ➡ Lamentablemente Colapinto quedó fuera del Gran Premio de Abu Dabi debido a una falla técnica en su auto y ahora su futuro en la F1 es incierto hasta que lo firme una nueva escudería.  Conocé más detalles en 👉 www.elobservador.com.uy 🎥: Williams Racing',
  },
  {
    date: '20241221',
    video_source: 'DC-R16POTVW',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC-R16POTVW_optimized-0llo2gCweXI24gS3aOiLd8Y0vBEadn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC-R16POTVW_preview-pxriQjJBEYWiYSlaDUM6mvmw6UqbZk.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3512323230850954582p-K2h25kRTODlokySgfqug5YD2gBNU7g.jpg',
    caption:
      '💻 El Observador presentó su rebranding estratégico para posicionarse como multimedio líder en Uruguay e Hispanoamérica. ➡️ Junto a agencias, empresarios y amigos, el equipo de El Observador presentó ayer su rebranding estratégico, un cambio de marca que alcanza a todas nuestras plataformas y canales y que implica un paso más hacia el futuro en la dinámica industria de los medios de comunicación. Conocé más detalles en 👉 www.elobservador.com.uy #elobservador',
  },
  {
    date: '20241221',
    video_source: 'DDNxdVMSDUP',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDNxdVMSDUP-video-1-TLyPIWJsLLgh0CfaVI7wEC7e2AC8g2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDNxdVMSDUP-video-prev-z1h1u7uGFpHk6odCTJD7jSPsf785zt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDNxdVMSDUP-poster-nFCGMLiIOxUKUPvIzrZ4MKzjUMAzkj.jpg',
    caption:
      '📚 Cada vez van menos: dos tercios de los escolares uruguayos padece ausentismo crónico. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #educación',
  },
  {
    date: '20241221',
    video_source: 'DDZs3iRR0ur',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241221',
    video_source: 'DDKgsyFxv3q',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241222',
    video_source: 'DDu2WsmRLo5',
    impressions: 75480,
    video_views: 0,
    cta_click: 58,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241222',
    video_source: 'DDsLvikyHUL',
    impressions: 48097,
    video_views: 0,
    cta_click: 32,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241222',
    video_source: 'DDsbem4S156',
    impressions: 35500,
    video_views: 0,
    cta_click: 35,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241222',
    video_source: 'DDr7n8txCsm',
    impressions: 25428,
    video_views: 0,
    cta_click: 44,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 8,
    video_views: 20,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 7,
    video_views: 50,
    cta_click: 40,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241222',
    video_source: 'DDiLgAeNyYo',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241222',
    video_source: 'DDjup8-J7VX',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241222',
    video_source: 'DDKgsyFxv3q',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241222',
    video_source: 'DBzjaqzuSbA',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBzjaqzuSbA_optimized-8qpmq45SVRGf2mdDUYMQgVCwDK3w86.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBzjaqzuSbA_preview-3vQkHZ6Hy54yNAJ1ZYjaYFbLToxspD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491289900441478848p-ywS77KyMRhK9ABDXUhqBFCMboAwEGH.jpg',
    caption:
      '🗣️🏎️ Lewis Hamilton sobre Franco Colapinto: "Se ha ganado el derecho de estar acá".  ➡️ En la conferencia de prensa previa al Gran Premio de Brasil, el piloto siete veces campeón del mundo elogió el desempeño del argentino en la máxima categoría y pidió por su continuidad para la temporada de 2025.  💬 "Él dice que no le corresponde decidir, pero mi consejo sería asegurarse, haciendo todo lo posible. Al final del día, él tiene que seguir enfocándose en hacer su trabajo cada fin de semana como lo está haciendo", expresó el británico.  Todos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241222',
    video_source: 'DDcmFxCJey6',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241222',
    video_source: 'DDcaLFIprsN',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241222',
    video_source: 'DBziZpVSBuO',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBziZpVSBuO_optimized-87MCW29joAZ2dVdNNI2rIht5VvJf16.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBziZpVSBuO_preview-o8Zu1zLNQB4hx54Wm2cdJ0MqDD4FQG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3491285432091024270p-hFAkYUG70kkHi57SRkg4UKZrRTF3UN.jpg',
    caption:
      '🥩 ¿Porqué Uruguay y Argentina son clásicos rivales en la carne? Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda  #carne #uruguay #argentina',
  },
  {
    date: '20241222',
    video_source: 'DDWsZEaxwRm',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDWsZEaxwRm-1-fhGyMSvd579yaBm3N9BOEIDHYV8dYU.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDWsZEaxwRm-prev-WyqUB69t65WZGWq1o16ihgP7EEnp4R.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDWsZEaxwRm-EFIj5B399c0dNCsl4uhItsaE81HHJ0.jpg',
    caption:
      '🗣️ Matías Valdez: "Era un momento que si bien no le podíamos dedicar el 100% de nuestro tiempo a la música, yo ya estaba en el camión y siempre andaba con la guitarra y en cualquier huequito subía algún videíto".  ➡️ El cantante oriundo de Florida fue entrevistado por Alejandro Fantino en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241222',
    video_source: 'DDJ_W_rJlEA',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDJ_W_rJlEA_optimized-jPimBgv22zELLPPKLnslIuydRZTZan.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDJ_W_rJlEA_preview-EXXvWFJVo8TK0bFoHyn4v42snpdSfY.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515619641267736832p-u2aezhOD9LUarONf9ahchFeNeuIs5D.jpg',
    caption:
      '🗣️ Pamela Martínez: \\"Uno de los motivos por los cuales la rehabilitación no funciona es el hacinamiento\\" ➡️ Martínez se dedica a ofrecerles una vida nueva a las personas que salen de las cárceles y el yoga es una de sus herramientas. Leé la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241222',
    video_source: 'DDIRcgASVUR',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDIRcgASVUR_optimized-nLPXqXdQXlWa2233WxnWQW2PoA8Obn.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDIRcgASVUR_preview-f33ZAnzb8pf961WWkJuItZEWj2R0qr.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3515136234463253777p-k6hTpSd1zmFlwmmPibt7kIPTbN3HYY.jpg',
    caption:
      '➡️ La cumbre del Mercosur, Orsi busca un contacto con Milei y comenzaron los movimientos para las elecciones departamentales. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241222',
    video_source: 'DDZs3iRR0ur',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241222',
    video_source: 'DDLLXLtsoZ4',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDLLXLtsoZ4-2-ZrVWOrrjc9RrdlSCHgInJyYVa0tMrJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDLLXLtsoZ4-prev-PfKtsKtY0J9TI8Gb9kVcppCW1snylJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDLLXLtsoZ4-R5W9WDBkubnCeA9TVN7jrM4tYumZLa.jpg',
    caption:
      '🗳️ Mientras el presidente electo avanza en la conformación de su gabinete, otra danza de nombres asoma en el horizonte porque la elección departamental está a la vuelta de la esquina. Para el FA las negociaciones son en paralelo a la designación de ministros mientras que la coalición se rearma para encarar las departamentales desde la oposición a nivel nacional. Esto (todavía) no para y el sistema político sigue generando noticias.  Leé la nueva edición de 👉 #GalletaDeCampaña ✍️ @diegocayota @santisoravilla @ramaa610',
  },
  {
    date: '20241223',
    video_source: 'DD1w5Ufp48Z',
    impressions: 73040,
    video_views: 0,
    cta_click: 15,
    video: 'https://media.doer.ar/EO/DD1w5Ufp48Z/video-mp4/video.m3u8',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DD1w5Ufp48Z-video-prev-RSSAAkYdNuxh4CUUH0v4eLOTWn2xh9.mp4',
    thumbnail: 'https://media.doer.ar/EO/DD1w5Ufp48Z/image-jpeg/videoposter.0000000.jpg',
    caption:
      '🚗 ¿Autos que se manejan solos? Todo lo que necesitás saber en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juampa.demarco  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241223',
    video_source: 'DDsLvikyHUL',
    impressions: 61450,
    video_views: 0,
    cta_click: 20,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241223',
    video_source: 'DDr7n8txCsm',
    impressions: 40817,
    video_views: 0,
    cta_click: 27,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20241223',
    video_source: 'DDu2WsmRLo5',
    impressions: 32486,
    video_views: 0,
    cta_click: 15,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241223',
    video_source: 'DDsbem4S156',
    impressions: 19294,
    video_views: 0,
    cta_click: 9,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 17124,
    video_views: 70,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241223',
    video_source: 'DDiLgAeNyYo',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 4,
    video_views: 10,
    cta_click: 50,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241223',
    video_source: 'DDjup8-J7VX',
    impressions: 4,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241223',
    video_source: 'DC4fJSkJRV-',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20241223',
    video_source: 'DDZq_pAPIgZ',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241223',
    video_source: 'DC2QiC2PA5d',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC2QiC2PA5d_optimized-XTTv0Wl4baQOjyMn75huzJkSZ8WR3I.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC2QiC2PA5d_preview-UfHOp7ppXjWaxk20BkHl4WkV8Raszf.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510065667845656157p-23tpmeKD5NN4j6fT5FKZQbTEVo35xL.jpg',
    caption:
      '🗳️ Yamandú Orsi le ganó el balotaje a Álvaro Delgado por casi 90mil votos: ¿Qué viene ahora?  💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de estas #Elecciones2024',
  },
  {
    date: '20241223',
    video_source: 'DC1mHB-OfUA',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC1mHB-OfUA_optimized-wSeXiz4E1enN6GuCAOrR5S4oOHp4su.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC1mHB-OfUA_preview-LRV6SskTC75I2rjpsLWQh4RV3TwtlJ.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509879093526656256p-D4i3aq3gcoEW2gPeBLWdOGqStMkCsp.jpg',
    caption:
      '¿Qué se habló sobre agricultura en la COP 29? 🌾 👉 El futuro de la agricultura como sostén de la seguridad alimentaria global y herramienta clave de la sostenibilidad ambiental está asegurado si se fortalece a los agricultores, se afirmó en la COP 29 en el pabellón del Instituto Interamericano de Cooperación para la Agricultura (IICA), cuyo director general -Manuel Otero- saludó a Yamandú Orsi tras su triunfo electoral en Uruguay. 🎙️: @juansamuelle  📽️: @agusabelenda',
  },
  {
    date: '20241223',
    video_source: 'DDKgsyFxv3q',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDKgsyFxv3q-2-WsaJZJDKzfLdBJV8kBDsmpZiwAEsE3.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDKgsyFxv3q-prev-A5Qqq1HASbKWkGIXX0cmRS6Q4Z8eUK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDKgsyFxv3q-MUOfQtCJ9ePeeB3OEBuUGBZrHLFXzB.jpg',
    caption:
      '🌎 El mundo se transforma, y nosotros también 🚀 En El Observador Brand Studio, no solo contamos historias, ¡las vivimos contigo! Creamos contenidos únicos para que tu marca conecte con nuestra audiencia. 💡Tu historia, nuestra pasión.',
  },
  {
    date: '20241223',
    video_source: 'DCtqssfJecY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCtqssfJecY_optimized-KtW2yM6kG5HbuyWHgaRKCKQnj6UuVG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCtqssfJecY_preview-e4jzsDNNxJ91agqjZlsRHydiDEsyYD.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3507647474169145112p-iUceBWtD1SkWSEjnLvOvOTWJAfLPwt.jpg',
    caption:
      '🎬 Algunas de las películas que ya se perfilan para los Oscar 2025.  Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  📹 @agusabelenda  #oscar2025 #cine',
  },
  {
    date: '20241223',
    video_source: 'DDcaLFIprsN',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241223',
    video_source: 'C-uuwbrupac',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/C-uuwbrupac_optimized-2-QhOhB0ZExmvQw4tkliieWqXCov4MJD.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/C-uuwbrupac_preview-6iD40OO5O3XTVz7LffLJxxUpc2lgVt.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/C-uuwbrupac_cover_resized-ClwI2C844ip8YXcOMhxbwngb1dqtba.jpg',
    caption:
      '💵 ¿Cómo afecta a Uruguay y qué implicancias tiene el “blanqueo” de capitales de Argentina?\n\n➡️ La medida que adoptó Argentina podría tener efectos positivos y negativos en la economía uruguaya y @piamestell te lo explica.\n\nLeé la nota completa en 👉 www.elobservador.com.uy\n\n#blanqueodecapitales #uruguay #argentina',
  },
  {
    date: '20241223',
    video_source: 'DDZs3iRR0ur',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241223',
    video_source: 'DCzvO-EokGM',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCzvO-EokGM_optimized-vgOy657SJVTgZudS8QI6m8G1CdpoAq.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCzvO-EokGM_preview-1F8VNS4FaUSiIRV5rbnNcwmb8D9pZE.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3509356271560180108p-nUnSuhDE81PQxqS2OOCZvMLBVXtDpN.jpg',
    caption:
      'El DETRÁS DE ESCENA de la transmisión de El Observador y FIPO por el BALOTAJE 2024 Decenas de invitados, periodistas y una transmisión que comenzó a las 8 AM y terminó a la medianoche con un programa especial conducido por Alejandro Fantino.',
  },
  {
    date: '20241224',
    video_source: 'DD1w5Ufp48Z',
    impressions: 73379,
    video_views: 0,
    cta_click: 17,
    video: 'https://media.doer.ar/EO/DD1w5Ufp48Z/video-mp4/video.m3u8',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DD1w5Ufp48Z-video-prev-RSSAAkYdNuxh4CUUH0v4eLOTWn2xh9.mp4',
    thumbnail: 'https://media.doer.ar/EO/DD1w5Ufp48Z/image-jpeg/videoposter.0000000.jpg',
    caption:
      '🚗 ¿Autos que se manejan solos? Todo lo que necesitás saber en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juampa.demarco  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241224',
    video_source: 'DDsLvikyHUL',
    impressions: 42518,
    video_views: 0,
    cta_click: 16,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241224',
    video_source: 'DDr7n8txCsm',
    impressions: 31074,
    video_views: 0,
    cta_click: 13,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 21541,
    video_views: 30,
    cta_click: 30,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241224',
    video_source: 'DDu2WsmRLo5',
    impressions: 38,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241224',
    video_source: 'DDsbem4S156',
    impressions: 18,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 6,
    video_views: 100,
    cta_click: 80,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241224',
    video_source: 'DDiLgAeNyYo',
    impressions: 5,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241224',
    video_source: 'DDjup8-J7VX',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241224',
    video_source: 'DDZq_pAPIgZ',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241224',
    video_source: 'DDcaLFIprsN',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241224',
    video_source: 'DCXRuTJxx9-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241224',
    video_source: 'DBPW4r8yFfq',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPW4r8yFfq_optimized-8QMu6LXv993FqM8ZgkMFxL9MKlCny2.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPW4r8yFfq_preview-QkeCfYxfbhqNiZEHY81BEDKq7VyfhG.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPW4r8yFfq_cover_resized-UFMXCefXM8LMO3edrhPHtUDOmBKZ4q.jpg',
    caption:
      '🕹️ 🇺🇾 Sebastián Gastelumendi, el gamer profesional de Age of Empires que es top 10 en el mundo y compite por miles de dólares. Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @juanpablo.dm 📹 @agusabelenda #ageofempire #gamer',
  },
  {
    date: '20241224',
    video_source: 'C_bzFuLNOLY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/CC_bzFuLNOLY_optimized-2-7ZWs1OwUdPU6NfTZaumZRkaXbOM4VV.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/C_bzFuLNOLY_preview-GnlL4ShDpFTVF8j2gPdG0ppWWT8gaM.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/C_bzFuLNOLY_cover_resized-4iE75He6dBJX375Dw0Xd6utPaFlcoY.jpg',
    caption:
      '🤰🏻 María Noel Riccetto habló sobre su embarazo: “Nosotros fuimos por una ovodonación”.\n\nMirá la entrevista completa en 👉 www.elobservador.com.uy\n\n@salusuruguay @tatasupermercados_uy @toyota_uruguay\n\n#diálogosenmontevideo',
  },
  {
    date: '20241224',
    video_source: 'DBBnU2vuRjw',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBBnU2vuRjw_optimized-3-kfOBJgnI8MOVnKjxKBHB1vFiZBcLGW.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBBnU2vuRjw_preview-nLm6lIhAaop4oIRGYY1gfab9vZbfQ9.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBBnU2vuRjw_cover_resized-Gp1GhVI1tX6DGQAf6HA8gK6x49ho2p.jpg',
    caption:
      '🗳️🇺🇾 ¿Cómo funciona el sistema de jubilaciones en Uruguay? ¿Cuáles son las diferencias entre régimen mixto, sistema de reparto y sistema de ahorro individual? \\n\\n🎙️ @piamestell te lo explica. \\n\\nTodos los detalles en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241224',
    video_source: 'C_dQaslNNmU',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/C_dQaslNNmU_optimized-2-z0v2sHiLvqxrSI8xHuCltFQPZIGnzE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/C_dQaslNNmU_preview-kIQy3gRVxzyylbFNlZxaKbbBU95S8A.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/C_dQaslNNmU_cover_resized-oKvW8FfJXmX0fq0t63HnB5lVGKWc7A.jpg',
    caption:
      '🗣️ María Noel Riccetto habló sobre la emoción que tiene por su embarazo.\n\n💬 En una entrevista con Alejandro Fantino para El Observador, Riccetto contó cómo fue el proceso que atravesó para esperar a su primer hijo. Espera –señaló– que su experiencia contribuya a naturalizar las conversaciones sobre fertilidad.\n\nPodés ver la nota completa en 👉 www.elobservador.com.uy\n\n@salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241224',
    video_source: 'DBPLbr4y9JZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBPLbr4y9JZ_optimized-s695hg9QXYKWRjNacQ2He1ojWFTM81.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBPLbr4y9JZ_preview-tpz5CH7e2ChtmbMFBSLLxhRzisN8PN.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBPLbr4y9JZ_cover_resized-394pPSm5NKsiVUAfxLSzzkLnyuz1JC.jpg',
    caption:
      '🗳️ Este 27 de octubre habrá un plebiscito sobre la Seguridad Social: ¿sabés qué propone la papeleta del SI? Conocé más detalles en 👉 www.elobservador.com.uy\\n\\n🎙️ @piamestell \\n\\n#elecciones2024 #plebiscito #seguridadsocial',
  },
  {
    date: '20241224',
    video_source: 'C_flIifJJWt',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/C_flIifJJWt_optimized-hCBgwM9F69q0IuZcwUDG1g0s9f2PPp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/C_flIifJJWt_preview-XpnH8JcKcA95g7w4hvbOtGzZ54VQxw.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/C_flIifJJWt_cover_resized-qQRrVCzGlkhZMb27zc4nKEcygb10Eh.jpg',
    caption:
      '🗳️ Blanca Rodríguez calentó la pantalla.\n\n💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @ssoravilla y @diegocayota sobre el detalle de las charlas políticas, los datos que pasan desapercibidos y los lugares donde se cocinan las estrategias de estas #Elecciones2024\n\nLéela todos los miércoles en 👉 www.elobservador.com.uy\n\n🎙️ @ssoravilla\n\n#elecciones2024',
  },
  {
    date: '20241224',
    video_source: 'DBUKGhzS3US',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBUKGhzS3US_optimized-2-d1y6Kn77XqWLRKRwZb7aIjA1AUkGId.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBUKGhzS3US_preview-ZY334i5GeK1N2SXq2u5KqNPHjuIw5V.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBUKGhzS3US_cover_resized-TvnFcFvBaUWjhvBhFH9LJiWI7RNjgt.jpg',
    caption:
      '🗳️ 🔎 A una semana de las Elecciones Nacionales, te mostramos cómo funciona el monitor de encuestas de El Observador.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n🎙️ @fedebordaberry\n\n#eleccionesnacionales #elecciones2024',
  },
  {
    date: '20241224',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241224',
    video_source: 'C_diaGFNHDY',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/C_diaGFNHDY_optimized-MQnTB86OQiNWamwSNkKv7dyhPOIlPN.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/C_diaGFNHDY_preview-m8iboEwYMSMToRbz9880fXfX1tjcCM.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/C_diaGFNHDY_cover_resized-yGkXfQlOyNpKGjU2qGuWsEoi1MMrae.jpg',
    caption:
      '👋 🇺🇾 Luis Suárez anunció su retiro de la selección uruguaya.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @agusabelenda\n\n#luissuárez #selecciónuruguaya',
  },
  {
    date: '20241225',
    video_source: 'DD1w5Ufp48Z',
    impressions: 94177,
    video_views: 0,
    cta_click: 21,
    video: 'https://media.doer.ar/EO/DD1w5Ufp48Z/video-mp4/video.m3u8',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DD1w5Ufp48Z-video-prev-RSSAAkYdNuxh4CUUH0v4eLOTWn2xh9.mp4',
    thumbnail: 'https://media.doer.ar/EO/DD1w5Ufp48Z/image-jpeg/videoposter.0000000.jpg',
    caption:
      '🚗 ¿Autos que se manejan solos? Todo lo que necesitás saber en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juampa.demarco  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241225',
    video_source: 'DDsLvikyHUL',
    impressions: 48594,
    video_views: 0,
    cta_click: 25,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241225',
    video_source: 'DDr7n8txCsm',
    impressions: 32692,
    video_views: 0,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 21891,
    video_views: 30,
    cta_click: 29,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241225',
    video_source: 'DDu2WsmRLo5',
    impressions: 26,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241225',
    video_source: 'DDsbem4S156',
    impressions: 14,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 5,
    video_views: 100,
    cta_click: 30,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241225',
    video_source: 'DDiLgAeNyYo',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241225',
    video_source: 'DDcaLFIprsN',
    impressions: 2,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241225',
    video_source: 'DCXdLdpyCqo',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXdLdpyCqo_optimized-FOKKiYCBrUKT2PDkT48XJ67C6WXcMp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXdLdpyCqo_preview-M4TBaxHGF19uDQOsIttsK3XMAuy13t.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501395566406412968p-JpdVZir0TpRkVs6gwqTjQrjVg46rdb.jpg',
    caption:
      '🎮 🤔 ¿Sabés a qué juega tu hijo? Ceibal y expertos reflexionan sobre la “edad prohibida” de los videojuegos.\n\nConocé más detalles en 👉 www.elobservador.com.uy\n\n📹 @juampa.demarco\n\n#videojuegos',
  },
  {
    date: '20241225',
    video_source: 'DDZq_pAPIgZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241225',
    video_source: 'DC4fJSkJRV-',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DC4fJSkJRV-_optimized-6fhLOYjkojTPeT49Cg1uk3Jpjslbjm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DC4fJSkJRV-_preview-UwAq1K8DKed5bF6kbOE9PGQDkOC8me.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3510692887386199422p-vDBqnK8ATijvadH4206puxeWOSv84O.jpg',
    caption:
      '🗳️ Se terminó el ciclo electoral. Tenemos presidente electo, empieza la transición y, tras el receso veraniego, tendremos nuevo gobierno. 💬 Pero, con los resultados consumados, sigue habiendo movimientos. En el Frente Amplio comienza la danza de nombres para conformar el gabinete. Leé la última edición de #GalletaDeCampaña en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  #elecciones2024 #eleccionesuruguay',
  },
  {
    date: '20250116',
    video_source: 'DCXIcWdSFW8',
    impressions: 1000,
    video_views: 100,
    cta_click: 90,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXIcWdSFW8_optimized-rBH5fRrsyXMjv2vWryAhVlPGw6bBAj.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXIcWdSFW8_preview-imRcHexkpghHxcaiiRZspycwafeyOK.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501304367934887356p-9YnVBKYC8n8cmfdWrmaDd9xYLIiW4O.jpg',
    caption:
      'GAC Motor desembarcó en Uruguay de la mano de Lestido, en un evento realizado en el Aeropuerto de Carrasco.\n\nSe presentaron los modelos Emzoom, Emkoo, GS8, Aion ES y Aion Y Plus.\n\n#gacmotor #lestido',
  },
  {
    date: '20241225',
    video_source: 'DCZF0CsxkPY',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCZF0CsxkPY-Z1Bl7WRytTqhgDDGX51awP11P3sk7G.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCZF0CsxkPY_preview-huEiTkGvGQLGChlIHQDi5uRtTCgU2T.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501855751801291736p-zfeAxb6xENi80YIl87XXGgvzyC6DMx.jpg',
    caption:
      '🗣️ Virginia Staricco reflexionó sobre la relación entre la maternidad y el trabajo.\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241226',
    video_source: 'DD1w5Ufp48Z',
    impressions: 116885,
    video_views: 0,
    cta_click: 33,
    video: 'https://media.doer.ar/EO/DD1w5Ufp48Z/video-mp4/video.m3u8',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DD1w5Ufp48Z-video-prev-RSSAAkYdNuxh4CUUH0v4eLOTWn2xh9.mp4',
    thumbnail: 'https://media.doer.ar/EO/DD1w5Ufp48Z/image-jpeg/videoposter.0000000.jpg',
    caption:
      '🚗 ¿Autos que se manejan solos? Todo lo que necesitás saber en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juampa.demarco  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241226',
    video_source: 'DDsLvikyHUL',
    impressions: 74868,
    video_views: 0,
    cta_click: 25,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241226',
    video_source: 'DDr7n8txCsm',
    impressions: 56687,
    video_views: 0,
    cta_click: 11,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 38770,
    video_views: 60,
    cta_click: 47,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241226',
    video_source: 'DDu2WsmRLo5',
    impressions: 22,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241226',
    video_source: 'DDsbem4S156',
    impressions: 8,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 2,
    video_views: 10,
    cta_click: 10,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241226',
    video_source: 'DDZq_pAPIgZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20241226',
    video_source: 'DBcQ2K0suss',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBcQ2K0suss_optimized-2-2xBb2iHlPZd9ElxRGrIafg5dt1h9hE.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBcQ2K0suss_preview-kqQk3mWhQWgW5T55i7oFi6m0C6HgCg.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBcQ2K0suss_cover_resized-LYj8h55RAa9HfsyccITPLyRANTIntI.jpg',
    caption:
      'El Observador evoluciona: un nuevo comienzo 📲 Queremos explicar el mundo en español contando historias que resuenan en cualquier parte del planeta con un enfoque local, regional y global que refleje el impacto de la hiper globalización 💬🌎',
  },
  {
    date: '20241226',
    video_source: 'DDZs3iRR0ur',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZs3iRR0ur-video-1-dKkJrqTOs3LW6HaWYfZ26VzWdEPUoa.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZs3iRR0ur-video-prev-6rXnsQrFi1JnGahciN84oqj40YmtlR.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZs3iRR0ur-g5y5L3J5uedyG4N9R7dYq7RX9hHjE1.jpg',
    caption:
      '🎤 Matías Valdéz: "yo quería sacar ya las canciones nuestras. No quería, teniendo algo para mostrar, mostrar algo que no era nuestro"  Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay  @tatasupermercados_uy  @toyota_uruguay',
  },
  {
    date: '20241226',
    video_source: 'DBl-HYxJqXv',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DBl-HYxJqXv_optimized-WpxBCWns0DqYI4pEkICI0PjpzUAaRm.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DBl-HYxJqXv_preview-zYrqorLPSA4Q25cP3jwADTxfV9NyF3.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DBl-HYxJqXv_cover_resized-7G5Q7mnvnJcJwdeO2bv13LdeIG4D6h.jpg',
    caption:
      '💻 La inteligencia artificial ahora es capaz de usar una computadora como un humano: mover el cursor, abrir ventanas y más. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juanpablo.dm  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241226',
    video_source: 'DDcmFxCJey6',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCAKKMahFvx-vid-1-2sMoSjjidNPOTdg2LewSOJFRmLwuPG.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcmFxCJey6-vidprev-T3J5J71ix99ga4wGSCveEFZeENXQ8N.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcmFxCJey6-KM7HQ5vdo8TComMag93c1bM8B157S6.jpg',
    caption:
      '💲🗓️ Llegó fin de año, y con él, el tan esperado aguinaldo. 🤔 ¿A la gente le alcanza? ¿En qué lo gastan? Salimos a la calle para averiguarlo. 📹 @taniarodriguezco  #aguinaldo #preguntasenlacalle #montevideo',
  },
  {
    date: '20241226',
    video_source: 'DChC98KuTc0',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DChC98KuTc0_optimized-9VzTkQDZWMTlLCmBmErumHF6WWYHJo.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DChC98KuTc0_preview-atZndXBQcIwrzg7EiWjEtgQsPxWNls.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3504095037656479540p-Z6vNj6G3r48XuHR4kYBeQda6OzfSCD.jpg',
    caption:
      '🗣️ Claudia Fernández: "Sentí que me estaban cortando las piernas, sentí que nada más doloroso me podía pasar que eso. Hasta que ese dolor se empezó a transformar con los días en encontrarme en esa respiración y en decir, "yo no perdí un hermano", "yo gané 40 años a un hermano".  💬 Alejandro Fantino entrevistó a Claudia Fernández en su ciclo Diálogos en Montevideo Mirá la nota completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241226',
    video_source: 'DCXRuTJxx9-',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DCXRuTJxx9--7WII12NJ7JKwlLyQKnnSldsXA8R38l.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DCXRuTJxx9-_preview-4IPRdDCB9Hpr6b5qRHoBve6IE8FyIh.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/3501345183755607934p-F3wZwaeeFZsvIK3m6f3LZnE2DTKL4j.jpg',
    caption:
      '🗣️ Virginia Staricco: "Muchas veces me sentí muy sola"\n\n💬 En una charla con Alejandro Fantino, la empresaria del transporte Virginia Staricco exorciza sus peores fantasmas y aterriza en una realidad que, dice, la encuentra feliz luego de tantos padecimientos.\n\nMirá la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20241226',
    video_source: 'DDcaLFIprsN',
    impressions: 0,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDcaLFIprsN-video-nPm8iNJMv4Jv2Kgg926SR7aBwntAV4.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDcaLFIprsN-video-prev-SBCff27TKp8EOWS6I1V6u9N5T3ufYn.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDcaLFIprsN-WdD6hYLJ6H0W5xNyJ6KIML1WPZKQsh.jpg',
    caption:
      '💬 Matías Valdez habló sobre la primera vez que escuchó al público cantar sus canciones.  🗣️ En una nueva entrega de Diálogos en Montevideo, Alejandro Fantino conversó con Valdez, que repasó su historia, el presente de su música, las sorpresas que su nueva profesión le ha dado en los últimos años y algunos de los sueños que todavía le quedan por cumplir. Mirá la entrevista completa en 👉 www.elobservador.com.uy @salusuruguay @tatasupermercados_uy @toyota_uruguay',
  },
  {
    date: '20241227',
    video_source: 'DD1w5Ufp48Z',
    impressions: 39030,
    video_views: 0,
    cta_click: 13,
    video: 'https://media.doer.ar/EO/DD1w5Ufp48Z/video-mp4/video.m3u8',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DD1w5Ufp48Z-video-prev-RSSAAkYdNuxh4CUUH0v4eLOTWn2xh9.mp4',
    thumbnail: 'https://media.doer.ar/EO/DD1w5Ufp48Z/image-jpeg/videoposter.0000000.jpg',
    caption:
      '🚗 ¿Autos que se manejan solos? Todo lo que necesitás saber en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juampa.demarco  📹 @agusabelenda  #tecnología',
  },
  {
    date: '20241227',
    video_source: 'DDsLvikyHUL',
    impressions: 27268,
    video_views: 0,
    cta_click: 6,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsLvikyHUL-video-opt-BVCFxMry8r6uvovaftCh3Io3bg8q88.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsLvikyHUL-video-prev-lkcx4DEa9NmHgguNbQuLxiQYmxwX5O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsLvikyHUL-1-vkamQc5qn3RifFPZDd3FGBQqAzm38N.jpg',
    caption:
      '👀 📚 5 libros que @emabremermann se lleva del 2024. ➡️ Un repaso por las lecturas que dejó el año, desde títulos locales a internacionales. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @emabremermann  #libros #lectura #literatura',
  },
  {
    date: '20241227',
    video_source: 'DDr7n8txCsm',
    impressions: 21320,
    video_views: 0,
    cta_click: 7,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDr7n8txCsm-video-opt-a2vwbBresH2J36FgiUXzPZQ3Vls4sF.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDr7n8txCsm-video-prev-fB6KACnNOsBVxFDBFuwRX6AodX9yiS.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDr7n8txCsm-1-vUoD5IDAc5IKODlWRBNVuPwNdvWLRI.jpg',
    caption:
      '📲 La cuenta regresiva para TikTok: los riesgos económicos de un negocio y la migración hacia otras plataformas La presión legal y política en Estados Unidos pone en jaque el modelo de ingresos de TikTok, afectando a creadores, negocios y estrategias publicitarias. Todos los detalles en 👉 www.elobservador.com.uy 🎙️ @fedebordaberry  #tiktok',
  },
  {
    date: '20241227',
    video_source: 'DED4uQgCRHx',
    impressions: 17426,
    video_views: 0,
    cta_click: 12,
    video: 'https://media.doer.ar/EO/DED4uQgCRHx/video-mp4/video.m3u8',
    preview: 'https://media.doer.ar/EO/DED4uQgCRHx/video-mp4/videopreview.m3u8',
    thumbnail: 'https://media.doer.ar/EO/DED4uQgCRHx/image-jpeg/videoposter.0000000.jpg',
    caption:
      '¿Sabías que en Uruguay NO SE FESTEJA la Navidad de manera oficial?  🎙️ @fedebordaberry 📹 @agusabelenda',
  },
  {
    date: '20250116',
    video_source: 'DDhxMAkydVt',
    impressions: 15179,
    video_views: 20,
    cta_click: 12,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDhxMAkydVt-1-2jLa7dGnlpDc8GLyPtX4Vpyeudd1bp.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDhxMAkydVt-prev-ke1i1Yid8JqdHVommiqrujBgf46Rp8.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDhxMAkydVt-1-iwvVHCklct9xketPinP1vmQ4gcbO5u.jpg',
    caption:
      '➡️ La economía uruguaya creció 0,6% en el tercer trimestre de 2024. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @piamestell',
  },
  {
    date: '20241227',
    video_source: 'DEDQUqDx8eF',
    impressions: 8826,
    video_views: 0,
    cta_click: 3,
    video: 'https://media.doer.ar/EO/DEDQUqDx8eF/video-mp4/video.m3u8',
    preview: 'https://media.doer.ar/EO/DEDQUqDx8eF/video-mp4/videopreview.m3u8',
    thumbnail: 'https://media.doer.ar/EO/DEDQUqDx8eF/image-jpeg/videoposter.0000000.jpg',
    caption:
      '📚 ¿Cómo se elige un libro para el verano? Para algunos se trata de una ecuación donde el entretenimiento debe primar, y allí aparecen la novela negra, los thrillers, los libracos de muchos personajes, las novelas históricas. Para otros, es el momento de ponerse al día, de leer todo lo que el año no permite, de limpiar la mesa de luz. Por último, están los que no se adrementan y prefieren los desafíos: van a los clásicos, a los mamotretos densos, a lo que desafía las neuronas en épocas donde, se supone, están descansadas. 🏖️ @emabremermann te recomienda 4 libros ideales para llevarte de vacaciones. Lée la nota completa en 👉 www.elobservador.com.uy  📹 @_sil_fernandez  #libros #verano #lecturadeverano',
  },
  {
    date: '20241227',
    video_source: 'DEDJbonxkUt',
    impressions: 6081,
    video_views: 0,
    cta_click: 5,
    video: 'https://media.doer.ar/EO/DEDJbonxkUt/video-mp4/video.m3u8',
    preview: 'https://media.doer.ar/EO/DEDJbonxkUt/video-mp4/videopreview.m3u8',
    thumbnail: 'https://media.doer.ar/EO/DEDJbonxkUt/image-jpeg/videoposter.0000000.jpg',
    caption:
      '😲 ChatGPT tiene ideología y no lo sabías: así confecciona sus respuestas 💻 Estudios revelan cómo las IA adaptan sus respuestas según sesgos culturales y la manera en que los usuarios interactúan con ellas.  Lée la nota completa en 👉 www.elobservador.com.uy  🎙️ @juanpablo.dm  📹 @_sil_fernandez',
  },
  {
    date: '20241227',
    video_source: 'DDjup8-J7VX',
    impressions: 3828,
    video_views: 0,
    cta_click: 7,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDjup8-J7VX-1-9f1f11VIZdoax87a0HfedFdLXPKov0.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDjup8-J7VX-prev-5MNsk84Nt8MpsQXAQWJMhXzjc8SOf0.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDjup8-J7VX-1-kY9Hj7rB7kfdfygp0PMuIHCHeCnYlT.jpg',
    caption:
      '➡️ ALUR destacó que la zafra de caña de azúcar culminó con el cuarto mejor resultado desde 1970. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @juansamuelle  📹 @agusabelenda',
  },
  {
    date: '20241227',
    video_source: 'DDu2WsmRLo5',
    impressions: 7,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDu2WsmRLo5-video-opt-GgY3lx4KCW5En7y9UY3qtLLJkLXzXc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDu2WsmRLo5-video-prev-110sTbgUyqpxSeKmRlQYGZPPTfV9UO.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDu2WsmRLo5-1-VK88MypuJl2ah7vgCFvcRMFAp97hbf.jpg',
    caption:
      '🇺🇾 El anuncio de los nombres del gabinete generó, como era de esperar, distintas repercusiones. Las especulaciones quedaron por el camino y el análisis ahora es a cartas vistas. ➡️ Las primeras conclusiones marcan que la paridad de género no se cumplió, que Jorge Díaz tendrá un rol destacado (con poco control) desde la prosecretaría y que un exasesor colorado se metió como presidente del Banco Central. 👉 Esta es la última entrega de #GalletaDeCampaña de este 2024, léela completa en www.elobservador.com.uy  ✍️ @diegocayota @santisoravilla  📹 @_sil_fernandez  #galletadecampaña',
  },
  {
    date: '20241227',
    video_source: 'DDsbem4S156',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDsbem4S156-video-opt-b9Zt0svStGZ7l8QXuiPcf84Lja9E7L.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDsbem4S156-video-prev-TvLsCp0Xn8SVFFx5g3udocmHKRBD8d.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDsbem4S156-1-mAFJX1KaUXq5smrOxnTUDUwzgM5rCp.jpg',
    caption:
      '➡️ La presencia de mujeres en el gabinete de Yamandú Orsi, la herencia fiscal y las candidaturas a las intendencias. 💬 Esto y mucho más en la nueva edición de #GalletaDeCampaña, una Newsletter de @santisoravilla y @diegocayota sobre el detalle de las charlas políticas y los datos que pasan desapercibidos. Léela todos los miércoles en 👉 www.elobservador.com.uy 🎙️ @diegocayota',
  },
  {
    date: '20241227',
    video_source: 'DDiLgAeNyYo',
    impressions: 3,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/video-DDiLgAeNyYo-1-8pqXwXxoT0RE6ZWXSRTijTUUjxpxca.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/video-DDiLgAeNyYo-prev-9cBMST7JehrKfOJTZVVAQjkQptIb9O.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/toolzin.com-DDiLgAeNyYo-1-4OSlI9vV907pFnO2mSTxsJ6EsVRpLZ.jpg',
    caption:
      '🌎🗣 Los principales diccionarios del mundo eligieron sus palabras para el 2024.  📚 Para el diccionario Oxford, fue \\"brain rot\\". Para otros fueron \\"polarización\\", \\"brat\\" o \\"manifestar\\". Pero, ¿qué indica este fenómeno que se ha viralizado? 🎙 @fedebordaberry  📹 @agusabelenda  Leé la nota completa en 👉 www.elobservador.com.uy',
  },
  {
    date: '20250116',
    video_source: 'DDfpmFWpK4c',
    impressions: 3,
    video_views: 5,
    cta_click: 1,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDfpmFWpK4c-vid-opt-OG3DjqdSiC5QcGChBiq02gAfDwXSRJ.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDfpmFWpK4c-vid-prev-HKOoMXbxkjbervpzN4OfsrYW3K1IW2.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDfpmFWpK4c-YBnHF3KFzM4DVica21Ykd1Jm5RWMmw.jpg',
    caption:
      '🇺🇾 Punta del Este, la nueva Buenos Aires y el boom que no fue: lo que el censo 2023 dice de las olas migratorias en Uruguay. Conocé más detalles en 👉 www.elobservador.com.uy 🎙️ @tomer.urwicz  📹 @agusabelenda  #censo #uruguay',
  },
  {
    date: '20241227',
    video_source: 'DDZq_pAPIgZ',
    impressions: 1,
    video_views: 0,
    cta_click: 0,
    video:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/optimized/DDZq_pAPIgZ-video-1-4kYNMXBeY8Jw3r2a1oQrHGtxqQurIc.mp4',
    preview:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/preview/DDZq_pAPIgZ-video-prev-6ljriTG4dKBe1AUdtmGk3XFSSrGSMV.mp4',
    thumbnail:
      'https://9twnm0hbmwmjhpz2.public.blob.vercel-storage.com/videos/posters/DDZq_pAPIgZ-6UnTvikwWGgo2mNdsi3nrOkQHI5E9o.jpg',
    caption:
      'Fentanilo en declive: la caída en las muertes por sobredosis y las teorías detrás de la tendencia Según las cifras de los CDC, la tendencia de descenso ya cumple casi un año. Mientras algunos expertos se muestran positivos con la gestión de la crisis de los opioides, otros han sido un poco más cuidadosos con su optimismo. 🎙@fedebordaberry 📹 @agusabelenda',
  },
]
