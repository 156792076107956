import React from 'react'
import type { MenuProps } from 'antd'
import { Layout, Menu, Avatar, Row, Divider, Button, Typography, Col } from 'antd'

import { ChartPie, Calendar, LogOut } from 'lucide-react'

import Logo from './logo'
import { useAuthContext } from '../context/auth'
import { DashboardProvider } from '../context/dashboard'
import { DashboardPage } from '../pages/dashboard'
import { EditFormPage } from '../pages/edit-form'

const { Content, Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

interface MenuConfig {
  items: MenuItem[]
  components: Record<string, React.ReactNode>
}

interface UserProfile {
  'custom:clientId'?: string
  'custom:defaultWidgetId'?: string
  email?: string
}

interface UserProfileSectionProps {
  userInfo?: UserProfile
  onSignOut: () => void
}

const menuItemStyle = `
.ant-menu-item.ant-menu-item-selected {
  font-weight: bold;
}
`

/**
 * Componente que muestra la información del perfil del usuario.
 *
 * @param userInfo - Información del usuario que se mostrará en el perfil.
 * @param onSignOut - Función para cerrar sesión cuando el usuario lo desee.
 * @returns JSX.Element - Renderiza la sección del perfil del usuario.
 */
const UserProfileSection: React.FC<UserProfileSectionProps> = ({ userInfo, onSignOut }) => (
  <Row
    style={{
      bottom: '30px',
      position: 'absolute',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Row
      style={{
        display: 'flex',
        gap: '10px',
        marginBottom: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxHeight: '50px',
      }}
    >
      {/* Columna 1: Avatar */}
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar size={30}>
          {userInfo?.['custom:clientId']
            ?.split(' ')
            .map(word => word[0]?.toUpperCase())
            .join('') || '?'}
        </Avatar>
      </Col>

      {/* Columna 2: Nombre y Email */}
      <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography.Text
          style={{ fontWeight: 'bold', color: '#595959', textAlign: 'left', fontSize: 11 }}
        >
          {userInfo?.['custom:clientId']}
        </Typography.Text>
        <Typography.Text style={{ color: '#8c8c8c', fontSize: 11 }}>
          {userInfo?.email}
        </Typography.Text>
      </Col>
    </Row>

    <Divider
      type='horizontal'
      style={{
        width: '100%',
        alignSelf: 'stretch',
        borderLeft: '0.5px solid #d9d9d9',
        marginTop: '0px',
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
      }}
    />
    <Button
      type='text'
      icon={<LogOut width={14} />}
      onClick={onSignOut}
      style={{ color: 'rgba(109, 109, 109, 1)' }}
    >
      Cerrar sesión
    </Button>
  </Row>
)

type MenuSections = 'dashboard' | 'content-editor'

/**
 * Componente de la barra lateral de la aplicación.
 *
 * @param activeKey - Clave del menú activo.
 * @param items - Lista de elementos del menú.
 * @param userInfo - Información opcional del usuario que se muestra en la barra lateral.
 * @param onKeySelect - Función llamada al seleccionar un elemento del menú.
 * @param onSignOut - Función llamada al cerrar sesión.
 * @returns JSX.Element - Renderiza la barra lateral de la aplicación.
 */
const AppSider: React.FC<{
  activeKey: MenuSections
  items: MenuItem[]
  userInfo?: UserProfile
  onKeySelect: (key: MenuSections) => void
  onSignOut: () => void
}> = ({ activeKey, items, userInfo, onKeySelect, onSignOut }) => (
  <Sider
    width={200}
    style={{
      background: '#FFFFFF',
      borderRight: '1px solid #f0f0f0',
    }}
  >
    <Row>
      <div style={{ padding: '20px' }}>
        <Logo />
      </div>
      <style>{menuItemStyle}</style>
      <Menu
        mode='inline'
        selectedKeys={[activeKey]}
        onClick={({ key }) => onKeySelect(key as MenuSections)}
        items={items}
      />
    </Row>
    <UserProfileSection userInfo={userInfo} onSignOut={onSignOut} />
  </Sider>
)

/**
 * Genera los elementos del menú para la aplicación.
 *
 * @param setActiveKey - Función para establecer el elemento activo del menú.
 * @param userInfo - Información del usuario (opcional).
 * @returns MenuConfig - Configuración del menú generada.
 */
const generateMenuItems = (
  setActiveKey: (key: MenuSections) => void,
  userInfo?: any,
): MenuConfig => {
  const components: Record<MenuSections, React.ReactNode> = {
    dashboard: (
      <DashboardProvider>
        <DashboardPage
          userInfo={userInfo}
          onNavigateToContent={() => setActiveKey('content-editor')}
        />
      </DashboardProvider>
    ),
    'content-editor': <EditFormPage widgetId={userInfo?.['custom:defaultWidgetId'] || ''} />,
  }

  const items: MenuItem[] = [
    {
      key: 'dashboard',
      icon: <ChartPie width={14} />,
      label: 'Dashboard',
      disabled: process.env.REACT_APP_ENVIRONMENT === 'production',
      title: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'Próximamente' : '',
    },
    {
      key: 'content-editor',
      icon: <Calendar width={14} />,
      label: 'Contenido',
    },
  ]

  return { items, components }
}

/**
 * Componente principal de diseño de la aplicación.
 *
 * @returns JSX.Element - Renderiza el layout principal de la aplicación.
 */
export const AppLayout: React.FC = () => {
  const { isLoggedIn, loginUrl, userInfo, signOut } = useAuthContext()
  const [activeKey, setActiveKey] = React.useState<MenuSections>('content-editor')

  const { items, components } = React.useMemo(
    () => generateMenuItems(setActiveKey, userInfo),
    [userInfo],
  )

  if (!isLoggedIn) {
    return (
      <div className='Button-container'>
        <Button onClick={() => (window.location.href = loginUrl)}>Ingresar</Button>
      </div>
    )
  }

  return (
    <Layout style={{ display: 'flex', height: '100vh' }}>
      <AppSider
        activeKey={activeKey}
        items={items}
        userInfo={userInfo}
        onKeySelect={setActiveKey}
        onSignOut={signOut}
      />
      <Layout>
        <Content
          style={{
            paddingLeft: '24px',
            paddingRight: '24px',
            minHeight: 280,
            overflow: 'auto',
          }}
        >
          {components[activeKey]}
        </Content>
      </Layout>
    </Layout>
  )
}
