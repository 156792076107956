import React from 'react'
import { CalendarAPI } from '../../../../constructs/calendar/interface'
import { useAPIContext } from '../../context/api'
import { CalendarContent } from '../../../../types/calendar'

export const useFetchCarouselDates = ({ widgetId }: { widgetId: string }) => {
  const { callEndpoint } = useAPIContext()
  const [submittedDates, setSubmittedDates] = React.useState<string[]>([])
  
  const fetchSubmittedDates = React.useCallback(() => {
    
    callEndpoint<CalendarAPI, 'get'>('calendar', `/${widgetId}`, 'GET')
      .then(calendar => {
        const content = calendar || []
        const calendarDates: string[] = content.map((calendarDateItem: CalendarContent) => {
          return calendarDateItem.date
        })
        setSubmittedDates(calendarDates)
        
        return calendarDates
      })
      .catch(err => {
        console.error('Error fetching calendar dates:', err)
        if (err?.message?.includes('404')) {
          return []
        }
      })
  }, [widgetId, callEndpoint])

  return { fetchSubmittedDates, submittedDates}
}
