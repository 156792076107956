import React from 'react'
import './App.css'
import { ConfigProvider } from 'antd'
import { customTheme } from './theme/theme'
import { AppLayout } from './components/app-layout'

const App: React.FC = () => {
  return (
    <ConfigProvider theme={customTheme}>
      <div className='App'>
        <AppLayout />
      </div>
    </ConfigProvider>
  )
}

export default App
