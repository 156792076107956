import { tokens } from '../theme/tokens'
import { PerformanceStyles } from '../../types/performance'

export const PERFORMANCE_STYLES: PerformanceStyles = {
  low: {
    label: 'Low performer',
    color: tokens.colors.supporting.red[500],
  },
  mid: {
    label: 'Mid performer',
    color: tokens.colors.supporting.yellow[500],
  },
  top: {
    label: 'Top performer',
    color: tokens.colors.supporting.green[500],
  },
}
