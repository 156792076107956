import React from 'react'
import { Card, Col, Row, Tag, Tooltip, Typography } from 'antd'
import { MousePointerClick } from 'lucide-react'

import { ConfigType } from '../../../theme/card'
import { tokens } from '../../../theme/tokens'
import { typography } from '../../../theme/typography'
import {
  numberFormatter,
  getMetricTooltipContent,
  calculateRatio,
} from '../../../utils/metrics-utils'
import { useDashboardContext } from '../../../context/dashboard'

const { Text } = Typography
interface VideoClicksProps {
  video_views: number
  cta_click: number
  config: ConfigType
}

const VideoClicks: React.FC<VideoClicksProps> = ({ video_views, cta_click, config }) => {
  const { getCTRlabel } = useDashboardContext()

  const ratio = calculateRatio(cta_click, video_views)
  const { label, color } = getCTRlabel(ratio)
  const percentage = (ratio * 100).toFixed(2)

  return (
    <Tooltip
      title={getMetricTooltipContent({ metricType: 'videoClicks', value: cta_click })}
      placement='top'
    >
      <Card
        style={{
          ...config.size,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          padding: '7px',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row
          style={{
            width: '100%',
            height: config.rowHeight,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Col>
            <MousePointerClick style={{ width: '14px' }} />
          </Col>
          <Tag
            style={{
              width: '105px',
              height: '20px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: tokens.colors.primary.white,
              fontSize: typography.label.fontSize,
            }}
          >
            {label}
            <span
              style={{
                display: 'inline-block',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: color,
              }}
            />
          </Tag>
        </Row>
        <Row
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginTop: '10px',
          }}
        >
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Text style={{ fontSize: config.fontSize.paragraph, fontWeight: 600 }}>
              {numberFormatter(cta_click)}
            </Text>
            <Text style={{ fontSize: config.fontSize.title }}>{config.title.videoClicks}</Text>
          </Col>

          <Col
            style={{
              backgroundColor: tokens.colors.primary.neutral[50],
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingLeft: '8px',
              marginTop: '4px',
              marginRight: '10px',
            }}
          >
            <Text
              style={{
                fontSize: config.fontSize.paragraph,
                fontWeight: 600,
                lineHeight: typography.heading.lg.lineHeight,
              }}
            >
              {percentage}%
            </Text>
            <Text
              style={{
                fontSize: config.fontSize.title,
                color: '#5E5E5E',
                fontWeight: 600,
              }}
            >
              CTR
            </Text>
          </Col>
        </Row>
      </Card>
    </Tooltip>
  )
}

export default VideoClicks
