import React from 'react'
import { Image, Skeleton } from 'antd'

const PREVIEW_WIDTH = 72
const PREVIEW_HEIGHT = 128
const VALID_IG_LINK = /https:\/\/www\.instagram.com\/reel\/\S{9,}\/?/

interface InstagramPreviewProps {
  reelUrl: string
  previewUrl: string | undefined
  loading: boolean
}

export const InstagramPreview = React.memo<InstagramPreviewProps>(
  ({ reelUrl, previewUrl, loading }) => {
    const isValidLink = VALID_IG_LINK.test(reelUrl)

    if (!isValidLink) return null

    return (
      <div
        style={{
          width: PREVIEW_WIDTH,
          height: PREVIEW_HEIGHT,
          backgroundColor: '#F8F7F7',
          borderRadius: 3,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {loading || !previewUrl ? (
          <Skeleton.Image
            active
            style={{
              width: PREVIEW_WIDTH,
              height: PREVIEW_HEIGHT,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        ) : (
          <Image
            src={previewUrl}
            width={PREVIEW_WIDTH}
            height={PREVIEW_HEIGHT}
            style={{ objectFit: 'cover', borderRadius: 3 }}
            preview={true}
            fallback=''
          />
        )}
      </div>
    )
  },
  (prevProps, nextProps) =>
    prevProps.reelUrl === nextProps.reelUrl &&
    prevProps.previewUrl === nextProps.previewUrl &&
    prevProps.loading === nextProps.loading,
)
