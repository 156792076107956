import React from 'react'
import { Button, ButtonProps, ConfigProvider } from 'antd'
import { tokens } from '../../theme/tokens'
import { typography } from '../../theme/typography'

export const SecondaryButton: React.FC<ButtonProps> = props => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            fontSize: typography.body.lg.fontSize,
            defaultColor: tokens.colors.primary.black,
            defaultHoverColor: tokens.colors.primary.black,
            defaultActiveColor: tokens.colors.primary.black,
            fontWeight: 600,
            borderRadius: 8,
            defaultBg: tokens.colors.primary.white, // Background color
            defaultActiveBg: tokens.colors.primary.white,
            defaultHoverBg: tokens.colors.primary.white,
            defaultBorderColor: tokens.colors.primary.brand[600],
            defaultHoverBorderColor: tokens.colors.primary.brand[800],
            defaultActiveBorderColor: tokens.colors.primary.brand[900],
            borderColorDisabled: tokens.colors.primary.brand[50],
            colorTextDisabled: '#848484',
          },
        },
      }}
    >
      <Button {...props}>{props.children}</Button>
    </ConfigProvider>
  )
}
