export const globalFontStyles = `
  @font-face {
    font-family: 'Open Sauce Two';
    src: url('/fonts/open-sauce-two/OpenSauceTwo-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Open Sauce Two';
    src: url('/fonts/open-sauce-two/OpenSauceTwo-ExtraBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
`

export const typography = {
  fontFamilies: {
    openSauce: 'Open Sauce Two',
    inter: 'Inter, sans-serif',
  },

  display: {
    xl: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 72,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    lg: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 60,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    md: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 48,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
  },

  heading: {
    xl: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 32,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 1.3,
    },
    lg: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 28,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 1.3,
    },
    md: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 1.3,
    },
    sm: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 20,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 1.3,
    },
  },

  body: {
    xl: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 28,
    },
    lg: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 26,
    },
    md: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 20,
    },
    sm: {
      fontFamily: 'Open Sauce Two, sans-serif',
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: -0.02,
      lineHeight: 20,
    },
  },

  label: {
    fontFamily: 'Open Sauce Two, sans-serif',
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: -0.02,
    lineHeight: 18,
  },
}
