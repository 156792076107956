import { ThemeConfig } from 'antd'
import { typography } from './typography'
import { tokens } from './tokens'

export const customTheme: ThemeConfig = {
  token: {
    // Seed token
    fontFamilyCode: typography.fontFamilies.openSauce,
    fontSize: typography.body.sm.fontSize,
    colorBgBase: tokens.colors.primary.white,
    colorTextBase: tokens.colors.primary.black,
    colorPrimary: tokens.colors.primary.brand[500],
    colorSuccess: tokens.colors.supporting.green[500],
    colorWarning: tokens.colors.supporting.yellow[500],
    colorError: tokens.colors.supporting.red[500],

    // Map Tokens
    colorBgLayout: tokens.colors.primary.white,
    fontSizeHeading1: typography.heading.xl.fontSize,
    fontSizeHeading2: typography.heading.lg.fontSize,
    fontSizeHeading3: typography.heading.md.fontSize,
    fontSizeHeading4: typography.heading.sm.fontSize,
    lineHeightHeading1: typography.heading.xl.lineHeight,
    lineHeightHeading2: typography.heading.lg.lineHeight,
    lineHeightHeading3: typography.heading.md.lineHeight,
    lineHeightHeading4: typography.heading.sm.lineHeight,
    fontSizeXL: typography.body.xl.fontSize,
    fontSizeLG: typography.body.lg.fontSize,
    fontSizeSM: typography.body.sm.fontSize,
    lineHeightLG: typography.body.lg.lineHeight,
    lineHeightSM: typography.body.sm.lineHeight,

    // Alias Tokens
  },

  components: {
    Typography: {
      fontFamilyCode: typography.fontFamilies.openSauce,
    },
    Button: {
      algorithm: true,
      borderRadius: 8,
      contentFontSize: typography.body.sm.fontSize, // Font size
      defaultBg: tokens.colors.primary.brand[600], // Background color
      defaultActiveBg: tokens.colors.primary.brand[900], // Active background color
      defaultHoverBg: tokens.colors.primary.brand[800], // Hover background color
      colorBgContainerDisabled: tokens.colors.primary.brand[50], // Disabled background color
      defaultColor: tokens.colors.primary.white, // Text color
      defaultHoverColor: tokens.colors.primary.white, // Hover text color
      defaultActiveColor: tokens.colors.primary.white, // Active text color
      defaultBorderColor: tokens.colors.primary.white, // Border color
      colorTextDisabled: '#C4C4C4', // Disabled text color
      borderColorDisabled: tokens.colors.primary.white,
    },

    Input: {},

    Tabs: {},
  },
}
