import React from 'react'
import { Col, Space, Row, Typography } from 'antd'

import { Share } from 'lucide-react'

import { SecondaryButton } from '../ui/secondary-button'
import { DateRangePicker } from './custom-calendar'

import { useDashboardContext } from '../../context/dashboard'

const { Text } = Typography

const DashboardNavbar: React.FC = () => {
  const { activeDateRangeText } = useDashboardContext()
  return (
    <Row justify='space-between' align='middle'>
      <Col>
        <Text style={{ fontSize: '16px', fontWeight: 600 }}>
          Tus contenidos para {activeDateRangeText.toLowerCase()}
        </Text>
      </Col>
      <Col>
        <Space size='large'>
          <DateRangePicker />
          <SecondaryButton
            icon={<Share width={12} />}
            style={{ borderColor: 'rgba(138, 148, 166, 1)' }}
          >
            Compartir
          </SecondaryButton>
        </Space>
      </Col>
    </Row>
  )
}

export default DashboardNavbar
