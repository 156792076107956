export const calculateRatio = (num: number, denom: number): number => {
  return denom > 0 ? num / denom : 0
}

export const numberFormatter = (views: number) => {
  if (views < 1000) return views

  const viewsInK = views / 1000
  const viewsInM = views / 1000000
  return viewsInM >= 1 ? `${Math.ceil(viewsInM * 10) / 10}M` : `${Math.ceil(viewsInK * 10) / 10}K`
}

export type MetricType = 'videoImpressions' | 'successfulViews' | 'videoClicks'

interface TooltipParams {
  metricType: MetricType
  value: number
}

export const getMetricTooltipContent = ({ metricType, value }: TooltipParams): string => {
  const metricMessages = {
    videoImpressions: `Alcance del contenido: ${value.toLocaleString()}`,
    successfulViews: `El contenido se reprodujo ${value.toLocaleString()} ${value === 1 ? 'vez' : 'veces'}`,
    videoClicks: `${value.toLocaleString()} clicks en el contenido`,
  }

  return metricMessages[metricType]
}
