import React from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { SortState } from '../../types/sort'
import { PerformanceMetrics } from '../../types/video'
import { MetricRanges, PerformanceConfig } from '../../types/performance'

import { formatDateRange } from '../config/date-range-config'
import { PERFORMANCE_STYLES } from '../constants/performance'

import { sortMetricsByConfig } from '../utils/sorting'
import { calculateRatio } from '../utils/metrics-utils'

import { fullData } from '../mocks/full-data'

const ITEMS_PER_PAGE = 10
const DEFAULT_DATE_RANGE: [Dayjs, Dayjs] = [dayjs().subtract(1, 'week'), dayjs()]
const PERFORMANCE_THRESHOLD = 0.15

interface DashboardContextType {
  activeDateRange: [Dayjs | null, Dayjs | null]
  setDateRange: (range: [Dayjs | null, Dayjs | null], showMessage?: boolean) => void
  activeDateRangeText: string

  filteredVideos: PerformanceMetrics[]
  summaryMetrics: PerformanceMetrics
  displayedMetrics: PerformanceMetrics[]

  sortConfig: SortState
  setSortConfig: React.Dispatch<React.SetStateAction<SortState>>

  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>

  calculateCTRmetric: MetricRanges
  calculatePerformanceMetric: MetricRanges
  getPerformanceLabel: (value: number) => PerformanceConfig
  getCTRlabel: (value: number) => PerformanceConfig
}

const DashboardContext = React.createContext<DashboardContextType | undefined>(undefined)

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeDateRange, setActiveDateRange] =
    React.useState<[Dayjs | null, Dayjs | null]>(DEFAULT_DATE_RANGE)
  const activeDateRangeText = React.useMemo(
    () => formatDateRange(activeDateRange),
    [activeDateRange],
  )
  const [sortConfig, setSortConfig] = React.useState<SortState>({
    type: 'impressions',
    direction: 'desc',
  })
  const [currentPage, setCurrentPage] = React.useState(1)

  const groupVideosById = React.useCallback(
    (videos: PerformanceMetrics[]): PerformanceMetrics[] => {
      const videoMap = new Map<string, PerformanceMetrics>()

      videos.forEach(video => {
        const existingVideo = videoMap.get(video.video_source)

        if (existingVideo) {
          existingVideo.impressions += video.impressions
          existingVideo.video_views += video.video_views
          existingVideo.cta_click += video.cta_click
          const currentDate = dayjs(video.date)
          const existingStartDate = dayjs(existingVideo.startDate)
          const existingEndDate = dayjs(existingVideo.endDate)

          if (currentDate.isBefore(existingStartDate)) {
            existingVideo.startDate = video.date
          }
          if (currentDate.isAfter(existingEndDate)) {
            existingVideo.endDate = video.date
          }
        } else {
          videoMap.set(video.video_source, {
            ...video,
            startDate: video.date,
            endDate: video.date,
          })
        }
      })

      return Array.from(videoMap.values())
    },
    [],
  )

  const transformInputData = (
    data: Omit<PerformanceMetrics, 'startDate' | 'endDate'>[],
  ): PerformanceMetrics[] => {
    return data.map(video => {
      const formattedDate = dayjs(video.date, 'YYYYMMDD').format('YYYY-MM-DD')

      return {
        ...video,
        date: formattedDate,
        startDate: formattedDate,
        endDate: formattedDate,
      }
    })
  }

  const filteredVideos = React.useMemo(() => {
    const [start, end] = activeDateRange
    const transformedData = transformInputData(fullData)

    if (!start || !end) {
      return groupVideosById(
        transformedData.filter(video => dayjs(video.date).isAfter(DEFAULT_DATE_RANGE[0], 'day')),
      )
    }

    const filteredByDate = transformedData.filter(video =>
      dayjs(video.date).isBetween(start, end, 'day', '[]'),
    )

    const groupedVideos = groupVideosById(filteredByDate)
    return sortMetricsByConfig(groupedVideos, sortConfig)
  }, [activeDateRange, groupVideosById, sortConfig])

  const summaryMetrics = React.useMemo(() => {
    return {
      video_source: 'Summary-Performance',
      impressions: filteredVideos.reduce((sum, video) => sum + video.impressions, 0),
      video_views: filteredVideos.reduce((sum, video) => sum + video.video_views, 0),
      cta_click: filteredVideos.reduce((sum, video) => sum + video.cta_click, 0),
      startDate: filteredVideos[0]?.startDate || '',
      endDate: filteredVideos[0]?.endDate || '',
      date: filteredVideos[0]?.date || '', // eliminar cuando se integre la API
    }
  }, [filteredVideos])

  const displayedMetrics = React.useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    return filteredVideos.slice(startIndex, endIndex)
  }, [filteredVideos, currentPage])

  const setDateRange = React.useCallback((newRange: [Dayjs | null, Dayjs | null]) => {
    setActiveDateRange(newRange)
  }, [])

  const calculateCTRmetric = React.useMemo(() => {
    const ratios = filteredVideos.map(video => {
      const ratio = calculateRatio(video.cta_click, video.video_views)
      return ratio
    })

    const minRatio = Math.min(...ratios)
    const maxRatio = Math.max(...ratios)

    const midpoint = (minRatio + maxRatio) / 2

    const lowThreshold = midpoint - midpoint * PERFORMANCE_THRESHOLD
    const highThreshold = midpoint + midpoint * PERFORMANCE_THRESHOLD

    return {
      minRatio,
      maxRatio,
      midpoint,
      lowThreshold,
      highThreshold,
    }
  }, [filteredVideos])

  const calculatePerformanceMetric = React.useMemo(() => {
    const ratios = filteredVideos.map(video => {
      const ratio = calculateRatio(video.video_views, video.impressions)
      return ratio
    })

    const minRatio = Math.min(...ratios)
    const maxRatio = Math.max(...ratios)

    const midpoint = (minRatio + maxRatio) / 2

    const lowThreshold = midpoint - midpoint * PERFORMANCE_THRESHOLD
    const highThreshold = midpoint + midpoint * PERFORMANCE_THRESHOLD

    return {
      minRatio,
      maxRatio,
      midpoint,
      lowThreshold,
      highThreshold,
    }
  }, [filteredVideos])

  const getPerformanceLabel = React.useCallback(
    (value: number): PerformanceConfig => {
      const { lowThreshold, highThreshold } = calculatePerformanceMetric

      const label = value <= lowThreshold ? 'low' : value >= highThreshold ? 'top' : 'mid'
      return PERFORMANCE_STYLES[label]
    },
    [calculatePerformanceMetric],
  )

  const getCTRlabel = React.useCallback(
    (value: number): PerformanceConfig => {
      const { lowThreshold, highThreshold } = calculateCTRmetric

      const label = value <= lowThreshold ? 'low' : value >= highThreshold ? 'top' : 'mid'
      return PERFORMANCE_STYLES[label]
    },
    [calculateCTRmetric],
  )

  const value = {
    activeDateRange,
    setDateRange,
    activeDateRangeText,

    filteredVideos,
    summaryMetrics,
    displayedMetrics,

    sortConfig,
    setSortConfig,

    currentPage,
    setCurrentPage,

    calculatePerformanceMetric,
    calculateCTRmetric,
    getPerformanceLabel,
    getCTRlabel,
  }

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext)
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider')
  }
  return context
}
