import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.locale('es')
dayjs.extend(isBetween)

type DateRangeGetter = () => [Dayjs, Dayjs]

// Función helper para crear rangos de fecha
const createDateRange = (
  amount: number,
  unit: 'day' | 'week' | 'month' | 'year',
): DateRangeGetter => {
  return () => [dayjs().subtract(amount, unit), dayjs()]
}

export const DATE_RANGES = {
  today: {
    label: 'Hoy',
    getDates: (): [Dayjs, Dayjs] => {
      const today = dayjs()
      return [today, today]
    },
  },
  lastWeek: {
    label: 'Última semana',
    getDates: createDateRange(1, 'week'),
  },
  lastMonth: {
    label: 'Último mes',
    getDates: createDateRange(1, 'month'),
  },
  last3Months: {
    label: 'Últimos 3 meses',
    getDates: createDateRange(3, 'month'),
  },
  lastYear: {
    label: 'Último año',
    getDates: createDateRange(1, 'year'),
  },
} as const

export type DateRangeKey = keyof typeof DATE_RANGES

export const getDateRange = (key: DateRangeKey): [Dayjs, Dayjs] => {
  const range = DATE_RANGES[key].getDates()
  return range
}

export const formatDateRange = (range: [Dayjs | null, Dayjs | null]): string => {
  if (!range[0] || !range[1]) return 'Seleccionar...'

  const preset = Object.values(DATE_RANGES).find(config => {
    const [presetStart, presetEnd] = config.getDates()
    return range[0]?.isSame(presetStart, 'day') && range[1]?.isSame(presetEnd, 'day')
  })

  return preset?.label ?? `${range[0].format('DD/MM/YYYY')} - ${range[1].format('DD/MM/YYYY')}`
}

export const getMenuItems = () =>
  Object.entries(DATE_RANGES).map(([key, { label }]) => ({
    key,
    label,
  }))
