import dayjs from 'dayjs'
import { SortState } from '../../types/sort'
import { PerformanceMetrics } from '../../types/video'

export const sortMetricsByConfig = (
  videos: PerformanceMetrics[],
  sortConfig: SortState,
): PerformanceMetrics[] => {
  return [...videos].sort((a, b) => {
    const sortDirectionFactor = sortConfig.direction === 'asc' ? 1 : -1

    if (sortConfig.type === 'date') {
      return sortDirectionFactor * (dayjs(a.date).unix() - dayjs(b.date).unix())
    }

    return sortDirectionFactor * (a[sortConfig.type] - b[sortConfig.type])
  })
}
