import React from 'react'

import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/es'
import isBetween from 'dayjs/plugin/isBetween'

import { ConfigProvider, Calendar, Menu, Button, Input, Divider, Typography } from 'antd'
import es_ES from 'antd/es/locale/es_ES'

import { ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react'
import { SecondaryButton } from '../ui/secondary-button'
import { useDashboardContext } from '../../context/dashboard'

import {
  DateRangeKey,
  formatDateRange,
  getDateRange,
  getMenuItems,
} from '../../config/date-range-config'

dayjs.locale('es')
dayjs.extend(isBetween)

const CalendarHeader: React.FC<{
  calendar: 'left' | 'right'
  currentMonth: Dayjs
  onNavigate: (direction: 'left' | 'right', calendar: 'left' | 'right') => void
}> = ({ calendar, currentMonth, onNavigate }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }}
  >
    <button
      onClick={() => onNavigate('left', calendar)}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '0',
      }}
    >
      <ChevronLeft width={12} />
    </button>
    <Typography.Text
      style={{
        textTransform: 'capitalize',
      }}
    >
      {currentMonth.format('MMMM YYYY')}
    </Typography.Text>
    <button
      onClick={() => onNavigate('right', calendar)}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '0',
      }}
    >
      <ChevronRight width={12} />
    </button>
  </div>
)

export const DateRangePicker: React.FC = () => {
  const { setDateRange, activeDateRange } = useDashboardContext()

  //  UI date range selection
  const [selectedDateRange, setSelectedDateRange] =
    React.useState<[Dayjs | null, Dayjs | null]>(activeDateRange)

  const [isExpanded, setIsExpanded] = React.useState(false)
  const [currentMonthLeft, setCurrentMonthLeft] = React.useState(dayjs().subtract(1, 'month'))
  const [currentMonthRight, setCurrentMonthRight] = React.useState(dayjs())

  const dateRangePickerRef = React.useRef<HTMLDivElement>(null)
  const toggleButtonRef = React.useRef<HTMLDivElement>(null)

  // Function to expand/collapse the calendar
  const handleExpandToggle = React.useCallback(() => {
    setIsExpanded(prev => !prev)
  }, [])

  // Function to handle quick date selection
  const handleQuickDateRangeSelect = React.useCallback((key: DateRangeKey) => {
    setSelectedDateRange(getDateRange(key))
  }, [])

  // Function to handle date selection
  const handleDateSelect = React.useCallback((date: Dayjs, calendar: 'left' | 'right') => {
    setSelectedDateRange(prev => {
      if (calendar === 'left') {
        if (prev[1] && date.isAfter(prev[1])) {
          return [date, date.add(1, 'month')]
        }
        return [date, prev[1]]
      } else {
        if (prev[0] && date.isBefore(prev[0])) {
          return [date.subtract(1, 'month'), date]
        }
        return [prev[0], date]
      }
    })
  }, [])

  // Function to navigate between months
  const navigateMonth = React.useCallback(
    (direction: 'left' | 'right', calendar: 'left' | 'right') => {
      const monthDiff = direction === 'left' ? -1 : 1

      if (calendar === 'left') {
        const newLeftMonth = currentMonthLeft.add(monthDiff, 'month')

        if (newLeftMonth.isAfter(currentMonthRight)) {
          setCurrentMonthRight(newLeftMonth.add(1, 'month'))
        }

        setCurrentMonthLeft(newLeftMonth)
      } else {
        const newRightMonth = currentMonthRight.add(monthDiff, 'month')

        if (newRightMonth.isBefore(currentMonthLeft)) {
          setCurrentMonthLeft(newRightMonth.subtract(1, 'month'))
        }

        setCurrentMonthRight(newRightMonth)
      }
    },
    [currentMonthLeft, currentMonthRight],
  )
  // Function to apply the selection
  const handleApply = React.useCallback(() => {
    setDateRange(selectedDateRange, true) // update context
    setIsExpanded(false)
  }, [selectedDateRange, setDateRange])

  // Function to cancel the selection
  const handleCancel = React.useCallback(() => {
    setSelectedDateRange([null, null])
    setIsExpanded(false)
  }, [])

  // Determinates if a date is in the selected range
  const isInRange = React.useCallback(
    (date: Dayjs) => {
      if (!selectedDateRange[0] || !selectedDateRange[1]) return false
      return date.isBetween(selectedDateRange[0], selectedDateRange[1], 'day', '[]')
    },
    [selectedDateRange],
  )

  // Determines if a date is the start or end of the range
  const isStartOrEnd = React.useCallback(
    (date: Dayjs) => {
      return selectedDateRange[0]?.isSame(date, 'day') || selectedDateRange[1]?.isSame(date, 'day')
    },
    [selectedDateRange],
  )

  const dateFullCellRender = React.useCallback(
    (date: Dayjs) => {
      const isSelected = isStartOrEnd(date)
      const inRange = isInRange(date)

      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '28px',
            backgroundColor: !isSelected && inRange ? 'rgba(218, 230, 255, 1)' : 'transparent',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '28px',
              width: '35px',
              borderTopLeftRadius: selectedDateRange[0]?.isSame(date, 'day') ? '4px' : '0',
              borderBottomLeftRadius: selectedDateRange[0]?.isSame(date, 'day') ? '4px' : '0',
              borderTopRightRadius: selectedDateRange[1]?.isSame(date, 'day') ? '4px' : '0',
              borderBottomRightRadius: selectedDateRange[1]?.isSame(date, 'day') ? '4px' : '0',
              backgroundColor: isSelected ? 'rgba(68, 131, 255, 1)' : 'transparent',
              color: isSelected ? 'white' : 'inherit',
            }}
          >
            {date.date()}
          </div>
        </div>
      )
    },
    [isInRange, isStartOrEnd, selectedDateRange],
  )

  // Effect to handle clicks outside the calendar
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node
      const toggleButton = toggleButtonRef.current
      const calendar = dateRangePickerRef.current

      // If the click was inside the button area, we do nothing (the button's onClick will handle it)
      if (toggleButton && toggleButton.contains(target)) {
        return
      }

      // If the click was outside the calendar and outside the button, we close the calendar
      if (calendar && !calendar.contains(target)) {
        setIsExpanded(false)
        handleCancel()
      }
    }

    if (isExpanded) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isExpanded, handleCancel])

  return (
    <ConfigProvider locale={es_ES}>
      <div style={{ position: 'relative', width: '100%' }}>
        <div ref={toggleButtonRef}>
          <SecondaryButton
            onClick={handleExpandToggle}
            style={{ borderColor: 'rgba(138, 148, 166, 1)' }}
          >
            {formatDateRange(selectedDateRange)}
            <ChevronDown width={12} />
          </SecondaryButton>
        </div>

        {isExpanded && (
          <div
            ref={dateRangePickerRef}
            style={{
              position: 'absolute',
              top: '100%',
              right: '0',
              zIndex: 1000,
              backgroundColor: '#fff',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              display: 'flex',
              padding: '12px',
              gap: '16px',
            }}
          >
            <Menu
              style={{ width: '135px' }}
              mode='vertical'
              onClick={({ key }) => handleQuickDateRangeSelect(key as DateRangeKey)}
              items={getMenuItems()}
            />
            <div style={{ flexDirection: 'column' }}>
              {/* Header and calendar */}
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CalendarHeader
                    calendar='left'
                    currentMonth={currentMonthLeft}
                    onNavigate={navigateMonth}
                  />
                  <Calendar
                    fullscreen={false}
                    value={currentMonthLeft}
                    headerRender={() => null}
                    onSelect={date => handleDateSelect(date, 'left')}
                    fullCellRender={dateFullCellRender}
                  />
                </div>

                <Divider
                  type='vertical'
                  style={{
                    height: 'auto',
                    alignSelf: 'stretch',
                    borderLeft: '0.5px solid #d9d9d9',
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CalendarHeader
                    calendar='right'
                    currentMonth={currentMonthRight}
                    onNavigate={navigateMonth}
                  />
                  <Calendar
                    fullscreen={false}
                    value={currentMonthRight}
                    headerRender={() => null}
                    onSelect={date => handleDateSelect(date, 'right')}
                    fullCellRender={dateFullCellRender}
                  />
                </div>
              </div>

              <Divider
                type='horizontal'
                style={{
                  alignSelf: 'stretch',
                  borderLeft: '0.5px solid #d9d9d9',
                  marginTop: '0px',
                  marginBottom: '16px',
                }}
              />

              {/* Inputs and buttons */}
              <div style={{ display: 'flex', gap: '25px', width: '100%' }}>
                <div style={{ display: 'flex', width: '240px', gap: '15px' }}>
                  <Input
                    value={selectedDateRange[0]?.format('DD/MM/YYYY') || ''}
                    readOnly
                    style={{ fontWeight: 'bold', width: '95px', borderRadius: '4px' }}
                  />
                  <Input
                    value={selectedDateRange[1]?.format('DD/MM/YYYY') || ''}
                    readOnly
                    style={{ fontWeight: 'bold', width: '95px', borderRadius: '4px' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '240px',
                    gap: '15px',
                  }}
                >
                  <SecondaryButton
                    style={{
                      width: '90px',
                      borderRadius: '4px',
                      borderColor: 'rgba(23, 22, 25, 1)',
                    }}
                    onClick={handleCancel}
                  >
                    Volver
                  </SecondaryButton>
                  <Button
                    type='primary'
                    style={{ width: '90px', borderRadius: '4px' }}
                    onClick={handleApply}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <style>
        {`
          /* Ajusta el thead */
          .ant-picker-content thead tr {
            height: 25px !important;
          }
        `}
      </style>
    </ConfigProvider>
  )
}
